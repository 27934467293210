import {
	Container,
	Paper,
	SimpleGrid,
	Card,
	Image,
	Group,
	Text,
	Button,
	Title,
	Stack,
	BackgroundImage,
	Box,
	Badge,
	Modal,
	ColorInput,
	Flex,
	Pagination,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Navigate } from "react-router-dom";
import { ImmApi } from "../../api/immApi";
import { IconEye, IconMovie, IconFile3d } from "@tabler/icons";
import { ModelsSpaceReq, ThemingTheme } from "../../api/api";
import { AssetSelector } from "../../components/asset-selector/asset-selector";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "@google/model-viewer";
import { SpaceSelector } from "../../components/space-selector";
import useResourceId from "../../hooks/useResourceId";

export const SpaceDetails = () => {
	const { id, isValid } = useResourceId();

	const { data: spaceData } = useQuery(
		["space", id],
		() => ImmApi.spaces.spaceDetails(id),
		{
			enabled: isValid,
		},
	);

	if (id == null) {
		return <Navigate to="/" />;
	}

	return (
		<Container mt="lg">
			<Paper radius="lg" shadow="lg">
				<Stack spacing="lg">
					<BackgroundImage
						src={spaceData?.data.environment.image?.url ?? ""}
						radius="lg"
					>
						<Box
							mah={300}
							h={300}
							sx={{ display: "flex", alignItems: "flex-end" }}
						>
							<Group
								position="apart"
								p="lg"
								w="100%"
								sx={{
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									color: "white",
									minHeight: 50,
								}}
							>
								<Title order={1} color="white">
									{spaceData?.data.name}
								</Title>
								<Group spacing={5}>
									<IconEye color="white" strokeWidth={1} size={18} />
									<Text
										color="white"
										size="lg"
										p={0}
										m={0}
										display="inline-block"
									>
										{spaceData?.data.visits}
									</Text>
								</Group>
							</Group>
						</Box>
					</BackgroundImage>

					{spaceData && (
						<Box p={30}>
							<SpaceTheme spaceId={spaceData.data.id} />
						</Box>
					)}

					{spaceData && (
						<Box p={30}>
							<SpaceRequirementsCards spaceId={spaceData.data.id} />
						</Box>
					)}
				</Stack>
			</Paper>
		</Container>
	);
};

type SpaceReqProps = {
	spaceId: number;
};

type SpaceThemeProps = {
	spaceId: number;
};

const SpaceTheme = ({ spaceId }: SpaceThemeProps) => {
	const { t } = useTranslation();
	const [theme, setTheme] = useState<ThemingTheme | null>(null);

	useQuery(["space", spaceId, "theme"], () => ImmApi.spaces.getTheme(spaceId), {
		onSuccess: (d) => setTheme(d.data),
	});

	const updateTheme = useMutation(
		({ spaceId, data }: { spaceId: number; data: ThemingTheme }) =>
			ImmApi.spaces.updateTheme(spaceId, data),
	);

	const onChangeColor = (idx: number, value: string) => {
		setTheme((current) => {
			if (!current) return null;

			const copy = current;
			copy.colors[idx].value = value;
			return { ...current, ...copy };
		});
	};

	const onSubmitTheme = () => {
		if (!theme) return;
		updateTheme.mutate({ spaceId: spaceId, data: theme });
	};

	return (
		<>
			<Box>
				<Text size="lg" weight={500}>
					{t("theme")}
				</Text>
				<Text color="dimmed">{t("theme_desc")}</Text>
				<Group my="lg">
					{theme?.colors.map((c, idx) => (
						<ColorInput
							// biome-ignore lint/suspicious/noArrayIndexKey: shoud be safe
							key={idx}
							placeholder="Pick color"
							label={c.name}
							value={theme.colors[idx].value}
							onChange={(e) => onChangeColor(idx, e)}
						/>
					))}
				</Group>
				<Button onClick={onSubmitTheme}>Save Theme</Button>
			</Box>
		</>
	);
};

const SpaceRequirementsCards = ({ spaceId }: SpaceReqProps) => {
	const { t } = useTranslation();
	const [opened, { open, close }] = useDisclosure();
	const [openedSS, { open: openSS, close: closeSS }] = useDisclosure();
	const [selectedSlot, setSelectedSlot] = useState(0);
	const [filter, setFilter] = useState<
		"presentation" | "video" | "image" | "model" | "portal" | undefined
	>(undefined);
	const queryClient = useQueryClient();
	const [activePage, setPage] = useState(0);

	const { data } = useQuery(["space", spaceId, "slos", activePage], () =>
		ImmApi.spaces.listSlots(spaceId, {
			page: activePage,
		}),
	);

	const deleteSlot = useMutation(
		({ spaceId, slotId }: { spaceId: number; slotId: number }) =>
			ImmApi.spaces.deAssignSlot(spaceId, slotId),
		{
			onSuccess: () =>
				queryClient.invalidateQueries(["space", spaceId, "slos", activePage]),
		},
	);

	const assignAsset = useMutation(
		({
			spaceId,
			slotId,
			assetId,
		}: {
			spaceId: number;
			slotId: number;
			assetId: number;
		}) => ImmApi.spaces.assignAssetToSlot(spaceId, slotId, assetId),
	);

	const openAssetSelector = (slotId: number, filter: string | undefined) => {
		if (
			filter !== "presentation" &&
			filter !== "video" &&
			filter !== "image" &&
			filter !== "model" &&
			filter !== "portal"
		) {
			setFilter(undefined);
		} else {
			setFilter(filter);
		}

		setSelectedSlot(slotId);

		if (filter === "portal") {
			openSS();
		} else {
			open();
		}
	};

	const assetSelected = (assetId: number) => {
		assignAsset.mutate(
			{
				spaceId: spaceId,
				slotId: selectedSlot,
				assetId: assetId,
			},
			{
				onSuccess: () => queryClient.invalidateQueries(["space", spaceId]),
			},
		);
		close();
		closeSS();
	};

	return (
		<>
			<Box>
				<Text size="lg" weight={500}>
					{t("slots")}
				</Text>
				<Text color="dimmed">{t("slots_desc")}</Text>
				<SimpleGrid cols={3} mt="lg">
					{data?.data?.items.map((r: ModelsSpaceReq) => (
						<Card withBorder shadow="sm" key={r.id}>
							<ReqCardSection req={r} />
							<Group position="apart" mt="md" mb="xs">
								<Text>{r.env_slot?.name}</Text>
								<Badge color="pink" variant="light">
									{r.env_slot?.asset_type || "empty"}
								</Badge>
							</Group>
							<Text size="sm" color="dimmed">
								{r.env_slot?.description}
							</Text>
							{r.portal && (
								<Text
									size="sm"
									color="dimmed"
								>{`${r.portal?.organisation?.name} - ${r.portal?.name}`}</Text>
							)}
							<Group grow mt="md">
								<Button
									variant="light"
									disabled={r.asset === null && r.portal === null}
									onClick={() => deleteSlot.mutate({ spaceId, slotId: r.id })}
								>
									Sil
								</Button>
								<Button
									variant="filled"
									onClick={() =>
										openAssetSelector(r.id, r.env_slot?.asset_type)
									}
								>
									{"Değiştir"}
								</Button>
							</Group>
						</Card>
					))}
				</SimpleGrid>
				<Pagination
					value={activePage + 1}
					onChange={(page) => setPage(page - 1)}
					total={data?.data.total_pages ?? 0}
					mt="lg"
					position="center"
				/>
			</Box>
			<Modal opened={opened} onClose={close} size="xl">
				<AssetSelector onSelected={assetSelected} filter={filter} />
			</Modal>
			<Modal opened={openedSS} onClose={closeSS} size="xl">
				<SpaceSelector onSelected={assetSelected} />
			</Modal>
		</>
	);
};

const ReqCardSection = ({ req }: { req: ModelsSpaceReq }) => {
	if (req.env_slot?.asset_type === "image")
		return (
			<Card.Section>
				<Image
					src={req.asset?.url}
					withPlaceholder
					height={160}
					onClick={() => req.asset && window.open(req.asset.url, "_blank")}
					sx={{
						":hover": {
							cursor: req.asset && "pointer",
						},
						height: 160,
					}}
				/>
			</Card.Section>
		);

	if (req.env_slot?.asset_type === "video")
		return (
			<Card.Section
				sx={{
					":hover": {
						cursor: req.asset && "pointer",
					},
					height: 160,
				}}
			>
				<Flex
					justify="center"
					align="center"
					sx={{
						top: 0,
						bottom: 0,
						width: "100%",
						height: "100%",
						overflow: "hidden",
					}}
				>
					{req.asset ? (
						// biome-ignore lint/a11y/useMediaCaption: <explanation>
						<video>
							<source src={req.asset?.url} />
						</video>
					) : (
						<IconMovie size={50} />
					)}
				</Flex>
			</Card.Section>
		);

	if (req.env_slot?.asset_type === "model")
		return (
			<Card.Section
				sx={{
					":hover": {
						cursor: req.asset && "pointer",
					},
					height: 160,
				}}
			>
				<Flex
					justify="center"
					align="center"
					sx={{ height: "100%", width: "100%", flex: 1 }}
				>
					{req.asset ? (
						<model-viewer src={req.asset.url as string} camera-controls />
					) : (
						<IconFile3d size={50} />
					)}
				</Flex>
			</Card.Section>
		);

	if (req.env_slot?.asset_type === "portal")
		return (
			<Card.Section
				sx={{
					":hover": {
						cursor: req.asset && "pointer",
					},
					height: 160,
				}}
			>
				<Image
					src={req.portal?.environment?.image?.url ?? ""}
					withPlaceholder
					height={160}
					// onClick={() =>
					//   req.asset && window.open(req.asset.url, "_blank")
					// }
					sx={{
						":hover": {
							cursor: req.asset && "pointer",
						},
						height: 160,
					}}
				/>
			</Card.Section>
		);

	return <Card.Section />;
};
