import { Box } from "@mantine/core";
import {
	AvatarCreator,
	AvatarExportedEvent,
} from "@readyplayerme/react-avatar-creator";

type AvatarContainerProps = {
	type?: "halfbody" | "fullbody";
	quickStart?: boolean;
	callBack: (event: AvatarExportedEvent) => void;
};

export const AvatarContainer = ({
	type,
	quickStart,
	callBack,
}: AvatarContainerProps) => {
	// useEffect(() => {
	//   const subscribe = (e: any) => {
	//     const json = parse(e);
	//     if (json?.source !== "readyplayerme") {
	//       return;
	//     }

	//     if (json.eventName === "v1.frame.ready" && frame.current?.contentWindow) {
	//       frame.current.contentWindow.postMessage(
	//         JSON.stringify({
	//           target: "readyplayerme",
	//           type: "subscribe",
	//           eventName: "v1.**",
	//         }),
	//         "*"
	//       );
	//     }

	//     // Get avatar GLB URL
	//     if (json.eventName === "v1.avatar.exported") {
	//       console.log(json);
	//       callBack(json.data.url);
	//     }
	//   };

	//   window.addEventListener("message", subscribe, false);
	//   document.addEventListener("message", subscribe, false);

	//   return () => {
	//     window.removeEventListener("message", subscribe, false);
	//     document.removeEventListener("message", subscribe, false);
	//   };
	// }, [callBack]);

	return (
		<Box
			sx={{
				height: "90vh",
				width: "100%",
				display: "flex",
				justifyContent: "center",
			}}
		>
			{/* <iframe
        title="avatar"
        ref={frame}
        id="frame"
        allow="camera *; microphone *; clipboard-write"
        src={
          type
            ? `https://nara.readyplayer.me/avatar?frameApi&clearCache&bodyType=${type}`
            : "https://nara.readyplayer.me/avatar?frameApi&clearCache"
        }
        style={{
          height: "100%",
          width: "100%",
          margin: "0",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
          fontSize: "14px",
          border: "none",
        }}
      ></iframe> */}
			<AvatarCreator
				subdomain="nara"
				config={{
					clearCache: true,
					bodyType: type,
					quickStart: quickStart,
					language: "tr",
				}}
				style={{
					width: "100%",
				}}
				onAvatarExported={callBack}
			/>
		</Box>
	);
};

export default AvatarContainer;
