import {
	Container,
	Paper,
	TextInput,
	Group,
	Text,
	Stack,
	Button,
	Title,
	Box,
	Alert,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { useState, useRef } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import {
	ImmApi,
	AuthServiceResetPasswordParameters,
	HttpErrHTTPError,
} from "@api";

interface FormValues {
	email: string;
}

export const ResetPassword = () => {
	const navigate = useNavigate();
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);
	const { t } = useTranslation();

	const [captcha, setCaptcha] = useState<string | null>(null);

	const captchaRef = useRef<ReCAPTCHA | null>(null);

	const form = useForm({
		initialValues: {
			email: "",
		},
		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : t("invalid email")),
		},
	});

	const sendRequest = useMutation(
		(request: AuthServiceResetPasswordParameters) =>
			ImmApi.auth.requestPasswordReset(request),
	);

	const handleSave = (values: FormValues) => {
		if (captcha === null) {
			setError(t("invalid_captcha"));
			return;
		}
		sendRequest.mutate(
			{ email: values.email, captcha: captcha },
			{
				onSuccess: () => {
					setSuccess(true);
					setTimeout(() => {
						navigate("/sign-in");
					}, 5000);
				},
				onError: (error) => {
					if (axios.isAxiosError<HttpErrHTTPError>(error)) {
						setError(
							error.response ? error.response.data.message : t("unknown_error"),
						);
					}
				},
			},
		);
	};

	if (success) {
		return (
			<Container>
				<Paper p={30} radius="lg" shadow="lg">
					<Title order={2} mb="lg">
						{t("request_success")}
					</Title>
					<Stack>
						<Text>
							{form.values.email}
							{t("reset_email_msg")}
						</Text>
						<Text color="dimmed">{t("redirect_msg")}</Text>
					</Stack>
				</Paper>
			</Container>
		);
	}

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Title order={2} mb="lg">
						{t("password_reset")}
					</Title>
					<Stack>
						<TextInput
							label={t("email")}
							withAsterisk
							{...form.getInputProps("email")}
						/>

						{error && (
							<Alert
								icon={<IconAlertCircle size="1rem" />}
								title={t("error")}
								color="red"
							>
								{error}
							</Alert>
						)}
						<Box>
							<ReCAPTCHA
								ref={captchaRef}
								sitekey="6LdNK58mAAAAAEomnC93pIGXXvJ0q2EYpKj9D9rX"
								onChange={setCaptcha}
								size="normal"
							/>
						</Box>
						<Group>
							<Button color="blue" type="submit">
								{t("ok")}
							</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								{t("cancel")}
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};

export default ResetPassword;
