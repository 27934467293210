import { Api } from "./api";
import storage from "../helpers/storage";

export const ImmApi = new Api<string>({
	baseURL: process.env.REACT_APP_API_BASEPATH,
	securityWorker: (token: string | null) => ({
		headers: { Authorization: `Bearer ${token}` },
	}),
});

const tk = storage.getItem<string>("token");

if (tk) {
	ImmApi.setSecurityData(tk);
}

// ArdesApi.instance.interceptors.request.use(authRequestInterceptor);

// export const refreshTokenFn = async () => {
//   const refreshToken = storage.getItem<string>("refresh");
//   const response = await API.get<TokenResponse>(
//     `oauth2/token?grant_type=refresh_token&client_id=000000&refresh_token=${refreshToken}`
//   );
//   return response.data;
// };

ImmApi.instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		const errMessage =
			(error.response.data.message as string) ||
			(error.response.data.error as string);
		if (
			errMessage &&
			(errMessage.includes("expired access token") ||
				errMessage.includes("invalid access token")) &&
			!originalRequest._retry
		) {
			originalRequest._retry = true;
			//   const response = await memoizedRefreshTokenFn();
			//   storage.setItem<string>("token", response.access_token);
			//   storage.setItem<string>("refresh", response.refresh_token);
			//   storage.setItem<number>("expire", response.expires_in);

			return ImmApi.instance(originalRequest);
		}

		if (errMessage?.includes("invalid_grant")) {
			storage.clearItem("token");
			storage.clearItem("refresh");
			storage.clearItem("expire");
			//   useFormStore.getState().setUser(null);
			window.location.href = "/login";
			return;
		}

		if (errMessage?.includes("incomplete registration")) {
			window.location.href = "/complete-registration";
			return;
		}

		if (errMessage?.includes("not verified")) {
			window.location.href = "/login";
			return;
		}

		return Promise.reject(error);
	},
);
