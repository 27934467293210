/* eslint-disable no-restricted-syntax */
import { useState } from "react";
import { useQampusStore } from "./useQampusStore";

type PermissionList = string[];

const hasAllFn =
	(state: PermissionList | null) =>
	(permissions: string[]): boolean => {
		if (state && state.length > 0) {
			return permissions.every((permission) =>
				state.includes(permission as never),
			);
		}
		return false;
	};

const hasAnyFn =
	(_state: PermissionList | null) =>
	(_permissions: string[]): boolean => {
		//TODO: fix
		return true;
		// if (state && state.length > 0) {
		//   return permissions.some((permission) =>
		//     state.includes(permission as never)
		//   );
		// }
		// return false;
	};

const doesNotHaveAllFn =
	(state: PermissionList | null) =>
	(permissions: string[]): boolean => {
		if (state && state.length > 0) {
			return permissions.every(
				(permission) => !state.includes(permission as never),
			);
		}
		return false;
	};

const doesNotHaveAnyFn =
	(state: PermissionList | null) =>
	(permissions: string[]): boolean => {
		if (state && state.length > 0) {
			return permissions.some(
				(permission) => !state.includes(permission as never),
			);
		}
		return false;
	};

function usePermissions() {
	const [state] = useState(useQampusStore.getState().permissions);
	const hasAll = hasAllFn(state);
	const hasAny = hasAnyFn(state);
	const doesNotHaveAll = doesNotHaveAllFn(state);
	const doesNotHaveAny = doesNotHaveAnyFn(state);

	return { hasAny, hasAll, doesNotHaveAny, doesNotHaveAll };
}

export default usePermissions;
