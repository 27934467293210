import {
	Container,
	Paper,
	Group,
	Stack,
	Text,
	Center,
	Loader,
	Button,
	createStyles,
	Space,
	Avatar,
	Flex,
	TextInput,
	ActionIcon,
	Modal,
} from "@mantine/core";
import { IconCopy, IconCheck, IconUser, IconMail } from "@tabler/icons-react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { OrganisationUsers } from "./OrganisationUsers";
import usePermissions from "../../hooks/usePermissions";
import { ImmApi } from "../../api/immApi";
import { notifications } from "@mantine/notifications";
import axios from "axios";
import { HttpErrHTTPError, ModelsUser } from "../../api/api";
import useQampusStore from "../../hooks/useQampusStore";
import { useDebouncedValue } from "@mantine/hooks";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
	icon: {
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[3]
				: theme.colors.gray[5],
	},

	name: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
	},
	label: { flex: 1 },
	data: { flex: 1 },
	inviteBox: {
		padding: 10,
		backgroundColor: theme.colors.gray[0],
		borderRadius: 5,
	},
	inviteLink: {
		padding: 5,
		"&:hover": {
			cursor: "pointer",
			backgroundColor: theme.colors.gray[1],
		},
	},
}));

const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

type Invitee = {
	query: string;
	isUser: boolean;
};

export const Organisation = () => {
	const [opened, { open, close }] = useDisclosure(false);
	const user = useQampusStore((state) => state.user);
	const [searchValue, setSearchValue] = useState<string>("");
	const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);
	const { classes } = useStyles();
	const [invitee, setInvitee] = useState<Invitee | null>(null);

	const { data: searchData } = useQuery(
		["user", "search", debouncedSearchValue],
		() => ImmApi.users.searchUsers({ email: debouncedSearchValue }),
		{
			enabled:
				debouncedSearchValue !== null &&
				validateEmail(debouncedSearchValue) !== null,
			select: (d) =>
				d.data.items.map((u: ModelsUser) => ({
					value: u.email,
					label: u.email,
				})),
		},
	);

	const fetchData = useQuery(
		["organisation", user?.organisation_id],
		() => ImmApi.organisations.getOrganisation(),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);
	const navigate = useNavigate();
	const { hasAny } = usePermissions();
	const clipboard = useClipboard({ timeout: 1000 });

	const invite = (query: string, isUser: boolean) => {
		if (validateEmail(query) === null) return;

		setInvitee({
			query: query,
			isUser: isUser,
		});
		open();
	};

	if (fetchData.isLoading) {
		return (
			<Container mt="lg">
				<Center>
					<Loader />
				</Center>
			</Container>
		);
	}

	if (fetchData.isError) {
		return <Text>Error...</Text>;
	}

	return (
		<Container mt="lg">
			<Paper p={30} radius="lg" shadow="lg">
				<Flex direction="column" gap="md">
					{hasAny(["organisation:write"]) && (
						<Group position="right" mb="lg">
							<Button
								variant="subtle"
								onClick={() => navigate("/organisation/edit")}
							>
								Edit
							</Button>
						</Group>
					)}
					<Group sx={{ alignItems: "stretch" }}>
						<Avatar
							size="xl"
							radius="xl"
							sx={(theme) => ({
								backgroundColor: theme.colors.gray[1],
								"&:hover": {
									backgroundColor: theme.colors.gray[2],
									cursor: "pointer",
								},
							})}
							src={
								fetchData.data.data.logo?.url
									? fetchData.data.data.logo.url
									: undefined
							}
						/>
						<Stack sx={{ alignSelf: "self-end" }}>
							<Text className={classes.data}>{fetchData.data.data.name}</Text>
							<Text className={classes.data}>
								{fetchData.data.data.description}
							</Text>
						</Stack>
					</Group>
					{(user?.role?.name === "Manager" || user?.role?.name === "Admin") && (
						<>
							<TextInput
								label="_invite_link"
								width="auto"
								disabled
								placeholder={fetchData.data.data.invite_link}
								rightSection={
									<ActionIcon
										onClick={() =>
											clipboard.copy(fetchData.data.data.invite_link)
										}
									>
										{clipboard.copied ? <IconCheck /> : <IconCopy />}
									</ActionIcon>
								}
							/>
							{/* <Select
                label="Invite User"
                searchable
                creatable
                getCreateLabel={(query) =>
                  validateEmail(query) ? (
                    <Group>
                      <IconMail size="0.9rem" />
                      <Text>{query}</Text>
                    </Group>
                  ) : (
                    <Text>{query}</Text>
                  )
                }
                value={searchValue}
                onSearchChange={setSearchValue}
                onChange={(val) => console.log(val)}
                onCreate={(query) => {
                  const item = { value: query, label: query };
                  return undefined;
                }}
                data={searchData || []}
                itemComponent={SelectItem}
              /> */}
							<TextInput
								label="_invite"
								value={searchValue}
								onChange={(e) => setSearchValue(e.currentTarget.value)}
							/>
							{searchValue.length > 0 && (
								<div className={classes.inviteBox}>
									{searchData && searchData.length > 0 ? (
										<>
											<Text size="sm" color="dimmed">
												Invite user
											</Text>
											<Group
												className={classes.inviteLink}
												onClick={() => invite(searchData[0].label, true)}
											>
												<IconUser size="0.9rem" />
												<Text size="sm">{searchData[0].label}</Text>
											</Group>
										</>
									) : (
										<>
											<Text size="sm" color="dimmed">
												Invite email
											</Text>
											<Group
												className={classes.inviteLink}
												onClick={() => invite(searchValue, false)}
											>
												<IconMail size="0.9rem" />
												<Text size="sm">{searchValue}</Text>
											</Group>
										</>
									)}
								</div>
							)}
						</>
					)}
				</Flex>
			</Paper>
			<Space h="lg" />
			<OrganisationUsers />
			<Modal opened={opened} onClose={close}>
				<Stack>
					<Text>{`Invite ${invitee?.isUser ? "user" : "email"} ${
						invitee?.query
					}`}</Text>
					<Group grow>
						<Button color="red" onClick={close}>
							_cancel
						</Button>
						<Button>_invite</Button>
					</Group>
				</Stack>
			</Modal>
		</Container>
	);
};
