import { useState } from "react";

type AvatarMetadata = {
	bodyType: "fullbody" | "halfbody";
	outfitGender: "masculine" | "feminine";
	outfitVersion: number;
	skinTone: string;
	createdAt: string;
	updatedAt: string;
};

const useAvatarMetadata = (): [
	(url: string) => Promise<AvatarMetadata>,
	boolean,
] => {
	const [isFetching, setIsFetching] = useState(false);

	const getMetadata = async (avatar: string) => {
		const url = `${avatar.substr(0, avatar.lastIndexOf("."))}.json`;
		setIsFetching(true);
		const resp = await fetch(url);
		const data: AvatarMetadata = await resp.json();
		setIsFetching(false);
		return data;
	};

	return [getMetadata, isFetching];
};

export default useAvatarMetadata;
