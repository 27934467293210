import { ImmApi, UsersvcUpdateAvatarParams } from "@api";
import {
	Box,
	Button,
	Container,
	Divider,
	Flex,
	Group,
	Image,
	LoadingOverlay,
	MediaQuery,
	Menu,
	Paper,
	Stack,
	Switch,
	Text,
	createStyles,
	useMantineTheme,
} from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import { AvatarExportedEvent } from "@readyplayerme/react-avatar-creator";
import { Avatar as RPMAvatar } from "@readyplayerme/visage";
import { IconCardboards, IconDeviceDesktop } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import AvatarContainer from "../../components/avatar/avatar";
import useAvatarMetadata from "../../hooks/useAvatarMetadata";
import { useQampusStore } from "../../hooks/useQampusStore";
import NoAvatarDesktop from "./no-avatar-desktop.png";

const useStyles = createStyles((theme) => ({
	icon: {
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[3]
				: theme.colors.gray[5],
	},

	name: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
	},
	label: { flex: 1 },
	data: { flex: 5, [theme.fn.smallerThan("sm")]: { flex: 2 } },
}));

export const UserProfile = () => {
	const { classes } = useStyles();

	const { user } = useQampusStore();
	const queryClient = useQueryClient();

	const updateAvatar = useMutation((request: UsersvcUpdateAvatarParams) =>
		ImmApi.users.updateOwnAvatar(request),
	);

	const navigate = useNavigate();
	//const [render] = useRender();
	const [getMetadata] = useAvatarMetadata();
	const [halfBody, setHalfBody] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [currentAvatar, setCurrentAvatar] = useState<string | null>(null);
	const { t } = useTranslation();
	const theme = useMantineTheme();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!user) return;

		const avatarKey = halfBody ? "avatarvr" : "avatar";
		const avatarUrl =
			user[avatarKey] !== "" ? `${user[avatarKey]}?quality=medium` : null;

		setCurrentAvatar(avatarUrl);
	}, [user, halfBody]);

	const handleAvatarLoaded = async (e: AvatarExportedEvent) => {
		const url = e.data.url;
		closeAllModals();
		setIsUploading(true);
		const metadata = await getMetadata(url);
		const pic = url.replace("glb", "png");

		const data = { avatar: url, avatar_pic: pic, type: metadata.bodyType };

		updateAvatar.mutate(data, {
			onSuccess: () => {
				queryClient.invalidateQueries(["user", "me"]);
				// updateStoredUserData(data);
			},
			onSettled: () => setIsUploading(false),
		});
	};

	// const updateStoredUserData = ({
	// 	avatar,
	// 	type,
	// }: {
	// 	avatar: string;
	// 	type: string;
	// }) => {
	// 	if (user === null) return;

	// 	setUser(user);
	// };

	return (
		<Container>
			<Paper radius="lg" shadow="lg">
				<LoadingOverlay visible={isUploading} overlayBlur={2} />
				<Group
					position="apart"
					sx={(theme) => ({
						alignItems: "stretch",

						[theme.fn.smallerThan("sm")]: {
							justifyContent: "center",
						},
					})}
				>
					<Box
						sx={(theme) => ({
							display: "flex",
							alignContent: "cener",
							width: "25%",
							[theme.fn.smallerThan("sm")]: {
								width: "50%",
							},
						})}
					>
						{currentAvatar ? (
							<RPMAvatar
								modelSrc={currentAvatar}
								style={{ height: "100%" }}
								cameraInitialDistance={1}
								cameraTarget={halfBody ? 0.7 : 1.55}
								animationSrc={
									halfBody
										? ""
										: "https://naraxr.s3.amazonaws.com/cdn/misc/male-idle.glb"
								}
							/>
						) : (
							<Image src={NoAvatarDesktop} />
						)}
					</Box>
					<Stack sx={{ flexGrow: 1 }} p={30}>
						<Group position="apart" sx={{ alignItems: "flex-start" }}>
							<Flex direction="column">
								{/* <Text
                  size="xs"
                  sx={{ textTransform: "uppercase" }}
                  weight={700}
                  color="dimmed"
                >
                  Title
                </Text> */}

								<Text size="lg" weight={500} className={classes.name}>
									{`${user?.name} ${user?.surname}`}
								</Text>

								<Text size="xs" color="dimmed">
									{user?.email}
								</Text>
							</Flex>
							<MediaQuery smallerThan="sm" styles={{ display: "none" }}>
								<Group>
									<Button
										variant="subtle"
										onClick={() =>
											openModal({
												children: (
													<AvatarContainer
														callBack={handleAvatarLoaded}
														type={halfBody ? "halfbody" : "fullbody"}
														quickStart
													/>
												),
												fullScreen: true,
												style: { padding: 0 },
											})
										}
									>
										{t("change_avatar")}
									</Button>
									<Button
										variant="subtle"
										onClick={() => navigate("/profile/password")}
									>
										{t("change_password")}
									</Button>
									<Button
										variant="subtle"
										onClick={() => navigate("/profile/edit")}
									>
										{t("edit_profile")}
									</Button>
								</Group>
							</MediaQuery>
							<MediaQuery largerThan="sm" styles={{ display: "none" }}>
								<Menu>
									<Menu.Target>
										<Button variant="subtle">Settings</Button>
									</Menu.Target>
									<Menu.Dropdown>
										<Menu.Item
											onClick={() =>
												openModal({
													title: "Select Avatar",
													children: (
														<AvatarContainer callBack={handleAvatarLoaded} />
													),
													fullScreen: true,
												})
											}
										>
											{t("change_avatar")}
										</Menu.Item>
										<Menu.Item onClick={() => navigate("/profile/password")}>
											{t("change_password")}
										</Menu.Item>
										<Menu.Item onClick={() => navigate("/profile/edit")}>
											{t("edit_profile")}
										</Menu.Item>
									</Menu.Dropdown>
								</Menu>
							</MediaQuery>
						</Group>
						<Box>
							<Text size="xs" color="dimmed" mt="md">
								<Switch
									checked={halfBody}
									label={<Text color="dimmed">Web/VR avatar</Text>}
									onChange={(event) => setHalfBody(event.currentTarget.checked)}
									onLabel={<IconCardboards size="1rem" stroke={2.5} />}
									offLabel={
										<IconDeviceDesktop
											size="1rem"
											stroke={2.5}
											color={theme.colors.blue[6]}
										/>
									}
								/>
							</Text>
							<Divider my="lg" />
						</Box>
						<Stack>
							<Group>
								<Text color="dimmed" className={classes.label}>
									{t("organisation")}
								</Text>
								<Text className={classes.data}>{user?.organisation?.name}</Text>
							</Group>
							<Group>
								<Text color="dimmed" className={classes.label}>
									{t("role")}
								</Text>
								<Text className={classes.data}>{user?.role?.name}</Text>
							</Group>
						</Stack>
					</Stack>
				</Group>
			</Paper>
			{/* <Modal
        open={avatarModalOpen}
        closeCallback={() => setAvatarModalOpen(false)}
        css={{
          width: "90vw",
          height: "85vh",
          zIndex: 10000,
        }}
      >
        <AvatarContainer callBack={handleAvatarLoaded} />
      </Modal> */}
		</Container>
	);
};
