import { Container, Paper, SimpleGrid, Card, Group, Text } from "@mantine/core";
import { IconEye } from "@tabler/icons";
import { useQuery } from "react-query";
import { ImmApi, ModelsSpace } from "@api";
import NoImage from "../../assets/no_image.png";
import useQampusStore from "../../hooks/useQampusStore";

export const Universe = () => {
	const user = useQampusStore((state) => state.user);
	const { data } = useQuery(["user", user?.id, "universe"], () =>
		ImmApi.spaces.spaceList(),
	);

	return (
		<Container size="xl" mt="lg">
			<Paper p={30} radius="lg" shadow="lg">
				<SimpleGrid
					cols={3}
					spacing="lg"
					mt="md"
					breakpoints={[
						{ maxWidth: "62rem", cols: 4, spacing: "md" },
						{ maxWidth: "48rem", cols: 2, spacing: "sm" },
						{ maxWidth: "36rem", cols: 1, spacing: "sm" },
					]}
				>
					{data?.data.items.map((space: ModelsSpace) => (
						<Card
							shadow="sm"
							padding={0}
							radius="md"
							withBorder
							sx={{
								backgroundSize: "cover",
								backgroundImage: space.environment?.image
									? `url("${space.environment.image?.url}")`
									: `url("${NoImage}")`,
								transition: "all .1s ease-in-out",
								"&:hover": {
									cursor: "pointer",
									transform: "scale(1.02)",
								},
							}}
						>
							<Card.Section sx={{ minHeight: 200 }} />

							<Group
								position="apart"
								p="lg"
								sx={{
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									color: "white",
									minHeight: 50,
								}}
							>
								<Text weight={500}>{space.name}</Text>
								<Group spacing="lg" align="center">
									<Group spacing={5}>
										<IconEye color="white" strokeWidth={1} size={18} />
										<Text
											color="white"
											size="lg"
											p={0}
											m={0}
											display="inline-block"
										>
											{space.visits}
										</Text>
									</Group>
								</Group>
							</Group>

							{/* <Button>Visit</Button> */}
						</Card>
					))}
				</SimpleGrid>
			</Paper>
		</Container>
	);
};
