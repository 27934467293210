import { useState } from "react";
import {
	Container,
	Paper,
	Stack,
	Group,
	Button,
	Select,
	Title,
	Alert,
	TextInput,
	Center,
	Loader,
	NumberInput,
	Box,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
	AdminSvcUpdateSettingsParams,
	ImmApi,
	ModelsBuild,
	ModelsEnvironment,
} from "@api";

export const SettingsPage = () => {
	const [version, setVersion] = useState<string | null>(null);
	const [saved, setSaved] = useState<boolean | null>(null);
	const [appID, setAppID] = useState("");
	const [appCode, setAppCode] = useState("");
	const [agoraID, setAgoraID] = useState("");
	const [agoraCert, setAgoraCert] = useState("");
	const [agoraExpire, setAgoraExpire] = useState(3600);
	const [shardCapacity, setShardCapacity] = useState(0);
	const [defaultEnv, setDefaultEnv] = useState<string | null>(null);
	const [registration, setRegistration] = useState<string | null>(null);

	const queryClient = useQueryClient();

	const fetchSettings = useQuery(
		["settings"],
		() => ImmApi.mng.getAppSettingsAdmin(),
		{
			onSuccess(data) {
				setVersion(data.data.web_version.id.toString());
				setAppID(data.data.photon_app_id);
				setAppCode(data.data.photon_app_code);
				setAgoraID(data.data.agora_id);
				setAgoraCert(data.data.agora_cert);
				setAgoraExpire(data.data.agora_expire_time);
			},
		},
	);

	const fetchBuilds = useQuery(["builds"], () => ImmApi.mng.listBuilds(), {
		select: (res) =>
			res.data.items.map((r: ModelsBuild) => ({
				id: r.id,
				value: r.id.toString(),
				label: r.version,
			})),
	});

	const fetchEnvironments = useQuery(
		["environments"],
		() => ImmApi.environments.listEnvironments(),
		{
			select: (res) =>
				res.data.items.map((e: ModelsEnvironment) => ({
					id: e.id,
					value: e.id.toString(),
					label: e.name,
				})),
			refetchOnWindowFocus: false,
		},
	);

	const updateSettings = useMutation(
		(request: AdminSvcUpdateSettingsParams) =>
			ImmApi.mng.settingsUpdateMultiple(request),
		{
			onSuccess: () => {
				setSaved(true);
				queryClient.invalidateQueries(["settings"]);
			},
			onError: () => {
				setSaved(false);
			},
		},
	);

	const handleSave = () => {
		if (version === null || defaultEnv === null || registration === null)
			return;

		const reg = parseInt(registration);

		if (reg !== 0 && reg !== 1 && reg !== 2) return;

		const data: AdminSvcUpdateSettingsParams = {
			photon_app_id: appID,
			photon_app_code: appCode,
			agora_id: agoraID,
			agora_cert: agoraCert,
			agora_expire_time: agoraExpire,
			build_id: parseInt(version),
			registration: reg,
			cap: shardCapacity,
		};

		updateSettings.mutate(data);
	};

	if (fetchSettings.isLoading) {
		return (
			<Center>
				<Loader />
			</Center>
		);
	}

	if (fetchSettings.isError) {
		return <h1>Error</h1>;
	}

	return (
		<Container>
			<Paper p={30} shadow="lg" radius="lg">
				<Title order={2} mb="lg">
					Settings
				</Title>
				<Stack>
					<Select
						label="Build Version"
						value={version}
						onChange={setVersion}
						data={fetchBuilds.data ? fetchBuilds.data : []}
					/>
					<TextInput
						label="Photon App ID"
						value={appID}
						onChange={(event) => setAppID(event.target.value)}
					/>
					<TextInput
						label="Photon App Code"
						value={appCode}
						onChange={(event) => setAppCode(event.target.value)}
					/>
					<TextInput
						label="Agora ID"
						value={agoraID}
						onChange={(event) => setAgoraID(event.target.value)}
					/>
					<TextInput
						label="Agora Certificate"
						value={agoraCert}
						onChange={(event) => setAgoraCert(event.target.value)}
					/>
					<NumberInput
						label="Agora Expire Time (seconds)"
						value={agoraExpire}
						onChange={(number) =>
							number ? setAgoraExpire(number) : setShardCapacity(3600)
						}
					/>
					<NumberInput
						label="Shard Capacity"
						value={shardCapacity}
						onChange={(number) =>
							number ? setShardCapacity(number) : setShardCapacity(0)
						}
					/>
					<Select
						label="Default Environment"
						value={defaultEnv}
						onChange={setDefaultEnv}
						data={fetchEnvironments.data ? fetchEnvironments.data : []}
					/>
					<Box sx={{ display: "flex", alignItems: "flex-end" }}>
						<Select
							label="Registration"
							value={registration}
							onChange={setRegistration}
							data={[
								{ value: "0", label: "No regisration" },
								{ value: "1", label: "Allowed domains" },
								{ value: "2", label: "Anyone can register" },
							]}
							sx={{ flexGrow: 1 }}
							mr={10}
						/>

						{saved !== null && (
							<Alert
								icon={<IconCheck size={16} />}
								title={saved ? "Done!" : "Error!"}
								color={saved ? "green" : "red"}
								onClose={() => setSaved(null)}
								withCloseButton
							>
								{saved ? "Settings saved." : "An error occured."}
							</Alert>
						)}
					</Box>
					<Group>
						<Button onClick={handleSave}>Save</Button>
					</Group>
				</Stack>
			</Paper>
		</Container>
	);
};
