import React from "react";
import { Table, Checkbox } from "@mantine/core";
import { useEffect, useState } from "react";

type SelectableTableItem = {
	id: number;
	[key: string]: React.ReactNode;
};

type SelectableTableProps = {
	headers: { [key: string]: React.ReactNode };
	data: SelectableTableItem[];
	onChange: (selected: number[]) => void;
};

export const SelectableTable = ({
	headers,
	data,
	onChange,
}: SelectableTableProps) => {
	const [selection, setSelection] = useState<number[]>([]);

	const toggleRow = (id: number) =>
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id],
		);

	const toggleAll = () =>
		setSelection((current) =>
			current.length === data.length ? [] : data.map((item) => item.id),
		);

	//TODO: SOOO UGLY
	useEffect(() => {
		setSelection([]);
	}, []);

	useEffect(() => {
		onChange(selection);
	}, [onChange, selection]);

	return (
		<>
			<Table fontSize="xs">
				<thead>
					<tr>
						<th>
							<Checkbox
								size="xs"
								onChange={toggleAll}
								checked={selection.length === data.length}
								indeterminate={
									selection.length > 0 && selection.length !== data.length
								}
							/>
						</th>
						{Object.keys(headers).map((key) => {
							return <th key={key}>{headers[key]}</th>;
						})}
					</tr>
				</thead>
				<tbody>
					{data.map((d) => (
						<tr key={`registrant-${d.id}`}>
							<td>
								<Checkbox
									size="xs"
									checked={selection.includes(d.id)}
									onChange={() => toggleRow(d.id)}
								/>
							</td>
							{Object.keys(headers).map((header) => {
								return (
									<td key={`registrant-${d.id}-${header}`}>{d[header]}</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
};
