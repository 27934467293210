import { AppShell } from "@mantine/core";

import { HeaderSearch } from "./../header/header";
import { NavbarSegmented } from "./navbar";
import { useQampusStore } from "../../hooks/useQampusStore";

type AppContainerProps = {
	children: React.ReactNode;
	navDisabled?: boolean;
	headerDisabled?: boolean;
	containerDisabled?: boolean;
};

export const AdminAppContainer = ({ children }: AppContainerProps) => {
	const navbar = useQampusStore((state) => state.navbar);

	return (
		<AppShell
			padding="md"
			navbar={navbar ? <NavbarSegmented /> : undefined}
			header={<HeaderSearch />}
			styles={(theme) => ({
				main: {
					backgroundColor:
						theme.colorScheme === "dark"
							? theme.colors.dark[8]
							: theme.colors.gray[0],
				},
			})}
		>
			{children}
		</AppShell>
	);
};

export default AdminAppContainer;
