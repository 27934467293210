import React from "react";

type AuthenticationProviderParams = {
	children: React.ReactNode;
};

export const AuthenticationProvider = ({
	children,
}: AuthenticationProviderParams) => {
	// TODO
	// ImmApi.instance.interceptors.response.use(
	//   (response) => {
	//     return response;
	//   },
	//   async (error) => {
	//     const errMessageAuth = error.response.data.error as string;
	//     const errMessageApi = error.response.data.message as string;
	//     if (errMessageApi && errMessageApi.includes("not permitted")) {
	//       navigate("/unauthorized");
	//       return Promise.reject(error);
	//     }

	//     if (
	//       errMessageAuth &&
	//       (errMessageAuth.includes("expired refresh token") ||
	//         errMessageAuth.includes("invalid refresh token"))
	//     ) {
	//       storage.clearItem("token");
	//       storage.clearItem("refresh");
	//       storage.clearItem("expire");
	//       setUser(null);
	//       navigate("/sign-in");
	//       return Promise.reject(error);
	//     }

	//     return Promise.reject(error);
	//   }
	// );

	return <>{children}</>;
};
