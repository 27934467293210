import {
	Container,
	Paper,
	Group,
	Stack,
	Text,
	Title,
	Button,
	Center,
	Loader,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";

import { SelectableTable } from "../../components/selectable_table/selectable_table";
import { ImmApi, AdminSvcUpdateBuildStatusParams, ModelsBuild } from "@api";

export const Builds = () => {
	const [selected, setSelected] = useState<number[]>([]);
	const queryClient = useQueryClient();

	const { hasAny } = usePermissions();

	const navigate = useNavigate();

	const updateUserStatus = useMutation(
		(request: AdminSvcUpdateBuildStatusParams) =>
			ImmApi.mng.updateBuildStatus(request),
	);

	const openDeleteModal = () =>
		openConfirmModal({
			title: <Text weight="bold">Remove user</Text>,
			centered: true,
			children: (
				<Text size="sm">Are you sure you want to remove this user ?</Text>
			),
			labels: { confirm: "Remove", cancel: "Cancel" },
			confirmProps: { color: "red" },
			onCancel: () => console.log("Cancel"),
			onConfirm: () =>
				updateUserStatus.mutate(
					{ action: "remove", builds: selected },
					{
						onSuccess: () => queryClient.invalidateQueries(["builds"]),
					},
				),
		});

	const fetchUsers = useQuery(["builds"], () => ImmApi.mng.listBuilds(), {
		select: (res) =>
			res.data.items.map((r: ModelsBuild) => ({
				id: r.id,
				version: r.version,
				notes: r.notes,
				uploaded: new Date(r.created_at).toLocaleString(),
			})),
	});

	//const updateUserStatus = useMutation(updateRegistrantStatusFn);

	if (fetchUsers.isLoading) {
		return (
			<Center>
				<Loader />
			</Center>
		);
	}

	if (fetchUsers.isError) {
		return <Text>Error...</Text>;
	}

	return (
		<Container>
			<Paper p={30} shadow="lg" radius="lg">
				<Group position="apart" mb="lg">
					<Title order={2}>Builds</Title>
					<Group>
						{hasAny(["organisation:write"]) && (
							<Button onClick={() => navigate("/admin/builds/add")}>
								Add Build
							</Button>
						)}
						{hasAny(["admin:write"]) && (
							<Button
								color="red"
								disabled={selected.length === 0}
								onClick={openDeleteModal}
							>
								Delete Builds
							</Button>
						)}
					</Group>
				</Group>
				<Stack>
					{fetchUsers.data && (
						<SelectableTable
							headers={{
								version: "Version",
								uploaded: "Uploaded At",
								notes: "Notes",
							}}
							data={fetchUsers.data}
							onChange={(slc) => setSelected(slc)}
						/>
					)}
				</Stack>
			</Paper>
		</Container>
	);
};
