import { Container, Paper, Title } from "@mantine/core";

export const NoSpace = () => {
	return (
		<Container size="xl" mt="lg">
			<Paper p={30} radius="lg" shadow="lg">
				<Title order={3}>İlk ortamını oluştur!</Title>
			</Paper>
		</Container>
	);
};
