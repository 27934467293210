import {
	Paper,
	Group,
	Title,
	Button,
	ActionIcon,
	Text,
	Modal,
	Card,
	Center,
	Badge,
	Image,
	useMantineTheme,
	MantineTheme,
} from "@mantine/core";
import {
	IconVideo,
	IconTrash,
	Icon3dCubeSphere,
	IconPresentationAnalytics,
	IconFileText,
	IconPhoto,
	IconFile,
} from "@tabler/icons";
import { openModal } from "@mantine/modals";
import { useDisclosure } from "@mantine/hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AssetDropzone } from "./AssetDropzone";
import { useTranslation } from "react-i18next";
import { ImmApi, ModelsAsset } from "@api";
import useQampusStore from "../../hooks/useQampusStore";
import { DataTable } from "mantine-datatable";
import dayjs from "dayjs";
import { useState } from "react";
import { DocumentViewer } from "../../components/doc-renderer/doc-renderer";

const GetIcon = (theme: MantineTheme, type?: string) => {
	if (!type) return <IconFile />;

	switch (type) {
		case "image":
			return <IconPhoto color={theme.colors.blue[5]} stroke={1} />;
		case "model":
			return <Icon3dCubeSphere color={theme.colors.violet[5]} stroke={1} />;
		case "presentation":
			return <IconFileText color={theme.colors.red[5]} stroke={1} />;
		case "video":
			return <IconVideo color={theme.colors.cyan[5]} stroke={1} />;
		default:
			return <IconFile color={theme.colors.gray[5]} stroke={1} />;
	}
};

function bytesToSize(bytes: number, seperator = ""): string {
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	if (bytes === 0) return "-";
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
	return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
}

export const SingleAsset = ({ asset }: { asset: ModelsAsset }) => {
	const queryClient = useQueryClient();
	const deleteAsset = useMutation(
		() => ImmApi.users.deleteUserAsset(asset.id),
		{
			onSuccess: () => queryClient.invalidateQueries(["assets", "all"]),
		},
	);

	return (
		<Card
			shadow="sm"
			padding="lg"
			radius="md"
			withBorder
			onClick={() => window.open(asset.key, "_blank", "noreferrer")}
			sx={{
				transition: "all .1s ease-in-out",
				"&:hover": {
					cursor: "pointer",
					transform: "scale(1.02)",
				},
			}}
		>
			<Card.Section>
				<Center mt="md">
					{asset.type === "model" && (
						<Icon3dCubeSphere size="40%" strokeWidth={1} />
					)}
					{asset.type === "presentation" && (
						<IconPresentationAnalytics size="40%" strokeWidth={1} />
					)}
					{asset.type === "video" && <IconVideo size="40%" strokeWidth={1} />}
					{asset.type === "image" && <Image src={asset.url} />}
				</Center>
			</Card.Section>

			<Group position="center" mt="md" mb="md">
				<Text weight={500}>{asset.description}</Text>
			</Group>
			<Group position="apart">
				<Badge color="pink">{asset.type}</Badge>
				<ActionIcon
					color="primary"
					onClick={(event: React.MouseEvent<HTMLElement>) => {
						deleteAsset.mutate();
						event.stopPropagation();
					}}
				>
					<IconTrash />
				</ActionIcon>
			</Group>
		</Card>
	);
};

export const AssetManager = () => {
	const user = useQampusStore((state) => state.user);
	const [page, setPage] = useState(0);
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { data } = useQuery(["user", user?.id, "assets"], () =>
		ImmApi.users.listUserAssets(),
	);

	const { data: storageData } = useQuery(["user", user?.id, "storage"], () =>
		ImmApi.users.getStorageInfo(),
	);

	return (
		<>
			<Paper p={30} radius="lg" shadow="lg">
				<Group mb="lg" position="apart">
					<Group spacing={5} sx={{ alignItems: "flex-end" }}>
						<Text weight={500}>
							{storageData?.data.used_space && storageData?.data.used_space > 0
								? bytesToSize(storageData?.data.used_space)
								: "0"}
						</Text>
						<Text>{t("asset_manager_of")}</Text>
						<Text weight={500}>1GB</Text>
						<Text>{t("asset_manager_used")}</Text>
					</Group>
					<Button
						onClick={() =>
							openModal({
								size: 600,
								title: <Title order={3}>{t("upload_files")}</Title>,
								children: <AssetDropzone />,
								centered: true,
								onClose: () => queryClient.invalidateQueries(["assets", "all"]),
							})
						}
					>
						{t("upload_files")}
					</Button>
				</Group>
				{data && data.data.items.length > 0 && (
					<AssetTable
						assets={data.data.items}
						size={data.data.size ?? 0}
						total={data.data.total ?? 0}
						page={page}
						onChangePage={(page) => setPage(page - 1)}
					/>
				)}
			</Paper>
		</>
	);
};

type AssetTableProps = {
	assets: ModelsAsset[];
	page: number;
	size: number;
	total: number;
	onChangePage: (page: number) => void;
};
const AssetTable = ({
	assets,
	page,
	size,
	total,
	onChangePage,
}: AssetTableProps) => {
	const theme = useMantineTheme();
	const [opened, { open, close }] = useDisclosure();
	const [selectedIndex, setSelectedIndex] = useState(0);
	const docs = assets.map((a) => ({ uri: a.url }));

	const handleRowClick = (index: number) => {
		setSelectedIndex(index);
		open();
	};

	return (
		<>
			<DataTable
				page={page + 1}
				highlightOnHover
				onPageChange={(page) => onChangePage(page - 1)}
				recordsPerPage={size}
				totalRecords={total}
				onRowClick={(_data, index, _event) => handleRowClick(index)}
				columns={[
					{
						accessor: "description",
						render: ({ description, type }) => (
							<Group>
								{GetIcon(theme, type)}
								<Text>{description}</Text>
							</Group>
						),
					},
					{
						accessor: "created_at",
						title: "Uploaded At",
						render: ({ created_at }) => dayjs(created_at).format("DD.MM.YYYY"),
					},
					{
						accessor: "size",
						title: "Size",
						render: ({ size }) => <Text>{bytesToSize(size || 0)}</Text>,
					},
				]}
				records={assets}
			/>
			<Modal opened={opened} onClose={close} centered size="70%">
				<DocumentViewer docs={docs} activeDocument={docs[selectedIndex]} />
			</Modal>
		</>
	);
};

export default AssetManager;
