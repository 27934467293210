import { AdminSvcAddEnvironmentParams, ImmApi } from "@api";
import {
	Button,
	Checkbox,
	Container,
	FileInput,
	Group,
	Image,
	NumberInput,
	Paper,
	Select,
	Stack,
	TextInput,
	Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

interface FormValues {
	name: string;
	url: string;
	type: "stand" | "fair";
	dses: boolean;
	ses: string;
	wvers: number;
	avers: number;
	cap: number;
}

export const AddEnvironment = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { uuid } = useParams();

	const [image, setImage] = useState<File | null>(null);
	const [preview, setPreview] = useState<string | undefined>(undefined);

	const createEnv = useMutation((request: AdminSvcAddEnvironmentParams) =>
		ImmApi.mng.createEnvironment(request),
	);
	const updateEnv = useMutation(
		({
			uid,
			request,
		}: {
			uid: string;
			request: AdminSvcAddEnvironmentParams;
		}) => ImmApi.mng.updateEnvironment(uid, request),
	);
	const uploadEnvironmentImage = useMutation(
		({ id, data }: { id: number; data: { file: File } }) =>
			ImmApi.mng.uploadEnvironmentImage(id, data),
	);

	const fetchEnv = useQuery(
		["environment", uuid as string],
		() => ImmApi.environments.getEnvironment(uuid as string),
		{
			enabled: uuid !== undefined,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				form.setValues(data.data);
			},
		},
	);

	const form = useForm<FormValues>({
		initialValues: {
			name: "",
			url: "",
			type: "stand",
			dses: false,
			ses: "",
			wvers: 1,
			avers: 1,
			cap: 50,
		},
		validate: {
			name: (value) =>
				value.length < 2 ? "Name must have at least 2 letters" : null,
			url: (value) => (value.length < 2 ? "Url have at least 2 letters" : null),
		},
	});

	const uploadImage = (id: number) => {
		if (image === null) return;

		uploadEnvironmentImage.mutate(
			{ id: id, data: { file: image } },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(["environments"]);
					navigate("/admin/environments");
				},
			},
		);
	};

	// create a preview as a side effect, whenever selected file is changed
	useEffect(() => {
		if (!image) {
			setPreview(undefined);
			return;
		}

		const objectUrl = URL.createObjectURL(image);
		setPreview(objectUrl);

		// free memory when ever this component is unmounted
		return () => URL.revokeObjectURL(objectUrl);
	}, [image]);

	const handleSave = (params: AdminSvcAddEnvironmentParams) => {
		if (uuid) {
			updateEnv.mutate(
				{ uid: uuid, request: params },
				{
					onSuccess: () => {
						if (image && fetchEnv.data !== undefined) {
							uploadImage(fetchEnv.data.data.id);
						} else {
							queryClient.invalidateQueries(["environments"]);
							navigate("/admin/environments");
						}
					},
				},
			);
			return;
		}

		createEnv.mutate(params, {
			onSuccess: (environment) => {
				if (image) {
					uploadImage(environment.data.id);
				} else {
					queryClient.invalidateQueries(["environments"]);
					navigate("/admin/environments");
				}
			},
		});
	};

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Title order={2} mb="lg">
						{uuid ? "Edit" : "Add"} an environment
					</Title>
					<Stack>
						{(preview || fetchEnv.data) && (
							<Image src={preview || fetchEnv.data?.data.image?.url} />
						)}
						<TextInput
							label="Name"
							withAsterisk
							{...form.getInputProps("name")}
						/>
						<TextInput
							label="URL"
							withAsterisk
							{...form.getInputProps("url")}
						/>
						<Select
							label="Type"
							defaultValue="social"
							data={[
								{ value: "social", label: "Social" },
								{ value: "meeting", label: "Meeting" },
							]}
							withAsterisk
							{...form.getInputProps("type")}
						/>
						<Checkbox
							label="Dynamic Session"
							{...form.getInputProps("dses", { type: "checkbox" })}
						/>
						<TextInput
							label="Session"
							withAsterisk
							{...form.getInputProps("ses")}
						/>
						<NumberInput
							label="Capacity"
							withAsterisk
							{...form.getInputProps("cap")}
						/>
						<NumberInput
							label="Web Version"
							withAsterisk
							{...form.getInputProps("wvers")}
						/>
						<NumberInput
							label="Android Version"
							withAsterisk
							{...form.getInputProps("avers")}
						/>
						<FileInput
							placeholder="Pick image"
							label="Environment image"
							accept="image/png"
							value={image}
							onChange={setImage}
						/>
						<Group>
							<Button
								color="blue"
								type="submit"
								disabled={!form.isDirty() && image === null}
							>
								Save
							</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								Cancel
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};
