import {
	Container,
	Paper,
	TextInput,
	Group,
	Stack,
	Button,
	Title,
	Alert,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { useState, useRef } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import {
	ImmApi,
	AuthServiceCompleteRegisterParameters,
	HttpErrHTTPError,
} from "@api";

interface FormValues {
	email: string;
	name: string;
	surname: string;
	jobTitle: string;
	department: string;
}

export const CompleteMetamaskRegister = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [error, setError] = useState<string | null>(null);

	const captchaRef = useRef<ReCAPTCHA | null>(null);
	// const register = useMutation<
	//   void,
	//   AxiosError<ApiError>,
	//   CompleteRegisterParameters,
	//   unknown
	// >(completeMetamaskRegistrationFn);

	const register = useMutation((data: AuthServiceCompleteRegisterParameters) =>
		ImmApi.auth.completeMetamaskRegistration(data),
	);

	const form = useForm({
		initialValues: {
			email: "",
			name: "",
			surname: "",
			department: "",
			jobTitle: "",
		},
		validate: {
			name: (value) =>
				value.length < 2 ? t("register_name_validation") : null,
			surname: (value) =>
				value.length < 2 ? t("register_surname_validation") : null,
			email: (value) =>
				/^\S+@\S+$/.test(value) ? null : t("register_email_validation"),
		},
	});

	const handleSave = (values: FormValues) => {
		const data: AuthServiceCompleteRegisterParameters = {
			name: values.name,
			surname: values.surname,
			email: values.email,
			job_title: values.jobTitle,
			department: values.department,
		};

		register.mutate(data, {
			onSuccess: () => {
				queryClient.invalidateQueries(["users"]);
				navigate("/");
			},
			onError: (error) => {
				captchaRef.current?.reset();

				if (axios.isAxiosError<HttpErrHTTPError>(error))
					setError(
						error.response ? error.response.data.message : t("unknown_error"),
					);
			},
		});
	};

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Title order={2} mb="lg">
						{t("complete_register")}
					</Title>
					<Stack>
						<TextInput
							label={t("email")}
							withAsterisk
							{...form.getInputProps("email")}
						/>
						<TextInput
							label={t("name")}
							withAsterisk
							{...form.getInputProps("name")}
						/>
						<TextInput
							label={t("surname")}
							withAsterisk
							{...form.getInputProps("surname")}
						/>
						<TextInput
							label={t("department")}
							{...form.getInputProps("department")}
						/>
						<TextInput
							label={t("job_title")}
							{...form.getInputProps("jobTitle")}
						/>

						{error && (
							<Alert
								icon={<IconAlertCircle size="1rem" />}
								title={t("error")}
								color="red"
							>
								{error}
							</Alert>
						)}
						<Group>
							<Button color="blue" type="submit">
								{t("save")}
							</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								{t("cancel")}
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};

export default CompleteMetamaskRegister;
