import {
	Box,
	Progress,
	PasswordInput,
	Group,
	Text,
	Center,
	Paper,
	Divider,
	Button,
	Container,
} from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { useMutation } from "react-query";
import { IconCheck, IconX } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ImmApi, UsersvcUpdatePasswordParams } from "@api";
import { getPasswordStrength } from "../../helpers/getPasswordStrength";
type PasswordRequirementsParams = {
	meets: boolean;
	label: string;
};

export function PasswordRequirement({
	meets,
	label,
}: PasswordRequirementsParams) {
	return (
		<Text color={meets ? "teal" : "red"} mt={5} size="sm">
			<Center inline>
				{meets ? (
					<IconCheck size={14} stroke={1.5} />
				) : (
					<IconX size={14} stroke={1.5} />
				)}
				<Box ml={7}>{label}</Box>
			</Center>
		</Text>
	);
}

export function EditPassword() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const updatePassword = useMutation(
		(request: UsersvcUpdatePasswordParams) =>
			ImmApi.users.updateOwnPassword(request),
		{
			onSuccess: () => navigate(-1),
		},
	);
	const [value, setValue] = useInputState("");
	const [confirmValue, setConfirmValue] = useInputState("");

	const requirements = [
		// { re: /[0-9]/, label: "Includes number" },
		// { re: /[a-z]/, label: "Includes lowercase letter" },
		// { re: /[A-Z]/, label: "Includes uppercase letter" },
		// { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
		{ re: /^.{8,}$/, label: t("password_char_len") },
	];

	const strength = getPasswordStrength(value, requirements);

	const checks = requirements.map((requirement, index) => (
		<PasswordRequirement
			// biome-ignore lint/suspicious/noArrayIndexKey: safe
			key={index}
			label={requirement.label}
			meets={requirement.re.test(value)}
		/>
	));

	checks.push(
		<PasswordRequirement
			key="confirm"
			label={t("passwords_must_match")}
			meets={value.length > 0 && value === confirmValue}
		/>,
	);

	const bars = Array(4)
		.fill(0)
		.map((_, index) => (
			<Progress
				styles={{ bar: { transitionDuration: "0ms" } }}
				value={
					value.length > 0 && index === 0
						? 100
						: strength >= ((index + 1) / 4) * 100
						  ? 100
						  : 0
				}
				color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
				// biome-ignore lint/suspicious/noArrayIndexKey: safe
				key={index}
				size={4}
			/>
		));

	const handleUpdate = () => {
		for (const check of checks) {
			if ((check.props as PasswordRequirementsParams).meets === false) return;
		}
		updatePassword.mutate({ password: value, confirm_password: confirmValue });
	};

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<PasswordInput
					value={value}
					onChange={setValue}
					label={t("password")}
					required
				/>
				<PasswordInput
					value={confirmValue}
					onChange={setConfirmValue}
					label={t("confirm_password")}
					required
				/>

				<Group spacing={5} grow mt="xs" mb="md">
					{bars}
				</Group>

				{checks}
				<Divider my="lg" />
				<Group>
					<Button color="blue" type="submit" onClick={handleUpdate}>
						{t("update")}
					</Button>
					<Button color="red" onClick={() => navigate(-1)}>
						{t("cancel")}
					</Button>
				</Group>
			</Paper>
		</Container>
	);
}
