import { useQuery } from "react-query";
import { ImmApi } from "../../api/immApi";
import {
	Box,
	Group,
	Title,
	Text,
	SimpleGrid,
	Card,
	Center,
	Image,
	TextInput,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ModelsSpace } from "../../api/api";

type SpaceSelectorProps = {
	onSelected: (id: number, desc: string) => void;
};

export const SpaceSelector = ({ onSelected }: SpaceSelectorProps) => {
	const { data } = useQuery(["spaces"], () =>
		ImmApi.organisations.listOrganisationSpaces({
			filters: `["type","stand"]`,
		}),
	);

	const { t } = useTranslation();

	return (
		<Box>
			<Group position="apart">
				<Title order={3} mb="lg">
					{t("space_selector")}
				</Title>
				<Group>
					<TextInput />
				</Group>
			</Group>
			{data && data.data.items.length > 0 && (
				<SimpleGrid
					cols={4}
					spacing="lg"
					mt="md"
					breakpoints={[
						{ maxWidth: "62rem", cols: 4, spacing: "md" },
						{ maxWidth: "48rem", cols: 2, spacing: "sm" },
						{ maxWidth: "36rem", cols: 1, spacing: "sm" },
					]}
				>
					{data.data.items.map((space: ModelsSpace) => (
						<div
							onClick={() => onSelected(space.id, space.name)}
							onKeyDown={(e) =>
								e.code === "Enter" && onSelected(space.id, space.name)
							}
						>
							<Card
								shadow="sm"
								padding="lg"
								radius="md"
								withBorder
								sx={{
									transition: "all .1s ease-in-out",
									"&:hover": {
										cursor: "pointer",
										transform: "scale(1.02)",
									},
								}}
							>
								<Card.Section>
									<Center mt="md">
										<Image src={space.environment.image?.url} />
									</Center>
									<Text>{space.name}</Text>
								</Card.Section>
							</Card>
						</div>
					))}
				</SimpleGrid>
			)}
		</Box>
	);
};
