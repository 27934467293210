import { useRef } from "react";
import { useMutation } from "react-query";
import {
	Text,
	Group,
	Stack,
	createStyles,
	ActionIcon,
	Paper,
	Button,
	Loader,
	Box,
} from "@mantine/core";
import { IconTrash, IconFileCheck } from "@tabler/icons";
import { useListState } from "@mantine/hooks";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { IconCloudUpload, IconX, IconDownload } from "@tabler/icons";
import { ImmApi } from "@api";
import { AxiosProgressEvent } from "axios";

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: "relative",
		marginBottom: 30,
	},

	dropzone: {
		borderWidth: 1,
		paddingBottom: 50,
	},

	icon: {
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[3]
				: theme.colors.gray[4],
	},

	control: {
		position: "absolute",
		width: 250,
		left: "calc(50% - 125px)",
		bottom: -20,
	},
}));

export type AssetDropzoneParams = {
	roomUuid: string;
};

export type AssetFile = {
	progress: number;
	uploading: boolean;
	uploadDone: boolean;
	error: boolean;
	file: FileWithPath;
};

export function AssetDropzone() {
	const [fileList, fileListHandlers] = useListState<AssetFile>([]);
	const { classes, theme } = useStyles();
	const openRef = useRef<() => void>(null);

	const uploadAsset = useMutation(
		(data: {
			id: number;
			description: string;
			file: File;
			progressCallback: (e: AxiosProgressEvent) => void;
		}) =>
			ImmApi.users.uploadAsset(data, {
				onUploadProgress: data.progressCallback,
			}),
		{
			onSuccess: (resp, variables) => {
				if (variables.id === undefined) return;

				if (resp.status === 200) {
					fileListHandlers.setItemProp(variables.id, "uploading", false);
					fileListHandlers.setItemProp(variables.id, "uploadDone", true);
				} else {
					fileListHandlers.setItemProp(variables.id, "uploading", false);
					fileListHandlers.setItemProp(variables.id, "uploadDone", false);
					fileListHandlers.setItemProp(variables.id, "error", true);
				}
			},
		},
	);

	const handleProgress = (index: number, progress: AxiosProgressEvent) => {
		const value = progress.total
			? Math.round((progress.loaded * 100) / progress.total)
			: 0;
		fileListHandlers.setItemProp(index, "progress", value);
	};

	const handleSelect = (files: FileWithPath[]) => {
		for (const file of files) {
			fileListHandlers.append({
				progress: 0,
				file: file,
				uploadDone: false,
				uploading: false,
				error: false,
			});
		}
	};

	const handleUpload = () => {
		fileList.forEach(async (file, index) => {
			fileListHandlers.setItemProp(index, "uploading", true);
			fileListHandlers.setItemProp(index, "uploadDone", false);

			await uploadAsset.mutateAsync({
				id: index,
				file: file.file,
				description: "",
				progressCallback: (event) => handleProgress(index, event),
			});
		});
	};

	const getBarColor = (file: AssetFile) => {
		if (file.error) return "#f03e3e";

		return file.progress === 100 ? "rgb(235,251,238)" : "rgb(231,245,255)";
	};

	return (
		<div className={classes.wrapper}>
			<Dropzone
				openRef={openRef}
				onDrop={(files) => handleSelect(files)}
				className={classes.dropzone}
				radius="md"
				accept={{
					"application/pdf": [".pdf"],
					"video/mp4": [".mp4"],
					"model/gltf-binary": [".glb"],
					"image/png": [".png"],
				}}
				maxSize={30 * 1024 ** 2}
				mb="md"
			>
				<div style={{ pointerEvents: "none" }}>
					<Group position="center">
						<Dropzone.Accept>
							<IconDownload
								size={50}
								color={theme.colors[theme.primaryColor][6]}
								stroke={1.5}
							/>
						</Dropzone.Accept>
						<Dropzone.Reject>
							<IconX size={50} color={theme.colors.red[6]} stroke={1.5} />
						</Dropzone.Reject>
						<Dropzone.Idle>
							<IconCloudUpload
								size={50}
								color={
									theme.colorScheme === "dark"
										? theme.colors.dark[0]
										: theme.black
								}
								stroke={1.5}
							/>
						</Dropzone.Idle>
					</Group>

					<Text align="center" weight={700} size="lg" mt="xl">
						<Dropzone.Accept>Drop files here</Dropzone.Accept>
						<Dropzone.Reject>Max file size is 30mb</Dropzone.Reject>
						<Dropzone.Idle>Upload assets</Dropzone.Idle>
					</Text>
					<Text align="center" size="sm" mt="xs" color="dimmed">
						Drag&apos;n&apos;drop files here to upload. We can accept only files
						that are less than 30mb in size.
					</Text>
				</div>
			</Dropzone>
			{fileList.length > 0 &&
				fileList.map((file, idx) => (
					<Stack mb="lg" spacing="xs" key={file.file.path}>
						<Paper
							withBorder
							p="xs"
							sx={{
								background: `linear-gradient(to right, ${getBarColor(file)} ${
									file.progress
								}%, white ${file.progress}%)`,
							}}
						>
							<Group position="apart">
								<Box>
									<Text>{file.file.path}</Text>
									<Text size="xs" color="dimmed">
										{`${(file.file.size / (1024 * 1024)).toFixed(2)} mb`}
									</Text>
								</Box>
								<>
									{!file.uploadDone && file.uploading && <Loader size={16} />}

									{file.uploadDone && !file.uploading && (
										<ActionIcon color="green">
											<IconFileCheck size={16} />
										</ActionIcon>
									)}

									{!file.uploadDone && !file.uploading && (
										<ActionIcon
											color="red"
											onClick={() => fileListHandlers.remove(idx)}
										>
											<IconTrash size={16} />
										</ActionIcon>
									)}
								</>
							</Group>
						</Paper>
					</Stack>
				))}
			<Group mt="lg">
				<Button color="red" variant="light">
					Cancel
				</Button>
				<Button color="blue" variant="light" onClick={handleUpload}>
					Upload
				</Button>
			</Group>
		</div>
	);
}
