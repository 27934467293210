import {
	Container,
	Paper,
	TextInput,
	Group,
	PasswordInput,
	Text,
	Stack,
	Button,
	Title,
	Box,
	Center,
	Progress,
	Transition,
	Alert,
} from "@mantine/core";
import { IconCheck, IconX, IconAlertCircle } from "@tabler/icons";
import { useState, useRef } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import useQampusStore from "../../hooks/useQampusStore";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { ImmApi, AuthServiceRegisterParameters, HttpErrHTTPError } from "@api";
import storage from "../../helpers/storage";
import { getPasswordStrength } from "../../helpers/getPasswordStrength";

type PasswordRequirementsParams = {
	meets: boolean;
	label: string;
};

interface FormValues {
	email: string;
	name: string;
	surname: string;
	password: string;
	confirmPassword: string;
	jobTitle: string;
	department: string;
}

export function PasswordRequirement({
	meets,
	label,
}: PasswordRequirementsParams) {
	return (
		<Text color={meets ? "teal" : "red"} mt={5} size="sm">
			<Center inline>
				{meets ? (
					<IconCheck size={14} stroke={1.5} />
				) : (
					<IconX size={14} stroke={1.5} />
				)}
				<Box ml={7}>{label}</Box>
			</Center>
		</Text>
	);
}

const requirements = [
	// { re: /[0-9]/, label: "Includes number" },
	// { re: /[a-z]/, label: "Includes lowercase letter" },
	// { re: /[A-Z]/, label: "Includes uppercase letter" },
	// { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
	{ re: /^.{6,}$/, label: "At least 6 characters" },
];

export const Register = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [passwordFocused, setPasswordFocused] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [success, _setSuccess] = useState<boolean>(false);
	const [captcha, setCaptcha] = useState<string | null>(null);
	const setUser = useQampusStore((state) => state.setUser);
	const setPermissions = useQampusStore((state) => state.setPermissions);
	const user = useQampusStore((state) => state.user);

	const captchaRef = useRef<ReCAPTCHA | null>(null);
	const register = useMutation((request: AuthServiceRegisterParameters) =>
		ImmApi.auth.register(request),
	);

	const form = useForm({
		initialValues: {
			email: "",
			name: "",
			password: "",
			confirmPassword: "",
			surname: "",
			department: "",
			jobTitle: "",
		},
		validate: {
			name: (value) =>
				value.length < 2 ? t("register_name_validation") : null,
			surname: (value) =>
				value.length < 2 ? t("register_surname_validation") : null,
			department: (value) =>
				value.length < 2 ? t("register_department_validation") : null,
			jobTitle: (value) =>
				value.length < 2 ? t("register_job_validation") : null,
			email: (value) =>
				/^\S+@\S+$/.test(value) ? null : t("register_email_validation"),
			password: () =>
				checks.some((req) => req.props.meets === false)
					? t("register_password_validation")
					: null,
			confirmPassword: (value, values) =>
				value !== values.password
					? t("register_confirm_password_validation")
					: null,
		},
	});
	const strength = getPasswordStrength(form.values.password, requirements);
	const checks = requirements.map((requirement, index) => (
		<PasswordRequirement
			// biome-ignore lint/suspicious/noArrayIndexKey: safe
			key={index}
			label={requirement.label}
			meets={requirement.re.test(form.values.password)}
		/>
	));

	const bars = Array(4)
		.fill(0)
		.map((_, index) => (
			<Progress
				styles={{ bar: { transitionDuration: "0ms" } }}
				value={
					form.values.password.length > 0 && index === 0
						? 100
						: strength >= ((index + 1) / 4) * 100
						  ? 100
						  : 0
				}
				color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
				// biome-ignore lint/suspicious/noArrayIndexKey: safe
				key={index}
				size={4}
			/>
		));

	const handleSave = (values: FormValues) => {
		if (captcha === null) {
			setError(t("captcha_empty_error"));
			return;
		}
		const data: AuthServiceRegisterParameters = {
			name: values.name,
			surname: values.surname,
			email: values.email,
			password: values.password,
			job_title: values.jobTitle,
			department: values.department,
			captcha: captcha,
		};

		register.mutate(data, {
			onSuccess: (response) => {
				storage.setItem<string>("token", response.data.token);
				storage.setItem<string>("refresh", response.data.refresh);
				storage.setItem<number>("expire", response.data.expire);
				setUser(response.data.user);
				// queryClient.invalidateQueries(["users"]);
				// setSuccess(true);
				// setTimeout(() => {
				//   navigate("/sign-in");
				// }, 5000);
			},
			onError: (error) => {
				captchaRef.current?.reset();
				if (axios.isAxiosError<HttpErrHTTPError>(error)) {
					setError(
						error.response ? error.response.data.message : t("unknown_error"),
					);
				}
			},
		});
	};

	useQuery(["permissions"], () => ImmApi.users.getOwnPermissions(), {
		enabled: user !== null,
		onSuccess: (resp) => {
			setPermissions(resp.data.permissions);
			navigate("/");
		},
	});

	if (success) {
		return (
			<Container>
				<Paper p={30} radius="lg" shadow="lg">
					<Title order={2} mb="lg">
						{t("registration_success")}
					</Title>
					<Stack>
						<Text>{t("registration_email_msg")}</Text>
						<Text color="dimmed">{t("redirect_msg")}</Text>
					</Stack>
				</Paper>
			</Container>
		);
	}

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Title order={2} mb="lg">
						{t("register_page_title")}
					</Title>
					<Stack>
						<TextInput
							label={t("email")}
							withAsterisk
							{...form.getInputProps("email")}
						/>
						<PasswordInput
							label={t("password")}
							withAsterisk
							{...form.getInputProps("password")}
							onFocus={() => setPasswordFocused(true)}
							onBlur={() => setPasswordFocused(false)}
						/>
						<Transition
							mounted={passwordFocused}
							transition="scale-y"
							duration={400}
							timingFunction="ease"
						>
							{(styles) => (
								<div style={{ ...styles }}>
									<Group spacing={5} grow mt="xs" mb="md">
										{bars}
									</Group>
									<Stack spacing="xs">{checks}</Stack>
								</div>
							)}
						</Transition>
						<PasswordInput
							label={t("confirm_password")}
							withAsterisk
							{...form.getInputProps("confirmPassword")}
						/>
						<TextInput
							label={t("name")}
							withAsterisk
							{...form.getInputProps("name")}
						/>
						<TextInput
							label={t("surname")}
							withAsterisk
							{...form.getInputProps("surname")}
						/>
						<TextInput
							label={t("department")}
							withAsterisk
							{...form.getInputProps("department")}
						/>
						<TextInput
							label={t("job_title")}
							withAsterisk
							{...form.getInputProps("jobTitle")}
						/>

						<Box>
							<ReCAPTCHA
								ref={captchaRef}
								sitekey="6LdNK58mAAAAAEomnC93pIGXXvJ0q2EYpKj9D9rX"
								onChange={setCaptcha}
								size="normal"
							/>
						</Box>

						{error && (
							<Alert
								icon={<IconAlertCircle size="1rem" />}
								title={t("error")}
								color="red"
							>
								{error}
							</Alert>
						)}
						<Group>
							<Button color="blue" type="submit">
								{t("save")}
							</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								{t("cancel")}
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};

export default Register;
