import { Notifications } from "@mantine/notifications";
import { Outlet } from "react-router-dom";
import { AppContainer } from "../components/appshell/appshell";

const FullLayout = ({
	headerDisabled = false,
	navDisabled = false,
	containerDisabled = false,
}: {
	navDisabled?: boolean;
	headerDisabled?: boolean;
	containerDisabled?: boolean;
}) => {
	return (
		<>
			<AppContainer
				headerDisabled={headerDisabled}
				navDisabled={navDisabled}
				containerDisabled={containerDisabled}
			>
				<Notifications />
				<Outlet />
			</AppContainer>
		</>
	);
};

export default FullLayout;
