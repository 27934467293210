import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ErrorBoundary } from "react-error-boundary";
import { MantineProvider, ColorSchemeProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import useQampusStore from "./hooks/useQampusStore";
import ErrorFallback from "./components/error/error";
import RequireAuth from "./components/auth/RequireAuth";
import { AuthenticationProvider } from "./components/auth/AuthenticationProvider";
import Lobby from "./pages/Lobby";
import CreateRoom from "./pages/CreateRoom/CreateRoom";
import SignIn from "./pages/SignIn";
import Register from "./pages/UserRegister";
import ResetPassword from "./pages/ResetPassword";
import RedeemResetToken from "./pages/RedeemResetToken";
import VerifyEmail from "./pages/VerifyEmail";
import Rooms from "./pages/Rooms/Rooms";
import RoomDetails from "./pages/RoomDetails/RoomDetails";
import { UserProfile } from "./pages/UserProfile/UserProfile";
import { EditUserProfile } from "./pages/UserProfile/EditUserProfile";
import { EditPassword } from "./pages/UserProfile/EditPassword";
import { OrganisationMain } from "./pages/Organisation/OrganisationMain";
import { OrganisationUsers } from "./pages/Organisation/OrganisationUsers";
import EditOrganisation from "./pages/Organisation/EditOrganisation";
import { BuildPage } from "./pages/Admin/UploadBuild";
import { Builds } from "./pages/Admin/BuildList";
import { SettingsPage } from "./pages/Admin/Settings";
import { Sessions } from "./pages/Sessions/Sessions";
import { Users } from "./pages/Admin/UserList";
import { Environments } from "./pages/Admin/EnvironmentList";
import Admin from "./pages/Admin";
import RequireAdmin from "./components/auth/RequireAdmin";
import PublicRoute from "./components/auth/PublicRoute";
import { AddEnvironment } from "./pages/Admin/AddEnvironment";
import { Integrations } from "./pages/Integrations/Integrations";
import { Oauth2Callback } from "./pages/Oauth2Callback/Oauth2Callback";
import { Assets } from "./pages/Assets";
import { NotFoundTitle } from "./pages/ErrorPages/404";
import { SpacesHome, CreateSpace } from "./pages/Spaces";
import { CompleteMetamaskRegister } from "./pages/CompleteRegistration";
import { NotVerified } from "./pages/NotVerified";
import FullLayout from "./layouts/FullLayout";
import AdminLayout from "./layouts/AdminLayout";
import RolePage from "./pages/Admin/RolePage";
import { Universe } from "./pages/Universe";
import { SpaceDetails } from "./pages/Spaces/SpaceDetails";
import { CreateFair } from "./pages/Fairs/CreateFair";
import { FairsHome } from "./pages/Fairs/FairsHome";
import PreRegister from "./pages/PreRegister/PreRegister";

const queryClient = new QueryClient();

function App() {
	const darkMode = useQampusStore((state) => state.darkMode);
	const toggleDarkMode = useQampusStore((state) => state.toggleDarkMode);

	const colorScheme = darkMode ? "dark" : "light";

	return (
		<ColorSchemeProvider
			colorScheme={colorScheme}
			toggleColorScheme={toggleDarkMode}
		>
			<MantineProvider
				theme={{
					colorScheme,
					colors: {
						immBlue: [
							"#a1e1f6",
							"#8adaf4",
							"#72d3f2",
							"#5bcbf0",
							"#43c4ed",
							"#2bbceb",
							"#14b5e9",
							"#1091ba",
							"#0e7fa3",
							"#0c6d8c",
						],
						immDarkBlue: [
							"#9caab0",
							"#84959c",
							"#6b7f88",
							"#526a74",
							"#395561",
							"#213f4d",
							"#082a39",
							"#072633",
							"#072633",
							"#06222e",
						],
					},
				}}
				withGlobalStyles
				withNormalizeCSS
			>
				<QueryClientProvider client={queryClient}>
					<ModalsProvider>
						<ReactQueryDevtools initialIsOpen={false} />
						<ErrorBoundary FallbackComponent={ErrorFallback}>
							<BrowserRouter>
								<GoogleOAuthProvider clientId="218581553671-no5s0nae9qrm7972niaavrlebsfmvv4t.apps.googleusercontent.com">
									<AuthenticationProvider>
										<Routes>
											{/* Admin pages with navbar, header and container */}
											<Route element={<AdminLayout />}>
												<Route
													path="/admin"
													element={
														<RequireAdmin>
															<Admin />
														</RequireAdmin>
													}
												/>
												<Route
													path="/admin/users"
													element={
														<RequireAdmin>
															<Users />
														</RequireAdmin>
													}
												/>
												<Route
													path="/admin/builds/add"
													element={
														<RequireAdmin>
															<BuildPage />
														</RequireAdmin>
													}
												/>
												<Route
													path="/admin/build"
													element={
														<RequireAdmin>
															<Builds />
														</RequireAdmin>
													}
												/>
												<Route
													path="/admin/settings"
													element={
														<RequireAdmin>
															<SettingsPage />
														</RequireAdmin>
													}
												/>
												<Route
													path="/admin/sessions"
													element={
														<RequireAdmin>
															<Sessions />
														</RequireAdmin>
													}
												/>
												<Route
													path="/admin/environments"
													element={
														<RequireAdmin>
															<Environments />
														</RequireAdmin>
													}
												/>

												<Route
													path="/admin/environments/add"
													element={
														<RequireAdmin>
															<AddEnvironment />
														</RequireAdmin>
													}
												/>
												<Route
													path="/admin/environments/:uuid"
													element={
														<RequireAdmin>
															<AddEnvironment />
														</RequireAdmin>
													}
												/>
												<Route
													path="/admin/perms"
													element={
														<RequireAdmin>
															<RolePage />
														</RequireAdmin>
													}
												/>
											</Route>
											{/* Pages with navbar, header and container */}
											<Route element={<FullLayout />}>
												<Route
													path="/"
													element={
														<RequireAuth
															hasAny={["class:read", "class:read:admin"]}
														>
															<Rooms />
														</RequireAuth>
													}
												/>
												<Route
													path="/meeting/schedule"
													element={
														<RequireAuth
															hasAny={["class:write", "class:write:admin"]}
														>
															<CreateRoom />
														</RequireAuth>
													}
												/>
												<Route
													path="/meeting"
													element={
														<RequireAuth
															hasAny={["class:read", "class:read:admin"]}
														>
															<Rooms />
														</RequireAuth>
													}
												/>
												<Route
													path="/meeting/:id"
													element={
														<RequireAuth
															hasAny={["class:read", "class:read:admin"]}
														>
															<RoomDetails />
														</RequireAuth>
													}
												/>
												<Route
													path="/meeting/:id/edit"
													element={
														<RequireAuth
															hasAny={["class:write", "class:write:admin"]}
														>
															<CreateRoom />
														</RequireAuth>
													}
												/>
												<Route
													path="/profile"
													element={
														<RequireAuth
															hasAny={["user:read", "user:read:admin"]}
														>
															<UserProfile />
														</RequireAuth>
													}
												/>
												<Route
													path="/profile/edit"
													element={
														<RequireAuth
															hasAny={["user:write", "user:write:admin"]}
														>
															<EditUserProfile />
														</RequireAuth>
													}
												/>
												<Route
													path="/profile/password"
													element={
														<RequireAuth
															hasAny={["user:write", "user:write:admin"]}
														>
															<EditPassword />
														</RequireAuth>
													}
												/>
												<Route
													path="/organisation"
													element={
														<RequireAuth
															hasAny={[
																"organisation:read",
																"organisation:read:admin",
															]}
														>
															<OrganisationMain />
														</RequireAuth>
													}
												/>
												<Route
													path="/organisation/edit"
													element={
														<RequireAuth
															hasAny={[
																"organisation:write",
																"organisation:write:admin",
															]}
														>
															<EditOrganisation />
														</RequireAuth>
													}
												/>
												<Route
													path="/organisation/users"
													element={
														<RequireAuth
															hasAny={[
																"organisation:write",
																"organisation:write:admin",
															]}
														>
															<OrganisationUsers />
														</RequireAuth>
													}
												/>

												<Route
													path="/sessions/list"
													element={
														<RequireAuth hasAny={["session:read:admin"]}>
															<Sessions />
														</RequireAuth>
													}
												/>
												<Route
													path="/integrations"
													element={
														<RequireAuth>
															<Integrations />
														</RequireAuth>
													}
												/>
												<Route
													path="/oauth2callback"
													element={
														<RequireAuth>
															<Oauth2Callback />
														</RequireAuth>
													}
												/>
												<Route
													path="/assets"
													element={
														<RequireAuth>
															<Assets />
														</RequireAuth>
													}
												/>
												<Route
													path="/universe"
													element={
														<RequireAuth>
															<Universe />
														</RequireAuth>
													}
												/>
												<Route
													path="/spaces/:id"
													element={
														<RequireAuth>
															<SpaceDetails />
														</RequireAuth>
													}
												/>
												<Route
													path="/spaces/me"
													element={
														<RequireAuth>
															<SpacesHome />
														</RequireAuth>
													}
												/>
												<Route
													path="/spaces/create"
													element={
														<RequireAuth>
															<CreateSpace />
														</RequireAuth>
													}
												/>
												<Route
													path="/fairs"
													element={
														<RequireAuth>
															<FairsHome />
														</RequireAuth>
													}
												/>
												<Route
													path="/fairs/create"
													element={
														<RequireAuth>
															<CreateFair />
														</RequireAuth>
													}
												/>
												<Route
													path="/fairs/:id"
													element={
														<RequireAuth>
															<SpaceDetails />
														</RequireAuth>
													}
												/>
											</Route>
											{/* Pages without navbar */}
											<Route element={<FullLayout navDisabled={true} />}>
												<Route
													path="/register"
													element={
														<PublicRoute>
															<Register />
														</PublicRoute>
													}
												/>
												<Route
													path="/reset-password"
													element={
														<PublicRoute>
															<ResetPassword />
														</PublicRoute>
													}
												/>
												<Route
													path="/reset-password/:token"
													element={
														<PublicRoute>
															<RedeemResetToken />
														</PublicRoute>
													}
												/>
												<Route
													path="/auth/verify/:code"
													element={
														<PublicRoute>
															<VerifyEmail />
														</PublicRoute>
													}
												/>
												<Route
													path="/pre"
													element={
														<PublicRoute>
															<PreRegister />
														</PublicRoute>
													}
												/>
												<Route
													path="/complete-registration"
													element={
														<RequireAuth>
															<CompleteMetamaskRegister />
														</RequireAuth>
													}
												/>
												<Route
													path="/not-verified"
													element={
														<RequireAuth>
															<NotVerified />
														</RequireAuth>
													}
												/>
											</Route>
											{/* Pages without header navbar and container */}
											<Route
												element={
													<FullLayout
														headerDisabled={true}
														containerDisabled={true}
														navDisabled={true}
													/>
												}
											>
												{/* <Route
                          path="/home"
                          element={
                            <PublicRoute>
                              <Home />
                            </PublicRoute>
                          }
                        /> */}
												<Route
													path="/lobby"
													element={
														<RequireAuth>
															<Lobby />
														</RequireAuth>
													}
												/>

												<Route
													path="/sign-in"
													element={
														<PublicRoute>
															<SignIn />
														</PublicRoute>
													}
												/>

												<Route path="*" element={<NotFoundTitle />} />
											</Route>

											{/* <Route path="/c/:id" element={<Class />} /> */}

											{/* <Route
                      path="/class/registration/:code"
                      element={<Registration />}
                    /> */}
											{/* <Route
                      path="/organisation"
                      element={
                        <RequireAuth
                          hasAny={[
                            "organisation:write",
                            "organisation:write:admin",
                          ]}
                        >
                          <Organisation />
                        </RequireAuth>
                      }
                    /> */}
										</Routes>
									</AuthenticationProvider>
								</GoogleOAuthProvider>
							</BrowserRouter>
						</ErrorBoundary>
					</ModalsProvider>
				</QueryClientProvider>
			</MantineProvider>
		</ColorSchemeProvider>
	);
}

export default App;
