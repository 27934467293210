import { useState } from "react";
import {
	Navbar,
	Image,
	Button,
	createStyles,
	getStylesRef,
} from "@mantine/core";
import {
	IconWorld,
	IconLogout,
	IconUsers,
	IconDeviceDesktopAnalytics,
	IconPlugConnected,
	IconHome,
	IconBuildingSkyscraper,
	IconMap2,
	IconFiles,
} from "@tabler/icons-react";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logo_dark.png";

const useStyles = createStyles((theme, _params) => {
	const icon = getStylesRef("icon");

	return {
		navbar: {
			backgroundColor:
				theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		},

		title: {
			textTransform: "uppercase",
			letterSpacing: -0.25,
		},

		link: {
			...theme.fn.focusStyles(),
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			fontSize: theme.fontSizes.sm,
			color:
				theme.colorScheme === "dark"
					? theme.colors.dark[1]
					: theme.colors.gray[7],
			backgroundColor:
				theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
			padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
			borderRadius: theme.radius.sm,
			fontWeight: 500,

			"&:hover": {
				backgroundColor:
					theme.colorScheme === "dark"
						? theme.colors.dark[6]
						: theme.colors.gray[0],
				color: theme.colorScheme === "dark" ? theme.white : theme.black,

				[`& .${icon}`]: {
					color: theme.colorScheme === "dark" ? theme.white : theme.black,
				},
			},
		},

		linkIcon: {
			ref: icon,
			color:
				theme.colorScheme === "dark"
					? theme.colors.dark[2]
					: theme.colors.gray[6],
			marginRight: theme.spacing.sm,
		},

		linkActive: {
			"&, &:hover": {
				backgroundColor: theme.fn.variant({
					variant: "light",
					color: theme.primaryColor,
				}).background,
				color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
					.color,
				[`& .${icon}`]: {
					color: theme.fn.variant({
						variant: "light",
						color: theme.primaryColor,
					}).color,
				},
			},
		},

		footer: {
			borderTop: `1px solid ${
				theme.colorScheme === "dark"
					? theme.colors.dark[4]
					: theme.colors.gray[3]
			}`,
			paddingTop: theme.spacing.md,
		},
	};
});

export function NavbarSegmented() {
	const location = useLocation();
	const { t } = useTranslation();
	const { classes, cx } = useStyles();
	const [section] = useState<"personal">("personal");

	const tabs = {
		personal: [
			{ link: "/meeting", label: t("home"), icon: IconHome },
			//{ link: "/material", label: "Material", icon: Icon3dCubeSphere },
			// { link: "/profile", label: t("profile"), icon: IconUser },
			{ link: "/assets", label: t("assets"), icon: IconFiles },
			{ link: "/fairs", label: t("navbar_fairs"), icon: IconMap2 },
			{ link: "/spaces/me", label: t("navbar_spaces"), icon: IconWorld },
			{
				link: "/integrations",
				label: t("integrations"),
				icon: IconPlugConnected,
			},
			{
				link: "/organisation",
				label: t("navbar_organisation"),
				icon: IconBuildingSkyscraper,
			},
		],

		management: [
			{ link: "/organisation/users", label: t("users"), icon: IconUsers },
			{
				link: "/sessions/list",
				label: t("sessions"),
				icon: IconDeviceDesktopAnalytics,
			},
		],
	};

	const links = tabs[section].map((item) => (
		<Button
			component={Link}
			variant="white"
			className={cx(classes.link, {
				[classes.linkActive]: location.pathname === item.link,
			})}
			to={item.link}
			key={item.label}
			leftIcon={<item.icon className={classes.linkIcon} stroke={1.5} />}
		>
			<span>{item.label}</span>
		</Button>
	));

	return (
		<Navbar height="auto" width={{ sm: 300 }} p="md" className={classes.navbar}>
			{/* <Navbar.Section>
        <SegmentedControl
          value={section}
          onChange={(value: "personal" | "management") => setSection(value)}
          transitionTimingFunction="ease"
          fullWidth
          data={getSegments()}
        />
      </Navbar.Section> */}
			<Navbar.Section mb="lg">
				<Image src={Logo} />
			</Navbar.Section>

			<Navbar.Section grow mt="xl">
				{links}
			</Navbar.Section>

			<Navbar.Section className={classes.footer}>
				<Button
					component={Link}
					variant="white"
					className={classes.link}
					to=""
					leftIcon={<IconLogout className={classes.linkIcon} stroke={1.5} />}
				>
					<span>{t("logout")}</span>
				</Button>
			</Navbar.Section>
		</Navbar>
	);
}
