import { useState } from "react";
import {
	Container,
	Paper,
	Stack,
	Group,
	Button,
	TextInput,
} from "@mantine/core";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AdminSvcCreateBuildParams, ImmApi } from "@api";

export const BuildPage = () => {
	const [version, setVersion] = useState("");
	const [notes, setNotes] = useState("");

	const navigate = useNavigate();
	const createBuild = useMutation((request: AdminSvcCreateBuildParams) =>
		ImmApi.mng.createBuild(request),
	);

	const handleUpload = () => {
		const data = {
			version: version,
			notes: notes,
		};

		createBuild.mutate(data, {
			onSuccess: () => navigate(-1),
		});
	};

	return (
		<Container>
			<div style={{ position: "relative" }}>
				<Paper p={30} shadow="lg" radius="lg">
					<Stack>
						<TextInput
							value={version}
							label="Build version"
							onChange={(evt) => setVersion(evt.target.value)}
							withAsterisk
						/>
						<TextInput
							value={notes}
							label="Build notes"
							onChange={(evt) => setNotes(evt.target.value)}
						/>
						<Group>
							<Button onClick={handleUpload}>Upload</Button>
							<Button onClick={() => navigate(-1)} color="red">
								Back
							</Button>
						</Group>
					</Stack>
				</Paper>
			</div>
		</Container>
	);
};
