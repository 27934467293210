import {
	Container,
	Paper,
	Group,
	ActionIcon,
	Text,
	Stack,
	Box,
	SegmentedControl,
	Space,
	MediaQuery,
	Flex,
	ThemeIcon,
	Center,
	Loader,
} from "@mantine/core";
import { UserProfile } from "../UserProfile/UserProfile";
import useQampusStore from "../../hooks/useQampusStore";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
	IconTrash,
	IconEdit,
	IconLock,
	IconLockOpen,
	IconUser,
	IconWorld,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { ImmApi, ModelsEventResponse, HttpErrHTTPError } from "@api";
import axios from "axios";
import { notifications } from "@mantine/notifications";

type ScheduledRoomProps = {
	room: ModelsEventResponse;
};

type EventType = "all" | "scheduled" | "live";

const ScheduledRoom = ({ room }: ScheduledRoomProps) => {
	const navigate = useNavigate();
	const user = useQampusStore((state) => state.user);
	const [hover, { open, close }] = useDisclosure(false);

	const queryClient = useQueryClient();
	const deleteEvent = useMutation(
		(uuid: string) => ImmApi.events.deleteEvent(uuid),
		{
			onSuccess: () => queryClient.invalidateQueries(["events"]),
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	const plannedStartTime = new Date(room.planned_start_time);
	const plannedEndTime = new Date(
		plannedStartTime.getTime() + room.planned_duration * 60000,
	);

	const renderIcon = (security: number) => {
		if (security === 0) {
			return (
				<ThemeIcon variant="light" radius="xl">
					<IconLockOpen size="1.2rem" />
				</ThemeIcon>
			);
		}
		if (security === 3) {
			return (
				<ThemeIcon variant="light" color="red" radius="xl">
					<IconLock size="1.2rem" />
				</ThemeIcon>
			);
		}

		return null;
	};

	return (
		<Group
			pl={10}
			pr={10}
			sx={(theme) => ({
				borderRadius: 5,
				"&:hover": {
					backgroundColor: theme.colors.gray[0],
					cursor: "pointer",
				},
			})}
			onClick={() => navigate(`/meeting/${room.uuid}`)}
			onMouseOver={open}
			onMouseLeave={close}
		>
			<Group
				sx={{
					height: 30,
					justifyContent: "flex-start",
					gap: 5,
					flex: 1,
					flexBasis: "5%",
				}}
			>
				<Text weight={600}>{plannedStartTime.toLocaleTimeString()}</Text>
				<MediaQuery smallerThan="sm" styles={{ display: "none" }}>
					<Text>{"-"}</Text>
				</MediaQuery>
				<MediaQuery smallerThan="sm" styles={{ display: "none" }}>
					<Text weight={600}>{plannedEndTime.toLocaleTimeString()}</Text>
				</MediaQuery>
			</Group>
			<Flex
				sx={(theme) => ({ flex: 2, [theme.fn.smallerThan("sm")]: { flex: 5 } })}
				align="center"
				gap="lg"
			>
				{renderIcon(room.security)}
				<Flex direction="column" sx={{ flex: 1 }}>
					<Text weight={600} truncate>
						{room.name}
					</Text>
					<Text color="dimmed" truncate>
						{room.description}
					</Text>
				</Flex>
			</Flex>
			<MediaQuery smallerThan="sm" styles={{ display: "none" }}>
				<Flex
					direction="column"
					sx={(theme) => ({
						flex: 2,
						[theme.fn.smallerThan("sm")]: { flex: 5 },
					})}
				>
					<Flex align="center" gap="xs">
						<IconUser size="1rem" />
						<Text>{`${room.creator?.name} ${room.creator?.surname}`}</Text>
					</Flex>
					<Flex align="center" gap="xs">
						<IconWorld size="1rem" />
						<Text color="dimmed">{room.space?.name}</Text>
					</Flex>
				</Flex>
			</MediaQuery>
			<MediaQuery smallerThan="sm" styles={{ display: "none" }}>
				<Flex sx={{ flex: 2 }}>
					{hover &&
						(room.admins?.includes(user?.id as number) ||
							user?.role_id === 3) && (
							<Group>
								<ActionIcon
									color="blue"
									onClick={() => navigate(`/meeting/${room.uuid}/edit`)}
								>
									<IconEdit size={16} />
								</ActionIcon>
								<ActionIcon
									color="red"
									onClick={() => deleteEvent.mutate(room.uuid)}
								>
									<IconTrash size={16} />
								</ActionIcon>
							</Group>
						)}
				</Flex>
			</MediaQuery>
		</Group>
	);
};

type ScheduledRoomsProps = {
	rooms: ModelsEventResponse[];
	date: string;
};

const ScheduledRooms = ({ rooms, date }: ScheduledRoomsProps) => {
	const fdate = new Date(date);
	return (
		<>
			<Box
				sx={(theme) => ({
					backgroundColor:
						theme.colorScheme === "dark" ? theme.colors.dark[4] : "#F2F2F7",
					padding: 10,
					borderRadius: 5,
				})}
			>
				<Text weight={600}>
					{fdate.toLocaleDateString("tr", {
						day: "numeric",
						weekday: "long",
						year: "numeric",
						month: "short",
					})}
				</Text>
			</Box>

			{rooms.map((room) => (
				<ScheduledRoom room={room} key={room.uuid} />
			))}
		</>
	);
};

export const Rooms = () => {
	const { t } = useTranslation();
	const [roomType, setRoomType] = useState<EventType>("scheduled");

	const fetchEvents = useQuery(
		["events", { type: roomType }],
		() => ImmApi.users.listAttendingEvents({ type: roomType }),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	const groupedRooms: { [key: string]: ModelsEventResponse[] } = {};

	if (fetchEvents.data?.data.items) {
		for (const room of fetchEvents.data.data.items) {
			const date = room.planned_start_time.split("T")[0];
			if (groupedRooms[date]) {
				groupedRooms[date].push(room);
			} else {
				groupedRooms[date] = [room];
			}
		}
	}

	const dates = Object.keys(groupedRooms);

	dates
		.sort((a, b) => {
			// Turn your strings into dates, and then subtract them
			// to get a value that is either negative, positive, or zero.
			return new Date(b).getTime() - new Date(a).getTime();
		})
		.reverse();

	if (fetchEvents.isFetching) {
		<Container>
			<Center>
				<Loader />
			</Center>
		</Container>;
	}

	return (
		<Box mt="xl">
			<UserProfile />
			<Space my="xl" />
			<Container>
				<Paper p={30} radius="lg" shadow="lg">
					<Stack>
						{/* <Group position="apart" sx={{ alignItems: "flex-end" }}>
              <Title order={2}>{t("meetings")}</Title>
              <Button
                leftIcon={<IconPlus />}
                onClick={() => navigate("/meeting/schedule")}
              >
                {t("schedule")}
              </Button>
            </Group> */}
						<SegmentedControl
							value={roomType}
							onChange={(value) => setRoomType(value as EventType)}
							data={[
								{ label: t("scheduled"), value: "scheduled" },
								{ label: t("live"), value: "live" },
								{ label: t("all"), value: "all" },
							]}
						/>
						<>
							{dates.length === 0 && (
								<Text>Şu an planlanmış bir etkinlik bulunmamaktadır.</Text>
							)}
							{dates.map((key) => (
								<ScheduledRooms
									rooms={groupedRooms[key]}
									date={key}
									key={key}
								/>
							))}
						</>
					</Stack>
				</Paper>
			</Container>
		</Box>
	);
};

export default Rooms;
