import { Container, Center, Loader } from "@mantine/core";
import { useQuery } from "react-query";
import { ImmApi, HttpErrHTTPError } from "@api";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import { Fairs } from ".";
import { Navigate } from "react-router-dom";
import useQampusStore from "../../hooks/useQampusStore";

export const FairsHome = () => {
	const user = useQampusStore((state) => state.user);

	const fetchEnvironments = useQuery(
		["spaces", user?.organisation_id, "fairs"],
		() =>
			ImmApi.organisations.listOrganisationSpaces({
				filters: `["type","fair"]`,
			}),
		{
			refetchOnWindowFocus: false,
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	if (fetchEnvironments.isFetching) {
		return (
			<Container size="xl" mt="lg">
				<Center>
					<Loader />
				</Center>
			</Container>
		);
	}

	if (fetchEnvironments.data?.data.total !== 0) {
		return <Fairs spaces={fetchEnvironments.data?.data.items} />;
	}
	return <Navigate to="/fairs/create" />;
};
