import { useParams } from "react-router-dom";

interface ValidatedParamResult {
	id: number;
	error: string | null;
	isValid: boolean;
}

function useResourceId(): ValidatedParamResult {
	const { id: rawValue } = useParams();

	if (rawValue === undefined) {
		return { id: 0, error: "Parameter is undefined", isValid: false };
	}

	const intValue = parseInt(rawValue, 10);
	if (Number.isNaN(intValue) || !Number.isInteger(intValue)) {
		return {
			id: 0,
			error: "Parameter is not a valid integer",
			isValid: false,
		};
	}

	return { id: intValue, error: null, isValid: true };
}

export default useResourceId;
