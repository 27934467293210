const storagePrefix = "metaqampus";

const storage = {
	getItem: <T>(key: string): T | null => {
		// biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
		let value;

		try {
			value = JSON.parse(
				window.localStorage.getItem(`${storagePrefix}_${key}`) as string,
			);
		} catch {
			value = window.localStorage.getItem(`${storagePrefix}_${key}`) as string;
		}

		return value;
	},
	setItem: <T>(key: string, value: T) => {
		// biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
		let val;

		switch (typeof value) {
			case "string":
				val = value;
				break;
			case "number":
				val = value.toString();
				break;
			case "object":
				val = JSON.stringify(value);
				break;
			default:
				return;
		}

		window.localStorage.setItem(`${storagePrefix}_${key}`, val);
	},
	clearItem: (key: string) => {
		window.localStorage.removeItem(`${storagePrefix}_${key}`);
	},
};

export default storage;
