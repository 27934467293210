import {
	ActionIcon,
	Avatar,
	Burger,
	Button,
	Group,
	Header,
	HoverCard,
	MediaQuery,
	Menu,
	Stack,
	Text,
	Tooltip,
	createStyles,
	useMantineColorScheme,
} from "@mantine/core";
import {
	IconCardboards,
	IconLanguage,
	IconMoonStars,
	IconSun,
	IconUser,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import storage from "../../helpers/storage";
import useQampusStore from "../../hooks/useQampusStore";

const useStyles = createStyles((theme) => ({
	header: {
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
	},

	inner: {
		height: 56,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},

	links: {
		[theme.fn.smallerThan("md")]: {
			display: "none",
		},
	},

	search: {
		[theme.fn.smallerThan("xs")]: {
			display: "none",
		},
	},

	link: {
		display: "block",
		lineHeight: 1,
		padding: "8px 12px",
		borderRadius: theme.radius.sm,
		textDecoration: "none",
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[0]
				: theme.colors.gray[7],
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,

		"&:hover": {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.colors.dark[6]
					: theme.colors.gray[0],
		},
	},
}));

interface HeaderSearchProps {
	links?: { link: string; label: string }[];
}

export function HeaderSearch({ links }: HeaderSearchProps) {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const navbar = useQampusStore((state) => state.navbar);
	const user = useQampusStore((state) => state.user);
	const setUser = useQampusStore((state) => state.setUser);
	const toogleNavbar = useQampusStore((state) => state.toggle);
	const { t, i18n } = useTranslation();

	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const dark = colorScheme === "dark";

	const items = links
		? links.map((link) => (
				<Text
					key={link.label}
					component={Link}
					to={link.link}
					className={classes.link}
				>
					{link.label}
				</Text>
		  ))
		: [];

	const authAction = () => {
		if (user) {
			storage.clearItem("token");
			storage.clearItem("refresh");
			storage.clearItem("expire");
			setUser(null);
		}

		navigate("/sign-in");
	};

	const enterAction = () => {
		if (!user) return;

		if (window.ReactNativeWebView !== undefined) {
			window.ReactNativeWebView.postMessage("enter");
			return;
		}

		navigate("/lobby");
	};

	const changeLanguageAnction = (lang: string) => {
		i18n.changeLanguage(lang);
	};

	return (
		<Header height={56} className={classes.header} mb={120}>
			<div className={classes.inner}>
				<Group>
					{user && <Burger opened={navbar} onClick={toogleNavbar} size="sm" />}
					{/* <MantineLogo size={28} /> */}
				</Group>

				<Group>
					<Group ml={50} spacing={5} className={classes.links}>
						{items}
					</Group>
				</Group>

				{/* <Group>
          <Autocomplete
            className={classes.search}
            placeholder="Search"
            icon={<IconSearch size={16} stroke={1.5} />}
            data={[
              "React",
              "Angular",
              "Vue",
              "Next.js",
              "Riot.js",
              "Svelte",
              "Blitz.js",
            ]}
          />
        </Group> */}
				<Group>
					{user !== null && (
						<HoverCard width={300} position="bottom" withArrow shadow="md">
							<HoverCard.Target>
								<ActionIcon color="blue" variant="light" radius={38} size={38}>
									<IconUser />
								</ActionIcon>
							</HoverCard.Target>
							<HoverCard.Dropdown>
								<Stack spacing="lg">
									<Group>
										<Avatar color="blue" radius={50} size={50}>
											{`${user?.name.charAt(0).toUpperCase()}${user?.surname
												.charAt(0)
												.toUpperCase()}`}
										</Avatar>
										<Stack spacing={0}>
											<Text weight="bold">{`${user?.name} ${user?.surname}`}</Text>
											<Text color="dimmed">{user?.email}</Text>
										</Stack>
									</Group>

									<Button variant="outline" onClick={authAction}>
										{user === null ? t("login") : t("logout")}
									</Button>
								</Stack>
							</HoverCard.Dropdown>
						</HoverCard>
					)}

					<Menu trigger="hover" shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="blue" variant="light" radius={38} size={38}>
								<IconLanguage />
							</ActionIcon>
						</Menu.Target>

						<Menu.Dropdown>
							<Menu.Label>Dil</Menu.Label>
							<Menu.Item onClick={() => changeLanguageAnction("tr")}>
								Türkçe
							</Menu.Item>
							<Menu.Item onClick={() => changeLanguageAnction("en")}>
								English
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>

					<ActionIcon
						variant="light"
						size={38}
						radius={38}
						color={dark ? "yellow" : "blue"}
						onClick={() => toggleColorScheme()}
						title="Toggle color scheme"
					>
						{dark ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
					</ActionIcon>

					{user !== null && (
						<>
							<MediaQuery smallerThan="sm" styles={{ display: "none" }}>
								<Button
									leftIcon={<IconCardboards />}
									onClick={enterAction}
									color="pink"
								>
									{t("enter_qampus")}
								</Button>
							</MediaQuery>
							<MediaQuery largerThan="sm" styles={{ display: "none" }}>
								<Tooltip label={t("enter_qampus")}>
									<ActionIcon
										color="pink"
										variant="filled"
										radius={38}
										size={38}
										onClick={enterAction}
									>
										<IconCardboards />
									</ActionIcon>
								</Tooltip>
							</MediaQuery>
						</>
					)}
				</Group>
			</div>
		</Header>
	);
}
