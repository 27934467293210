import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, Navigate } from "react-router-dom";
import useQampusStore from "../../hooks/useQampusStore";
import storage from "../../helpers/storage";
import { useQuery } from "react-query";
import { ImmApi } from "@api";

type RequireAuthProps = {
	children: JSX.Element;
	hasAny?: string[];
	hasAll?: string[];
};

function RequireAuth({ children }: RequireAuthProps) {
	const token = storage.getItem<string>("token");
	const navigate = useNavigate();
	const setUser = useQampusStore((state) => state.setUser);
	const setPermissions = useQampusStore((state) => state.setPermissions);

	const user = useQampusStore((state) => state.user);
	// const perms = useQampusStore((state) => state.permissions);
	const location = useLocation();

	useEffect(() => {
		if (!token) navigate("/sign-in");
	}, [token, navigate]);

	const { isFetched: userFetched } = useQuery(
		["user", "me"],
		() => ImmApi.users.getOwnDetails(),
		{
			enabled: token !== null,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			onSuccess: (d) => {
				setUser(d.data);
			},
		},
	);

	const { isFetched: permsFetched } = useQuery(
		["permissions", "me"],
		() => ImmApi.users.getOwnPermissions(),
		{
			enabled: token !== null,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			onSuccess: (d) => setPermissions(d.data.permissions),
		},
	);

	if (!userFetched || !permsFetched) {
		return <div />;
	}

	if (user === null) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/sign-in" state={{ from: location }} replace />;
	}

	// TODO: Enable this later
	// if (
	//   hasAny !== undefined &&
	//   permData?.data &&
	//   permData.data.permissions &&
	//   !hasAny.some((perm) => permData.data.permissions.includes(perm))
	// ) {
	//   return <Navigate to="/" state={{ from: location }} replace />;
	// }

	// if (
	//   hasAll !== undefined &&
	//   permData?.data &&
	//   !hasAll.every((perm) => permData.data.permissions.includes(perm))
	// ) {
	//   return <Navigate to="/" state={{ from: location }} replace />;
	// }

	return <>{children}</>;
}

export default RequireAuth;
