import {
	Container,
	Paper,
	SimpleGrid,
	Card,
	Group,
	Text,
	Flex,
	useMantineTheme,
} from "@mantine/core";
import { IconEye } from "@tabler/icons";
import { ModelsSpace } from "@api";

import { useNavigate } from "react-router-dom";

import Fair from "../../assets/images/expo.png";
import { useTranslation } from "react-i18next";
import Blueprint from "../../assets/images/blueprint.png";
interface Props {
	spaces: ModelsSpace[];
}
export const Fairs = ({ spaces }: Props) => {
	const navigate = useNavigate();
	const theme = useMantineTheme();
	const { t } = useTranslation();

	return (
		<Container size="xl" mt="lg">
			<Paper p={30} radius="lg" shadow="lg">
				{/* <Group position="right">
            <Button component={Link} to="/spaces/create">
              Yeni Ortam
            </Button>
          </Group> */}
				<SimpleGrid
					cols={3}
					spacing="lg"
					mt="md"
					breakpoints={[
						{ maxWidth: "62rem", cols: 4, spacing: "md" },
						{ maxWidth: "48rem", cols: 2, spacing: "sm" },
						{ maxWidth: "36rem", cols: 1, spacing: "sm" },
					]}
				>
					<Card
						shadow="sm"
						padding={0}
						radius="md"
						sx={{
							border: `0.0625rem solid ${theme.colors.blue[5]}`,
							justifyContent: "flex-end",
							backgroundSize: "cover",
							backgroundImage: `url("${Blueprint}")`,
							transition: "all .1s ease-in-out",
							"&:hover": {
								cursor: "pointer",
								transform: "scale(1.02)",
							},
						}}
						onClick={() => navigate("/fairs/create")}
					>
						<Flex justify="flex-end" sx={{ height: "100%" }}>
							<Group
								position="apart"
								mt="auto"
								p="lg"
								sx={{
									flexGrow: 1,
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									color: "white",
									minHeight: 50,
								}}
							>
								<Text weight={500}>{t("create_fair")}</Text>
							</Group>
						</Flex>
					</Card>
					{spaces.map((space: ModelsSpace) => (
						<Card
							shadow="sm"
							padding={0}
							radius="md"
							withBorder
							sx={{
								backgroundSize: "cover",
								backgroundImage: `url("${Fair}")`,
								transition: "all .1s ease-in-out",
								"&:hover": {
									cursor: "pointer",
									transform: "scale(1.02)",
								},
							}}
							onClick={() => navigate(`/fairs/${space.id}`)}
						>
							<Card.Section sx={{ minHeight: 200 }} />
							<Group
								position="apart"
								p="lg"
								sx={{
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									color: "white",
									minHeight: 50,
								}}
							>
								<Text weight={500}>{space.name}</Text>
								<Group spacing="lg" align="center">
									<Group spacing={5}>
										<IconEye color="white" strokeWidth={1} size={18} />
										<Text
											color="white"
											size="lg"
											p={0}
											m={0}
											display="inline-block"
										>
											{""}
										</Text>
									</Group>
								</Group>
							</Group>

							{/* <Button>Visit</Button> */}
						</Card>
					))}
				</SimpleGrid>
			</Paper>
		</Container>
	);
};

export default Fairs;
