import {
	Container,
	Paper,
	Text,
	Stack,
	Group,
	createStyles,
	Button,
	Box,
	Divider,
	Title,
	Anchor,
} from "@mantine/core";
import { useRef, useEffect, useState } from "react";
import { useClipboard } from "@mantine/hooks";
import { google, yahoo, ics, CalendarEvent } from "calendar-link";
import { IconCalendarEvent } from "@tabler/icons";
import { useNavigate, Navigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useQampusStore } from "../../hooks/useQampusStore";
import { useTranslation } from "react-i18next";
import { ImmApi } from "@api";
import Hls from "hls.js";
import useResourceId from "../../hooks/useResourceId";

const useStyles = createStyles(() => ({
	label: { flex: "1 1 0 !important" },
	data: { flex: "3 1 0 !important" },
}));

export const RoomDetails = () => {
	const { id, isValid } = useResourceId();

	const { classes } = useStyles();
	const navigate = useNavigate();
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const { t } = useTranslation();

	const [showStreamKey, setShowStreamKey] = useState(false);

	const clipboard = useClipboard({ timeout: 500 });
	const user = useQampusStore((state) => state.user);

	const fetchEvent = useQuery(
		["event", id],
		() => ImmApi.events.getEvent(id.toString()),
		{
			enabled: isValid,
		},
	);

	const deleteEvent = useMutation(
		(uuid: string) => ImmApi.events.deleteEvent(uuid),
		{
			onSuccess: () => navigate("/meeting"),
		},
	);

	useEffect(() => {
		if (videoRef.current === null) return;
		if (
			fetchEvent.data?.data.livefeed === undefined ||
			fetchEvent.data?.data.livefeed === ""
		)
			return;

		if (Hls.isSupported()) {
			const hls = new Hls();
			hls.loadSource(fetchEvent.data?.data.livefeed);
			hls.attachMedia(videoRef.current);
			//videoRef.current.play();
		}
	});

	const event: CalendarEvent = {
		title: fetchEvent.data?.data.name ?? "",
		description: `Aciklama: ${fetchEvent.data?.data.description}

Metaverse baglantisi: ${window.location.protocol}//${window.location.host}/lobby?s=${fetchEvent.data?.data.uuid}
Panel baglantisi: ${window.location.protocol}//${window.location.host}/meeting/${fetchEvent.data?.data.uuid}`,
		start: fetchEvent.data?.data.planned_start_time,
		duration: [fetchEvent.data?.data.planned_duration ?? 0, "minute"],
		location: `${window.location.protocol}//${window.location.host}/lobby?s=${fetchEvent.data?.data.uuid}`,
	};

	if (id == null) {
		return <Navigate to="/" />;
	}

	return (
		<Container>
			<Paper radius="lg" shadow="lg" p={30}>
				<Title order={2} mb="lg">
					{t("meeting_details")}
				</Title>
				<Stack>
					<Group>
						<Text color="dimmed" className={classes.label}>
							{t("topic")}
						</Text>
						<Text className={classes.data}>{fetchEvent.data?.data.name}</Text>
					</Group>
					<Divider my="sm" />
					<Group>
						<Text color="dimmed" className={classes.label}>
							{t("description")}
						</Text>
						<Text className={classes.data}>
							{fetchEvent.data?.data.description}
						</Text>
					</Group>
					<Divider my="sm" />
					<Group align="baseline">
						<Text color="dimmed" className={classes.label}>
							{t("date_time")}
						</Text>
						<Stack className={classes.data}>
							<Text>
								{new Date(
									fetchEvent.data?.data.planned_start_time as string,
								).toLocaleString()}
							</Text>
							<Group>
								<Button
									component="a"
									target="_blank"
									rel="noopener noreferrer"
									href={google(event)}
									leftIcon={<IconCalendarEvent size={14} />}
									variant="outline"
									color="blue"
								>
									Google Calendar
								</Button>
								<Button
									component="a"
									target="_blank"
									rel="noopener noreferrer"
									download={`metaqampus-${fetchEvent.data?.data.uuid}`}
									href={ics(event)}
									leftIcon={<IconCalendarEvent size={14} />}
									variant="outline"
									color="indigo"
								>
									Outlook (.ics)
								</Button>
								<Button
									component="a"
									target="_blank"
									rel="noopener noreferrer"
									href={yahoo(event)}
									leftIcon={<IconCalendarEvent size={14} />}
									variant="outline"
									color="violet"
								>
									Yahoo Calendar
								</Button>
							</Group>
						</Stack>
					</Group>
					{/* <Divider my="sm" />
          <Group>
            <Text color="dimmed" className={classes.label}>
              {fetchEvent.data?.security === 0 ? "Join URL" : "Registration URL"}
            </Text>

            <Tooltip label="Double click to copy">
              <Text
                className={classes.data}
                onDoubleClick={() =>
                  event.location &&
                  navigator.clipboard.writeText(event.location)
                }
              >
                {event.location}
              </Text>
            </Tooltip>
          </Group> */}
					<Divider my="sm" />
					<Group>
						<Text color="dimmed" className={classes.label}>
							{t("meeting_type")}
						</Text>
						<Text className={classes.data}>
							{fetchEvent.data?.data.security === 0
								? t("public")
								: t("private")}
						</Text>
					</Group>
					<Group>
						<Text color="dimmed" className={classes.label}>
							{t("environment")}
						</Text>
						<Text className={classes.data}>
							{fetchEvent.data?.data.space?.name}
						</Text>
					</Group>
					<Group>
						<Text color="dimmed" className={classes.label}>
							{t("join_link")}
						</Text>
						<Anchor
							className={classes.data}
							href={`${window.location.protocol}//${window.location.host}/lobby?s=${fetchEvent.data?.data.uuid}`}
						>
							{`${window.location.protocol}//${window.location.host}/lobby?s=${fetchEvent.data?.data.uuid}`}
						</Anchor>
					</Group>
					{fetchEvent.data?.data.space_id === 6 &&
						fetchEvent.data?.data.livefeed !== "" &&
						fetchEvent.data?.data.livefeed !== null && (
							<>
								<Divider my="sm" />
								{Object.hasOwn(fetchEvent.data, "liveingest") && (
									<>
										<Group>
											<Text color="dimmed" className={classes.label}>
												{t("stream_endpoint")} (RTMP)
											</Text>
											<Text className={classes.data}>
												{`rtmps://${fetchEvent.data?.data.liveingest}:443/app/`}
											</Text>
										</Group>
										<Group>
											<Text color="dimmed" className={classes.label}>
												{t("stream_key")}
												<Text
													color="blue"
													size="sm"
													className={classes.label}
													span
													onClick={() => setShowStreamKey(!showStreamKey)}
													sx={{
														"&:hover": {
															cursor: "pointer",
														},
													}}
												>
													{showStreamKey ? " (Hide)" : " (Show)"}
												</Text>
											</Text>
											<Text className={classes.data}>
												{showStreamKey
													? fetchEvent.data?.data.livekey
													: "*".repeat(40)}
											</Text>
										</Group>
									</>
								)}
								<Group>
									<Text color="dimmed" className={classes.label}>
										{t("stream")}
										<Text
											color="blue"
											size="sm"
											className={classes.label}
											span
											onClick={() =>
												clipboard.copy(fetchEvent.data?.data.livefeed)
											}
											sx={{
												"&:hover": {
													cursor: "pointer",
												},
											}}
										>
											{" (Copy)"}
										</Text>
									</Text>
									<Text truncate="end" className={classes.data}>
										{fetchEvent.data?.data.livefeed}
									</Text>
								</Group>
								<Box>
									{/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
									<video ref={videoRef} height="100%" width="100%" controls />
								</Box>
							</>
						)}
					{(fetchEvent.data?.data.admins?.includes(user?.id as number) ||
						user?.role_id === 3) && (
						<>
							<Divider my="sm" />
							<Group mb="lg">
								<Button
									color="blue"
									onClick={() =>
										navigate(`/meeting/${fetchEvent.data?.data.uuid}/edit`)
									}
								>
									{t("edit")}
								</Button>
								<Button
									color="red"
									onClick={() =>
										fetchEvent.data &&
										deleteEvent.mutate(fetchEvent.data.data.uuid)
									}
								>
									{t("delete")}
								</Button>
							</Group>
						</>
					)}
				</Stack>
			</Paper>
		</Container>
	);
};

export default RoomDetails;
