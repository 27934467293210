import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ModelsUser } from "../api/api";

type PermissionList = string[];
interface QampusState {
	user: ModelsUser | null;
	permissions: PermissionList | null;
	navbar: boolean;
	darkMode: boolean;
	app: string | null;
	sfToken: string | null;
	toggleDarkMode: () => void;
	toggle: () => void;
	setUser: (user: ModelsUser | null) => void;
	setPermissions: (perms: PermissionList | null) => void;
	setSfToken: (token: string) => void;
	setApp: (app: string | null) => void;
}

export const useQampusStore = create<QampusState>()(
	devtools(
		persist(
			(set) => ({
				navbar: true,
				user: null,
				permissions: null,
				sfToken: null,
				app: null,
				darkMode: false,
				toggleDarkMode: () => set((state) => ({ darkMode: !state.darkMode })),
				toggle: () => set((state) => ({ navbar: !state.navbar })),
				setUser: (user: ModelsUser | null) => set({ user: user }),
				setPermissions: (perms: PermissionList | null) =>
					set({ permissions: perms }),
				setSfToken: (token: string) => set({ sfToken: token }),
				setApp: (app: string | null) => set({ app: app }),
			}),
			{
				name: "qampus-storage",
			},
		),
	),
);

export default useQampusStore;
