import { ModelsAsset, ModelsUser } from "@api";
import { Box, Group, Modal, Progress, Stack, Text } from "@mantine/core";
import { AvatarExportedEvent } from "@readyplayerme/react-avatar-creator";
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import NaraLogoWhite from "../../assets/nara-beyaz.png";
import NaraLogo from "../../assets/nara.png";
import { DropzoneFullScreen } from "../../components/dropzone/DropzoneFullScreen";
import storage from "../../helpers/storage";
import { useQampusStore } from "../../hooks/useQampusStore";
import AvatarContainer from "../avatar/avatar";

enum AttendeeStatus {
	Spectator = 0,
	Cohost = 1,
}

type KampusArea = {
	area: string;
	session: string;
};

type LoginParams = {
	token: string;
	app: string;
	area?: string | null;
	session?: string | null;
};

const UnityConfig = {
	alpha: true,
	antialias: true,
	depth: true,
	failIfMajorPerformanceCaveat: true,
	premultipliedAlpha: true,
	preserveDrawingBuffer: true,
	stencil: true,
	desynchronized: true,
	xrCompatible: false,
	productName: "MetaQampus",
	productVersion: "0.0.1",
	companyName: "NaraXR",
	cacheControl: handleCacheControl,
};

interface RendererParams {
	user: ModelsUser | null | undefined;
	version: string;
}
declare global {
	interface Window {
		unityInstance: {
			SendMessage: (var1: string, var2: string, var3: string) => void;
		};
		mainCanvas: HTMLCanvasElement | null;
		mainContext: CanvasRenderingContext2D | null;
		inMemCanvas: HTMLCanvasElement | null;
		inMemContext: CanvasRenderingContext2D | null;
		canvasWidth: number;
		canvasHeight: number;
		angle: number;
	}
}

window.angle = 0;

function handleCacheControl(url: string) {
	if (url.match(/\.data/) || url.match(/\.bundle/)) {
		return "must-revalidate";
	}
	if (url.match(/\.mp4/) || url.match(/\.wav/)) {
		return "immutable";
	}
	return "no-store";
}

function NewRenderer({ version }: RendererParams) {
	const {
		unityProvider,
		isLoaded,
		loadingProgression,
		sendMessage,
		addEventListener,
		removeEventListener,
	} = useUnityContext({
		loaderUrl: `${process.env.REACT_APP_BUILD_BASEPATH}${version}/webgl-${version}.loader.js`,
		dataUrl: `${process.env.REACT_APP_BUILD_BASEPATH}${version}/webgl-${version}.data.gz`,
		frameworkUrl: `${process.env.REACT_APP_BUILD_BASEPATH}${version}/webgl-${version}.framework.js.gz`,
		codeUrl: `${process.env.REACT_APP_BUILD_BASEPATH}${version}/webgl-${version}.wasm.gz`,
		webglContextAttributes: UnityConfig,
	});

	window.unityInstance = { SendMessage: sendMessage };
	const appCode = useQampusStore((state) => state.app);
	const [avatarModalOpen, setAvatarModalOpen] = useState(false);
	const [attendeeStatus, setAttendeeStatus] = useState(
		AttendeeStatus.Spectator,
	);
	const [_area, setArea] = useState<KampusArea | null>(null);
	const [searchParams, _setSearchParams] = useSearchParams();

	const mainCanvasRef = useRef<HTMLCanvasElement>(null);
	const memCanvasRef = useRef<HTMLCanvasElement>(null);

	window.mainCanvas = mainCanvasRef.current;
	if (mainCanvasRef.current) {
		window.mainContext = mainCanvasRef.current.getContext("2d");
	}

	window.inMemCanvas = memCanvasRef.current;
	if (memCanvasRef.current) {
		window.inMemContext = memCanvasRef.current.getContext("2d");
	}

	window.alert = console.log;

	// useEffect(() => {
	//   if (os === "android") {
	//     requestFullscreen(true);
	//   }
	// });

	useEffect(() => {
		const DoLogin = () => {
			if (appCode === null) return;

			const data: LoginParams = {
				token: storage.getItem<string>("token") || "",
				app: appCode,
			};

			if (searchParams.has("s")) {
				data.session = searchParams.get("s");
			}

			sendMessage("WebInterface", "Login", JSON.stringify(data));
		};

		const OpenAvatarModal = () => {
			setAvatarModalOpen(true);
		};

		const AttendeeStatusChanged = (newStatus: AttendeeStatus) => {
			setAttendeeStatus(newStatus);
		};

		const UpdateAreaInformation = (area: string, session: string) => {
			setArea({
				area: area,
				session: session,
			});

			// setSearchParams({ area: area, session: session });
		};

		const OpenLink = (url: string) => {
			window.open(url, "_blank");
		};

		addEventListener("OpenAvatarMenu", OpenAvatarModal);
		addEventListener("GameStart", DoLogin);
		addEventListener("AttendeeStatusChanged", AttendeeStatusChanged);
		addEventListener("UpdateAreaInformation", UpdateAreaInformation);
		addEventListener("OpenLink", OpenLink);

		return () => {
			removeEventListener("OpenAvatarMenu", OpenAvatarModal);
			removeEventListener("GameStart", DoLogin);
			removeEventListener("AttendeeStatusChanged", AttendeeStatusChanged);
			removeEventListener("UpdateAreaInformation", UpdateAreaInformation);
			removeEventListener("OpenLink", OpenLink);
		};
	}, [
		addEventListener,
		removeEventListener,
		sendMessage,
		// setSearchParams,
		searchParams,
		appCode,
	]);

	const loadingPercentage = Math.round(loadingProgression * 100);

	const handleAvatarLoaded = (e: AvatarExportedEvent) => {
		const url = e.data.url;
		console.log("Avatar loaded: ", url);
		//setQsettings({ ...qsettings, avatar: url });

		sendMessage("WebInterface", "AvatarSelected", url);
		setAvatarModalOpen(false);
	};

	const sendSpawnAsset = (asset: ModelsAsset) => {
		if (asset.type === "presentation") {
			sendMessage("WebInterface", "SpawnPDF", asset.key);
		}
	};

	const handleUpload = (asset: ModelsAsset) => {
		sendSpawnAsset(asset);
	};

	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			{attendeeStatus === AttendeeStatus.Cohost && (
				<DropzoneFullScreen
					roomId={"testtest"}
					active={true}
					callback={handleUpload}
				/>
			)}
			<Modal
				opened={avatarModalOpen}
				onClose={() => setAvatarModalOpen(false)}
				fullScreen
			>
				<AvatarContainer callBack={handleAvatarLoaded} />
			</Modal>

			{isLoaded === false ? (
				// We'll conditionally render the loading overlay if the Unity
				// Application is not loaded.
				<Box
					sx={{
						display: "flex",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Stack align="center">
						<img src={NaraLogo} alt="logo" height="25%" width="25%" />
						<Progress value={loadingPercentage} w="50%" h={10} />
					</Stack>
				</Box>
			) : (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						right: 0,
						width: "100%",
					}}
				>
					<Group position="center" spacing={5}>
						<Text color="white">{"Powered by"}</Text>
						<img src={NaraLogoWhite} alt="logo" height="auto" width="5%" />
					</Group>
				</Box>
			)}

			<Box sx={{ height: "100%", width: "100%" }}>
				<Unity
					unityProvider={unityProvider}
					devicePixelRatio={devicePixelRatio}
					tabIndex={0}
					style={{
						height: "100%",
						width: "100%",
						display: isLoaded ? "block" : "none",
					}}
				/>
			</Box>

			<canvas
				id="inMem_Canvas"
				ref={memCanvasRef}
				height="450"
				width="450"
				style={{ display: "none" }}
			/>
			<canvas
				id="myCanvas"
				ref={mainCanvasRef}
				height="450"
				width="450"
				style={{ display: "none" }}
			/>
		</Box>
	);
}

export default NewRenderer;
