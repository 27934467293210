import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { ImmApi } from "@api";

// https://sketchfab.com/oauth2/authorize/?response_type=code&client_id=OfreRzC1HoUrLMLTQovfjfBR8EV2bts1vRD0GPiN&redirect_uri=http://localhost/oauth2callback

import { useEffect } from "react";

export const Oauth2Callback = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const params = new URLSearchParams(search);
	const code = params.get("code") ?? "";
	const state = params.get("state") ?? "";

	// Only enable the query if both code and state are not null
	const queryEnabled = code !== "" && state !== "";

	const getToken = useQuery(
		["integrations", { code, state }],
		() => ImmApi.integrations.getToken({ code, state }),
		{
			enabled: queryEnabled,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	useEffect(() => {
		if (getToken.isSuccess) {
			navigate("/integrations");
		}
	}, [getToken.isSuccess, navigate]);

	if (!queryEnabled) {
		return (
			<div>
				<p>Invalid params</p>
			</div>
		);
	}

	if (getToken.isFetching) {
		return <h1>Please wait...</h1>;
	}

	if (getToken.isError) {
		return <h1>An error occurred...</h1>;
	}

	return <h1>You can close this page</h1>;
};
