import {
	Paper,
	TextInput,
	Container,
	Title,
	Stack,
	Select,
	Group,
	Button,
	Card,
	Text,
	SimpleGrid,
	Modal,
	Center,
	Loader,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import {
	ImmApi,
	HttpErrHTTPError,
	ModelsEnvironment,
	OrganisationSvcCreateSpaceParameters,
} from "@api";
import useQampusStore from "../../hooks/useQampusStore";
import { useQuery, useMutation } from "react-query";
import { z } from "zod";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import NoImage from "../../assets/no_image.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListState, useDisclosure } from "@mantine/hooks";
import { AssetSelector } from "../../components/asset-selector/asset-selector";

export const CreateFair = () => {
	const [step, setStep] = useState(0);
	const [env, setEnv] = useState<ModelsEnvironment | null>(null);
	const navigate = useNavigate();
	const createSpace = useMutation(
		(request: OrganisationSvcCreateSpaceParameters) =>
			ImmApi.organisations.createSpace(request),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	const chooseEnv = (env: ModelsEnvironment) => {
		setStep(1);
		setEnv(env);
	};

	const submit = (values: FormValues) => {
		if (env === null) {
			notifications.show({
				title: "Error",
				message: "An error occured",
				withCloseButton: true,
				autoClose: 5000,
			});

			return;
		}

		const data: OrganisationSvcCreateSpaceParameters = {
			name: values.name,
			type: values.type,
			privacy: values.privacy,
			environment_id: env.id,
		};

		createSpace.mutate(data, {
			onSuccess: (resp) => {
				navigate(`/spaces/${resp.data.id}`);
			},
		});
	};

	return (
		<>
			{step === 0 ? (
				<ChooseEnvironment onChosen={chooseEnv} />
			) : (
				env && (
					<CreateFairForm
						env={env}
						onBack={() => setStep(0)}
						onFinish={submit}
					/>
				)
			)}
		</>
	);
};

const privacyTypes = [
	{
		label: "Private",
		value: "private",
	},
	{
		label: "Restricted",
		value: "restricted",
	},
	{
		label: "Public",
		value: "public",
	},
];

const schema = z.object({
	name: z.string().nonempty(),
	type: z.enum(["fair", "stand"]),
	privacy: z.enum(["private", "restricted", "public"]),
});

interface CreateFairFormProps {
	isFirst?: boolean;
	env: ModelsEnvironment;
	onBack: () => void;
	onFinish: (values: FormValues, reqs: AssetToReq[]) => void;
}

type FormValues = {
	name: string;
	type: "stand" | "fair";
	privacy: "private" | "public" | "restricted";
};

type AssetToReq = {
	slot_id: number;
	asset_id: number;
	asset_desc: string;
};

const CreateFairForm = ({ onBack, onFinish }: CreateFairFormProps) => {
	const user = useQampusStore((state) => state.user);

	const form = useForm<FormValues>({
		initialValues: {
			name: `${user?.name}'ın Fuarı`,
			type: "fair",
			privacy: "public",
		},
		validate: zodResolver(schema),
	});

	const [values, handlers] = useListState<AssetToReq>([]);
	const [selectorOpen, selectorHandlers] = useDisclosure(false);
	const [selectingFor, setSelectingFor] = useState(0);

	const submit = () => {
		const { hasErrors } = form.validate();

		if (hasErrors) return;

		onFinish(form.values, values);
	};

	const assetSelected = (assetId: number, assetDesc: string) => {
		const idx = values.findIndex((item) => item.slot_id === selectingFor);

		if (idx > -1) {
			handlers.setItemProp(idx, "asset_id", assetId);
		} else {
			handlers.append({
				slot_id: selectingFor,
				asset_id: assetId,
				asset_desc: assetDesc,
			});
		}

		closeSelector();
	};

	const closeSelector = () => {
		setSelectingFor(0);
		selectorHandlers.close();
	};

	return (
		<Container size="sm" mt="lg">
			<Paper p={30} radius="lg" shadow="lg">
				<Title order={3} mb="lg">
					Bilgileri gir
				</Title>
				<Stack>
					<TextInput label="Name" {...form.getInputProps("name")} />
					{/* <Select
              label="Type"
              data={spaceTypes}
              {...form.getInputProps("type")}
            /> */}
					<Select
						label="Privacy"
						data={privacyTypes}
						{...form.getInputProps("privacy")}
					/>

					{/* {env.slots && env.slots.length > 0 && (
              <Box my="lg">
                <Title order={3} mb="lg">
                  Materyalleri Seç
                </Title>
                <Stack>
                  {env.slots.map((slot) => (
                    <Group grow>
                      <Text>{slot.description}</Text>
                      <Text>
                        {values.find((v) => v.slot_id === slot.id)
                          ? values.find((v) => v.slot_id === slot.id)?.asset_desc
                          : t("asset_not_selected")}
                      </Text>
                      <Group position="right">
                        <ActionIcon
                          color="blue"
                          onClick={() => openAssetSelector(slot.id)}
                        >
                          <IconHandClick size="1rem" />
                        </ActionIcon>
                        <ActionIcon color="green">
                          <IconUpload size="1rem" />
                        </ActionIcon>
                      </Group>
                    </Group>
                  ))}
                </Stack>
              </Box>
            )} */}
					<Group position="right">
						<Group sx={{ width: "50%" }} grow>
							<Button variant="white" onClick={onBack}>
								Geri
							</Button>
							<Button onClick={submit}>Oluştur</Button>
						</Group>
					</Group>
				</Stack>
			</Paper>

			<Modal opened={selectorOpen} onClose={closeSelector} title="Select Asset">
				<AssetSelector onSelected={assetSelected} />
			</Modal>
		</Container>
	);
};

interface ChooseEnvironmentProps {
	onChosen: (env: ModelsEnvironment) => void;
}

const ChooseEnvironment = ({ onChosen }: ChooseEnvironmentProps) => {
	const { data: environments, isFetching: fetchingEnvironments } = useQuery(
		["environments", "fairs"],
		() =>
			ImmApi.environments.listEnvironments({
				filters: `["type","fair"]`,
			}),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	if (fetchingEnvironments) {
		return (
			<Container size="xl" mt="lg">
				<Center>
					<Loader />
				</Center>
			</Container>
		);
	}

	return (
		<Container size="xl" mt="lg">
			<Paper p={30} radius="lg" shadow="lg">
				<Title order={3}>Şablon Seç</Title>
				<SimpleGrid
					cols={3}
					spacing="lg"
					mt="md"
					breakpoints={[
						{ maxWidth: "62rem", cols: 4, spacing: "md" },
						{ maxWidth: "48rem", cols: 2, spacing: "sm" },
						{ maxWidth: "36rem", cols: 1, spacing: "sm" },
					]}
				>
					{environments?.data.items.map((env: ModelsEnvironment) => (
						<Card
							key={env.id}
							shadow="sm"
							padding={0}
							radius="md"
							withBorder
							sx={{
								backgroundSize: "cover",
								backgroundImage: env?.image
									? `url("${env.image?.url}")`
									: `url("${NoImage}")`,
								transition: "all .1s ease-in-out",
								"&:hover": {
									cursor: "pointer",
									transform: "scale(1.02)",
								},
							}}
							onClick={() => onChosen(env)}
						>
							<Card.Section sx={{ minHeight: 200 }} />

							<Group
								position="apart"
								p="lg"
								sx={{
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									color: "white",
									minHeight: 50,
								}}
							>
								<Text weight={500}>{env.name}</Text>
							</Group>
						</Card>
					))}
				</SimpleGrid>
			</Paper>
		</Container>
	);
};
