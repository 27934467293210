import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Paper, Stack, Title, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ImmApi, HttpErrHTTPError } from "@api";
import axios from "axios";

const VerifyEmail = () => {
	const { code } = useParams();

	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const verifyEmail = useQuery(
		["codes", code as string],
		() => ImmApi.auth.verifyEmail(code as string),
		{
			retry: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			enabled: code !== undefined,
			onError: (error) => {
				if (axios.isAxiosError<HttpErrHTTPError>(error)) {
					setError(
						error.response ? error.response.data.message : t("unknown_error"),
					);
				}
			},
			onSettled: () =>
				setTimeout(() => {
					navigate("/sign-in");
				}, 5000),
		},
	);

	if (verifyEmail.isFetching) {
		return (
			<Container>
				<Paper p={30} radius="lg" shadow="lg">
					<Title order={2} mb="lg">
						{t("verifying_email")}
					</Title>
					<Text>{error ? error : t("verifying_email_2")}</Text>
				</Paper>
			</Container>
		);
	}

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<Title order={2} mb="lg">
					{error ? t("verification_failed") : t("verification_success")}
				</Title>
				<Stack>
					<Text color={error ? "red" : "black"}>
						{error ? t("error") + error : t("verification_success_2")}
					</Text>
					<Text color="dimmed">{t("redirect_msg")}</Text>
				</Stack>
			</Paper>
		</Container>
	);
};

export default VerifyEmail;
