import {
	Container,
	Paper,
	Divider,
	Stack,
	TextInput,
	Button,
	Group,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ImmApi, UsersvcUpdateUserParameters } from "@api";

type FormParams = {
	name: string;
	surname: string;
	department: string;
	job_title: string;
};

export const EditUserProfile = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const form = useForm<FormParams>({
		initialValues: {
			name: "",
			surname: "",
			department: "",
			job_title: "",
		},

		validate: {
			name: (value) =>
				value.length >= 2 ? null : t("register_name_validation"),
			surname: (value) =>
				value.length >= 2 ? null : t("register_surname_validation"),
		},
	});

	useQuery(["user", "me"], () => ImmApi.users.getOwnDetails(), {
		onSuccess: (response) => {
			form.setValues({
				name: response.data.name,
				surname: response.data.surname,
				department: response.data.department,
				job_title: response.data.job_title,
			});
		},
	});

	const updateUserProfile = useMutation(
		(request: UsersvcUpdateUserParameters) =>
			ImmApi.users.updateOwnData(request),
		{
			onSuccess: () => navigate("/profile"),
		},
	);

	const handleSave = (values: FormParams) => {
		const data: UsersvcUpdateUserParameters = {
			name: values.name,
			surname: values.surname,
			department: values.department,
			job_title: values.job_title,
		};
		updateUserProfile.mutate(data);
	};

	return (
		<Container>
			<Paper p={30} shadow="lg" radius="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Stack>
						<TextInput label={t("name")} {...form.getInputProps("name")} />
						<TextInput
							label={t("surname")}
							{...form.getInputProps("surname")}
						/>
						<TextInput
							label={t("department")}
							{...form.getInputProps("department")}
						/>
						<TextInput
							label={t("job_title")}
							{...form.getInputProps("job_title")}
						/>
						<Divider my="sm" />
						<Group>
							<Button color="blue" type="submit">
								{t("update")}
							</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								{t("cancel")}
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};
