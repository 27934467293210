export type Requirement = {
	re: RegExp;
	label: string;
};

export function getPasswordStrength(
	password: string,
	requirements: Requirement[],
) {
	let multiplier = password.length > 5 ? 0 : 1;

	for (const req of requirements) {
		if (!req.re.test(password)) {
			multiplier += 1;
		}
	}

	return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}
