import {
	Container,
	Paper,
	Stack,
	Button,
	Title,
	TextInput,
	Space,
	Image,
	Group,
	FileInput,
} from "@mantine/core";
import { z } from "zod";
import { useForm, zodResolver } from "@mantine/form";
import NoImage from "../../assets/no_image.png";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {
	ImmApi,
	OrganisationSvcCreateOrganisationInput,
	HttpErrHTTPError,
} from "@api";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

const MAX_FILE_SIZE = 500000;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

const schema = z.object({
	name: z.string().nonempty(),
	description: z.string().nonempty(),
	file: z
		.any()
		.refine(
			(file: File) => file?.size <= MAX_FILE_SIZE,
			"Max dosya boyutu 5MB.",
		)
		.refine(
			(file: File) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
			"Sadece .jpg, .jpeg, ve .png dosyaları desteklenmektedir.",
		)
		.refine((file: File | null) => file !== null, "Boş bırakılamaz"),
});

type FormValues = {
	name: string;
	description: string;
	file: File | null;
};

export const CreateOrganisation = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const form = useForm<FormValues>({
		initialValues: {
			name: "",
			description: "",
			file: null,
		},
		validate: zodResolver(schema),
	});

	const createOrganisation = useMutation(
		(request: OrganisationSvcCreateOrganisationInput) =>
			ImmApi.organisations.createOrganisation(request),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	const uploadimage = useMutation(
		(data: { file: File }) =>
			ImmApi.organisations.uploadOrganisationImage(data),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	const submit = () => {
		const { hasErrors, errors } = form.validate();

		console.log(errors);
		console.log(form.values);
		if (hasErrors) return;

		const data: OrganisationSvcCreateOrganisationInput = {
			name: form.values.name,
			description: form.values.description,
		};

		createOrganisation.mutate(data, {
			onSuccess: () => {
				if (form.values.file == null) return;

				const data = {
					file: form.values.file,
				};

				uploadimage.mutate(data, {
					onSuccess: () => {
						queryClient.invalidateQueries(["user", "me"]);
						queryClient.invalidateQueries(["permissions", "me"]);
						navigate("/organisation");
					},
				});
			},
		});
	};

	return (
		<Container mt="lg">
			<Paper p={30} shadow="lg" radius="lg">
				<Title order={3}>{t("create_organisation")}</Title>
				<Space h="lg" />
				<Stack>
					<TextInput
						label={t("organisation_name")}
						{...form.getInputProps("name")}
						withAsterisk
					/>
					<TextInput
						label={t("organisation_description")}
						{...form.getInputProps("description")}
						withAsterisk
					/>
					<Image
						src={
							form.values.file === null
								? NoImage
								: URL.createObjectURL(form.values.file)
						}
						width={200}
					/>
					<FileInput
						label={t("organisation_logo")}
						{...form.getInputProps("file")}
						withAsterisk
					/>
					<Group>
						<Button onClick={() => navigate(-1)} variant="outline">
							{t("cancel")}
						</Button>
						<Button onClick={submit}>{t("create")}</Button>
					</Group>
				</Stack>
			</Paper>
		</Container>
	);
};
