import {
	Paper,
	Box,
	Group,
	Stack,
	Text,
	Center,
	Loader,
	Pagination,
	Flex,
	Avatar,
	Select,
	Table,
	ActionIcon,
	TextInput,
	Menu,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { IconDots, IconTrash } from "@tabler/icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
	ImmApi,
	ModelsRole,
	ModelsUser,
	OrganisationSvcUpdateMemberRoleParams,
	OrganisationSvcUpdateMemberStatusParams,
	HttpErrHTTPError,
} from "@api";
import { notifications } from "@mantine/notifications";
import useQampusStore from "../../hooks/useQampusStore";

const MemberRow = ({
	member,
	roles,
	onDelete,
	onChangeRole,
}: {
	roles: ModelsRole[];
	member: ModelsUser;
	onDelete: (id: number) => void;
	onChangeRole: (id: number, role_id: number) => void;
}) => {
	const roleData = roles.map((r) => ({
		value: r.id.toString(),
		label: r.name,
		...r,
	}));

	const changeRole = (role_id: string | null) => {
		if (!role_id) return;
		let rid: number;

		try {
			rid = parseInt(role_id);
			onChangeRole(member.id, rid);
		} catch (_e) {
			return;
		}
	};

	return (
		<tr key={member.id}>
			<td>
				<Flex gap="xs">
					<Avatar radius="xl">{member.name[0]}</Avatar>
					<Flex direction="column">
						<Text>{`${member.name} ${member.surname}`}</Text>
						<Text color="dimmed">{member.email}</Text>
					</Flex>
				</Flex>
			</td>
			<td>
				{member.role?.protected ? (
					<Box>
						<Text>{member.role?.name}</Text>
					</Box>
				) : (
					<Select
						data={roleData}
						value={member.role?.id.toString()}
						onChange={changeRole}
						sx={{
							width: "50%",
							border: "none",
						}}
						styles={(theme) => ({
							input: {
								border: "none",
								fontSize: theme.fontSizes.xs,
								"&:hover": {
									backgroundColor: theme.colors.gray[2],
								},
							},
						})}
					/>
				)}
			</td>
			<td>
				<Flex>
					<Menu>
						<Menu.Target>
							<ActionIcon>
								<IconDots />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								color="red"
								icon={<IconTrash size={14} />}
								onClick={() => onDelete(member.id)}
								disabled={member.role?.protected}
							>
								Remove Member
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			</td>
		</tr>
	);
};

const MemberList = ({
	members,
	roles,
	onDelete,
	onChangeRole,
}: {
	members: ModelsUser[];
	roles: ModelsRole[];
	onDelete: (id: number) => void;
	onChangeRole: (id: number, role_id: number) => void;
}) => {
	return (
		<Table fontSize="xs" sx={{ width: "100%", tableLayout: "fixed" }}>
			<thead>
				<tr>
					<th>User</th>
					<th>Role</th>
					<th>More</th>
				</tr>
			</thead>
			<tbody>
				{members.map((m) => (
					<MemberRow
						key={m.id}
						roles={roles}
						member={m}
						onDelete={onDelete}
						onChangeRole={onChangeRole}
					/>
				))}
			</tbody>
		</Table>
	);
};

export const OrganisationUsers = () => {
	const [keyword, setKeyword] = useState("");
	const [activePage, setPage] = useState(1);
	const { t } = useTranslation();
	const user = useQampusStore((state) => state.user);

	const queryClient = useQueryClient();

	const [debouncedKeyword] = useDebouncedValue(keyword, 300);

	const openDeleteModal = (id: number) =>
		openConfirmModal({
			title: <Text weight="bold">{t("remove_user")}</Text>,
			centered: true,
			children: <Text size="sm">{t("remove_user_text")}</Text>,
			labels: { confirm: t("delete"), cancel: t("cancel") },
			confirmProps: { color: "red" },
			onCancel: () => console.log("Cancel"),
			onConfirm: () =>
				updateUserStatus.mutate(
					{ action: "remove", users: [id] },
					{
						onSuccess: () =>
							queryClient.invalidateQueries([
								"organisation",
								user?.organisation_id,
								"users",
							]),
					},
				),
		});

	const changeRole = (id: number, rid: number) => {
		updateUserRole.mutate(
			{
				roleID: rid,
				request: { users: [id] },
			},
			{
				onSuccess: () =>
					queryClient.invalidateQueries([
						"organisation",
						user?.organisation_id,
						"users",
					]),
			},
		);
	};

	const fetchUsers = useQuery(
		[
			"organisation",
			user?.organisation_id,
			"users",
			debouncedKeyword,
			activePage - 1,
			10,
		],
		() =>
			ImmApi.organisations.listOrganisationMembers({
				filters: `["email", "like","${debouncedKeyword}"],["OR"],["name","like","${debouncedKeyword}"]]`,
			}),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	const fetchRoles = useQuery(
		["roles"],
		() => ImmApi.organisations.listOrganisationRoles(),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	const updateUserStatus = useMutation(
		(request: OrganisationSvcUpdateMemberStatusParams) =>
			ImmApi.organisations.updateMemberStatus(request),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);
	const updateUserRole = useMutation(
		({
			roleID,
			request,
		}: {
			roleID: number;
			request: OrganisationSvcUpdateMemberRoleParams;
		}) => ImmApi.organisations.updateMemberRole(roleID, request),
		{
			onError: (e) => {
				if (axios.isAxiosError<HttpErrHTTPError>(e)) {
					notifications.show({
						title: "Error",
						message: e.response?.data.message,
						color: "red",
						withCloseButton: true,
						autoClose: 5000,
					});
				}
			},
		},
	);

	if (fetchUsers.isError) {
		return <Text>Error...</Text>;
	}

	return (
		<Paper p={30} shadow="lg" radius="lg">
			<Group position="apart" mb="lg">
				<TextInput
					placeholder={t("search_members")}
					value={keyword}
					onChange={(e) => setKeyword(e.currentTarget.value)}
				/>
			</Group>
			{fetchUsers.isLoading ? (
				<Center>
					<Loader />
				</Center>
			) : (
				<Stack align="center">
					<MemberList
						roles={
							fetchRoles.data?.data.items ? fetchRoles.data.data.items : []
						}
						members={fetchUsers.data.data.items || []}
						onDelete={openDeleteModal}
						onChangeRole={changeRole}
					/>
					<Pagination
						value={activePage}
						onChange={setPage}
						total={fetchUsers.data.data.total_pages ?? 0}
					/>
				</Stack>
			)}
		</Paper>
	);
};
