import {
	Container,
	Paper,
	TextInput,
	Group,
	Text,
	Stack,
	Button,
	Title,
	Box,
	Alert,
	Select,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { useState, useRef, useMemo } from "react";
import { useForm, zodResolver } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import {
	ImmApi,
	HttpErrHTTPError,
	AuthServicePreRegisterParameters,
	ModelsPreUser,
} from "@api";
import { z } from "zod";

const schema = z.object({
	email: z.string().email(),
	company: z.string().min(2),
	size: z.enum(["micro", "small", "medium", "large"]),
	industry: z.enum([
		"agriculture",
		"automotive",
		"banking_finance",
		"construction",
		"consulting",
		"education",
		"energy",
		"entertainment",
		"food_beverage",
		"healthcare",
		"hospitality",
		"information_technology",
		"manufacturing",
		"marketing_advertising",
		"pharmaceuticals",
		"real_estate",
		"retail",
		"telecommunications",
		"transportation_logistics",
		"other",
	]),
});

type FormValues = z.infer<typeof schema>;
export const PreRegister = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [error, setError] = useState<string | null>(null);
	const [captcha, setCaptcha] = useState<string | null>(null);
	const [user, setUser] = useState<ModelsPreUser | null>(null);

	const captchaRef = useRef<ReCAPTCHA | null>(null);
	const register = useMutation((request: AuthServicePreRegisterParameters) =>
		ImmApi.auth.preRegister(request),
	);

	const form = useForm<FormValues>({
		initialValues: {
			email: "",
			company: "",
			size: "micro",
			industry: "agriculture",
		},
		validate: zodResolver(schema),
	});

	const sizeData = useMemo(
		() =>
			schema.shape.size.options.map((o) => {
				return { value: o, label: t(o) };
			}),
		[t],
	);
	const indData = useMemo(
		() =>
			schema.shape.industry.options.map((o) => {
				return { value: o, label: t(o) };
			}),
		[t],
	);

	const handleSave = (values: FormValues) => {
		if (captcha === null) {
			setError(t("captcha_empty_error"));
			return;
		}

		const data: AuthServicePreRegisterParameters = {
			email: values.email,
			company: values.company,
			company_size: values.size,
			industry: values.industry,
			captcha: captcha,
		};

		register.mutate(data, {
			onSuccess: (response) => {
				setUser(response.data);
				setTimeout(() => {
					navigate("/sign-in");
				}, 5000);
			},
			onError: (error) => {
				captchaRef.current?.reset();
				if (axios.isAxiosError<HttpErrHTTPError>(error)) {
					setError(
						error.response ? error.response.data.message : t("unknown_error"),
					);
				}
			},
		});
	};

	if (user) {
		return (
			<Container
				size="sm"
				sx={{
					height: "100vh",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<Paper p={30} radius="lg" shadow="lg">
					<Title order={2} mb="lg">
						{t("thank_you")}
					</Title>
					<Stack>
						<Text>{t("pre_registration_complete")}</Text>
						<Text color="dimmed">{t("redirect_msg")}</Text>
					</Stack>
				</Paper>
			</Container>
		);
	}

	return (
		<Container
			size="sm"
			sx={{
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Paper p={30} radius="lg" shadow="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Title order={2} mb="lg">
						{t("preregister")}
					</Title>
					<Stack>
						<TextInput
							label={t("company")}
							withAsterisk
							{...form.getInputProps("company")}
						/>
						<TextInput
							label={t("email")}
							withAsterisk
							{...form.getInputProps("email")}
						/>
						<Select
							label={t("company_size")}
							withAsterisk
							data={sizeData}
							{...form.getInputProps("size")}
						/>
						<Select
							label={t("industry")}
							withAsterisk
							data={indData}
							{...form.getInputProps("industry")}
						/>
						<Box>
							<ReCAPTCHA
								ref={captchaRef}
								sitekey="6LdNK58mAAAAAEomnC93pIGXXvJ0q2EYpKj9D9rX"
								onChange={setCaptcha}
								size="normal"
							/>
						</Box>

						{error && (
							<Alert
								icon={<IconAlertCircle size="1rem" />}
								title={t("error")}
								color="red"
							>
								{error}
							</Alert>
						)}
						<Group>
							<Button color="blue" type="submit">
								{t("save")}
							</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								{t("cancel")}
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};

export default PreRegister;
