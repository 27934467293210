import { AppShell } from "@mantine/core";
import { HeaderSearch } from "../header/header";
import { NavbarSegmented } from "../navbar/navbar";
import { useQampusStore } from "../../hooks/useQampusStore";

type AppContainerProps = {
	children: React.ReactNode;
	navDisabled?: boolean;
	headerDisabled?: boolean;
	containerDisabled?: boolean;
};

// const testLinks = [
//   { link: "/features", label: "Features" },
//   { link: "#", label: "Pricing" },
//   { link: "#", label: "Learn" },
//   { link: "#", label: "Community" },
//   { link: "/class", label: "Account" },
// ];

export const AppContainer = ({
	children,
	navDisabled = false,
	headerDisabled = false,
	containerDisabled = false,
}: AppContainerProps) => {
	const navbar = useQampusStore((state) => state.navbar);

	if (containerDisabled) {
		return <>{children}</>;
	}

	return (
		<AppShell
			navbar={navbar && !navDisabled ? <NavbarSegmented /> : undefined}
			header={!headerDisabled ? <HeaderSearch /> : undefined}
			layout="alt"
			padding={0}
			styles={(theme) => ({
				main: {
					backgroundColor:
						theme.colorScheme === "dark"
							? theme.colors.dark[8]
							: theme.colors.gray[0],
				},
			})}
		>
			{children}
		</AppShell>
	);
};

export default AppContainer;
