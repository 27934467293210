import { useQampusStore } from "../../hooks/useQampusStore";
import { CreateOrganisation } from "./CreateOrganisation";
import { Organisation } from "./Organisation";

export const OrganisationMain = () => {
	const user = useQampusStore((state) => state.user);
	console.log(user);
	if (user?.organisation_id !== null) {
		return <Organisation />;
	}
	return <CreateOrganisation />;
};
