import { Outlet } from "react-router-dom";
import { AdminAppContainer } from "../components/admin/adminshell";

const FullLayout = ({
	headerDisabled = false,
	navDisabled = false,
	containerDisabled = false,
}: {
	navDisabled?: boolean;
	headerDisabled?: boolean;
	containerDisabled?: boolean;
}) => {
	return (
		<>
			<AdminAppContainer
				headerDisabled={headerDisabled}
				navDisabled={navDisabled}
				containerDisabled={containerDisabled}
			>
				<Outlet />
			</AdminAppContainer>
		</>
	);
};

export default FullLayout;
