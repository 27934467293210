import { useMutation } from "react-query";
import { Group, Text, useMantineTheme } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { ModelsAsset, ModelsUser, ImmApi } from "@api";
import { AxiosProgressEvent } from "axios";

type DropzoneFullScreenProps = {
	roomId: string;
	user?: ModelsUser | null;
	active: boolean;
	callback: (asset: ModelsAsset) => void;
};

export function DropzoneFullScreen({
	active,
	callback,
}: DropzoneFullScreenProps) {
	const theme = useMantineTheme();

	const uploadAsset = useMutation(
		(data: {
			file: File;
			description: string;
			progressCallback: (e: AxiosProgressEvent) => void;
		}) =>
			ImmApi.users.uploadAsset(data, {
				onUploadProgress: data.progressCallback,
			}),
	);

	const handleProgress = (progress: AxiosProgressEvent) => {
		const value = progress.total
			? Math.round((progress.loaded * 100) / progress.total)
			: 0;
		console.log(value);
	};

	const handleUpload = (files: FileWithPath[]) => {
		//const ext = files[0].name.split(".").pop();
		uploadAsset.mutate(
			{
				file: files[0],
				description: "",
				progressCallback: (event) => handleProgress(event),
			},
			{
				onSuccess: (data) => {
					callback(data.data);
				},
			},
		);
	};

	return (
		<Dropzone.FullScreen
			multiple={false}
			active={active}
			accept={{
				"application/pdf": [".pdf"],
				"model/gltf-binary": [".glb"],
				"video/mp4": [".mp4"],
			}}
			onDrop={(files) => {
				handleUpload(files);
			}}
		>
			<Group
				position="center"
				spacing="xl"
				style={{ minHeight: 220, pointerEvents: "none" }}
			>
				<Dropzone.Accept>
					<IconUpload
						size={50}
						stroke={1.5}
						color={
							theme.colors[theme.primaryColor][
								theme.colorScheme === "dark" ? 4 : 6
							]
						}
					/>
				</Dropzone.Accept>
				<Dropzone.Reject>
					<IconX
						size={50}
						stroke={1.5}
						color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
					/>
				</Dropzone.Reject>
				<Dropzone.Idle>
					<IconPhoto size={50} stroke={1.5} />
				</Dropzone.Idle>

				<div>
					<Text size="xl" inline>
						Drag images here or click to select files
					</Text>
					<Text size="sm" color="dimmed" inline mt={7}>
						Attach as many files as you like, each file should not exceed 5mb
					</Text>
				</div>
			</Group>
		</Dropzone.FullScreen>
	);
}
