import {
	Container,
	Paper,
	Box,
	Group,
	Stack,
	Text,
	Title,
	Button,
	Center,
	Loader,
	Pagination,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import { SelectableTable } from "../../components/selectable_table/selectable_table";
import { AdminSvcUpdateUserStatusParams, ImmApi, ModelsUser } from "@api";

export const Users = () => {
	const [selected, setSelected] = useState<number[]>([]);
	const [activePage, setPage] = useState(1);

	const queryClient = useQueryClient();

	const { hasAny } = usePermissions();

	const navigate = useNavigate();

	const updateUserStatus = useMutation(
		(request: AdminSvcUpdateUserStatusParams) =>
			ImmApi.mng.updateUserStatus(request),
	);

	const openDeleteModal = () =>
		openConfirmModal({
			title: <Text weight="bold">Remove user</Text>,
			centered: true,
			children: (
				<Text size="sm">Are you sure you want to remove this user ?</Text>
			),
			labels: { confirm: "Remove", cancel: "Cancel" },
			confirmProps: { color: "red" },
			onCancel: () => console.log("Cancel"),
			onConfirm: () =>
				updateUserStatus.mutate(
					{ action: "remove", users: selected },
					{
						onSuccess: () => queryClient.invalidateQueries(["users"]),
					},
				),
		});

	const fetchUsers = useQuery(
		["users", activePage],
		() => ImmApi.mng.listAllRoles({ page: activePage }),
		{
			select: (res) => {
				return {
					current_page: res.data.page,
					num_pages: res.data.total_pages,
					data: res.data.items.map((r: ModelsUser) => ({
						id: r.id,
						name: `${r.name} ${r.surname}`,
						email: r.email,
						registerDate: new Date(r.created_at).toLocaleString(),
						role: r.role_id,
					})),
				};
			},
		},
	);

	//const updateUserStatus = useMutation(updateRegistrantStatusFn);

	if (fetchUsers.isLoading) {
		return (
			<Center>
				<Loader />
			</Center>
		);
	}

	if (fetchUsers.isError) {
		return <Text>Error...</Text>;
	}

	return (
		<Container>
			<Paper p={30} shadow="lg" radius="lg">
				<Group position="apart" mb="lg">
					<Title order={2}>Users</Title>
					<Box>
						{hasAny(["organisation:write"]) && (
							<Button onClick={() => navigate("/admin/users/add")}>
								Add User
							</Button>
						)}

						{hasAny(["admin:write"]) && (
							<Button
								color="red"
								disabled={selected.length === 0}
								onClick={openDeleteModal}
								ml={10}
							>
								Delete Users
							</Button>
						)}
					</Box>
				</Group>
				<Stack>
					{fetchUsers.data && (
						<SelectableTable
							headers={{
								email: "Email",
								name: "Name",
								role: "Role",
							}}
							data={fetchUsers.data.data}
							onChange={(slc) => setSelected(slc)}
						/>
					)}
					<Pagination
						value={activePage}
						total={fetchUsers.data.num_pages ? fetchUsers.data.num_pages : 0}
						onChange={setPage}
						position="center"
					/>
				</Stack>
			</Paper>
		</Container>
	);
};
