import {
	Container,
	Paper,
	Group,
	PasswordInput,
	Text,
	Stack,
	Button,
	Title,
	Box,
	Center,
	Progress,
	Transition,
	Alert,
} from "@mantine/core";
import { IconCheck, IconX, IconAlertCircle } from "@tabler/icons";
import { useState } from "react";
import { useForm } from "@mantine/form";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
	ImmApi,
	AuthServiceRedeemPasswordResetTokenParams,
	HttpErrHTTPError,
} from "@api";
import { getPasswordStrength } from "../../helpers/getPasswordStrength";

type PasswordRequirementsParams = {
	meets: boolean;
	label: string;
};

interface FormValues {
	password: string;
	confirmPassword: string;
}

export function PasswordRequirement({
	meets,
	label,
}: PasswordRequirementsParams) {
	return (
		<Text color={meets ? "teal" : "red"} mt={5} size="sm">
			<Center inline>
				{meets ? (
					<IconCheck size={14} stroke={1.5} />
				) : (
					<IconX size={14} stroke={1.5} />
				)}
				<Box ml={7}>{label}</Box>
			</Center>
		</Text>
	);
}

const requirements = [
	// { re: /[0-9]/, label: "Includes number" },
	// { re: /[a-z]/, label: "Includes lowercase letter" },
	// { re: /[A-Z]/, label: "Includes uppercase letter" },
	// { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
	{ re: /^.{6,}$/, label: "At least 6 characters" },
];

export const RedeemResetToken = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [passwordFocused, setPasswordFocused] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);

	const { token } = useParams();
	const { t } = useTranslation();

	const redeemPasswordResetToken = useMutation(
		(request: AuthServiceRedeemPasswordResetTokenParams) =>
			ImmApi.auth.redeemPasswordResetToken(request),
	);

	const form = useForm({
		initialValues: {
			password: "",
			confirmPassword: "",
		},
		validate: {
			password: () =>
				checks.some((req) => req.props.meets === false)
					? t("register_password_validation")
					: null,
			confirmPassword: (value, values) =>
				value !== values.password
					? t("register_confirm_password_validation")
					: null,
		},
	});
	const strength = getPasswordStrength(form.values.password, requirements);
	const checks = requirements.map((requirement, index) => (
		<PasswordRequirement
			// biome-ignore lint/suspicious/noArrayIndexKey: it is safe because array is static
			key={index}
			label={requirement.label}
			meets={requirement.re.test(form.values.password)}
		/>
	));

	const bars = Array(4)
		.fill(0)
		.map((_, index) => (
			<Progress
				styles={{ bar: { transitionDuration: "0ms" } }}
				value={
					form.values.password.length > 0 && index === 0
						? 100
						: strength >= ((index + 1) / 4) * 100
						  ? 100
						  : 0
				}
				color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
				// biome-ignore lint/suspicious/noArrayIndexKey: it is safe because array is static
				key={index}
				size={4}
			/>
		));

	const handleSave = (values: FormValues) => {
		if (token === undefined) return;

		const data: AuthServiceRedeemPasswordResetTokenParams = {
			password: values.password,
			token: token,
		};

		redeemPasswordResetToken.mutate(data, {
			onSuccess: () => {
				queryClient.invalidateQueries(["users"]);
				setSuccess(true);
				setTimeout(() => {
					navigate("/sign-in");
				}, 5000);
			},
			onError: (error) => {
				if (axios.isAxiosError<HttpErrHTTPError>(error)) {
					setError(
						error.response ? error.response.data.message : t("unknown_error"),
					);
				}
			},
		});
	};

	if (success) {
		return (
			<Container>
				<Paper p={30} radius="lg" shadow="lg">
					<Title order={2} mb="lg">
						{t("request_success")}
					</Title>
					<Stack>
						<Text color="dimmed">{t("redirect_msg")}</Text>
					</Stack>
				</Paper>
			</Container>
		);
	}

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Title order={2} mb="lg">
						{t("set_new_password")}
					</Title>
					<Stack>
						<PasswordInput
							label={t("password")}
							withAsterisk
							{...form.getInputProps("password")}
							onFocus={() => setPasswordFocused(true)}
							onBlur={() => setPasswordFocused(false)}
						/>
						<Transition
							mounted={passwordFocused}
							transition="scale-y"
							duration={400}
							timingFunction="ease"
						>
							{(styles) => (
								<div style={{ ...styles }}>
									<Group spacing={5} grow mt="xs" mb="md">
										{bars}
									</Group>
									<Stack spacing="xs">{checks}</Stack>
								</div>
							)}
						</Transition>
						<PasswordInput
							label={t("confirm_password")}
							withAsterisk
							{...form.getInputProps("confirmPassword")}
						/>

						{error && (
							<Alert
								icon={<IconAlertCircle size="1rem" />}
								title={t("error")}
								color="red"
							>
								{error}
							</Alert>
						)}
						<Group>
							<Button color="blue" type="submit">
								{t("ok")}
							</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								{t("cancel")}
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};

export default RedeemResetToken;
