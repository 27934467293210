import { Container } from "@mantine/core";

import { AssetManager } from "./AssetManager";

export const Assets = () => {
	return (
		<Container mt="lg">
			<AssetManager />
		</Container>
	);
};
