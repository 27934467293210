import { useState } from "react";
import {
	Navbar,
	SegmentedControl,
	Button,
	createStyles,
	getStylesRef,
} from "@mantine/core";
import {
	IconLogout,
	IconUsers,
	IconFiles,
	IconAdjustments,
	IconBuildingSkyscraper,
	IconDeviceDesktopAnalytics,
} from "@tabler/icons";
import { useLocation, Link } from "react-router-dom";

const useStyles = createStyles((theme, _params) => {
	const icon = getStylesRef("icon");

	return {
		navbar: {
			backgroundColor:
				theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		},

		title: {
			textTransform: "uppercase",
			letterSpacing: -0.25,
		},

		link: {
			...theme.fn.focusStyles(),
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			fontSize: theme.fontSizes.sm,
			color:
				theme.colorScheme === "dark"
					? theme.colors.dark[1]
					: theme.colors.gray[7],
			padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
			borderRadius: theme.radius.sm,
			fontWeight: 500,

			"&:hover": {
				backgroundColor:
					theme.colorScheme === "dark"
						? theme.colors.dark[6]
						: theme.colors.gray[0],
				color: theme.colorScheme === "dark" ? theme.white : theme.black,

				[`& .${icon}`]: {
					color: theme.colorScheme === "dark" ? theme.white : theme.black,
				},
			},
		},

		linkIcon: {
			ref: icon,
			color:
				theme.colorScheme === "dark"
					? theme.colors.dark[2]
					: theme.colors.gray[6],
			marginRight: theme.spacing.sm,
		},

		linkActive: {
			"&, &:hover": {
				backgroundColor: theme.fn.variant({
					variant: "light",
					color: theme.primaryColor,
				}).background,
				color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
					.color,
				[`& .${icon}`]: {
					color: theme.fn.variant({
						variant: "light",
						color: theme.primaryColor,
					}).color,
				},
			},
		},

		footer: {
			borderTop: `1px solid ${
				theme.colorScheme === "dark"
					? theme.colors.dark[4]
					: theme.colors.gray[3]
			}`,
			paddingTop: theme.spacing.md,
		},
	};
});

const tabs = {
	admin: [
		{ link: "/admin/users", label: "Users", icon: IconUsers },
		{
			link: "/admin/build",
			label: "Build Files",
			icon: IconFiles,
		},
		{
			link: "/admin/settings",
			label: "Settings",
			icon: IconAdjustments,
		},
		{
			link: "/admin/environments",
			label: "Environments",
			icon: IconBuildingSkyscraper,
		},
		{
			link: "/admin/sessions",
			label: "Sessions",
			icon: IconDeviceDesktopAnalytics,
		},
	],
};

export function NavbarSegmented() {
	const location = useLocation();
	const { classes, cx } = useStyles();
	const [section, setSection] = useState<"admin">("admin");

	const links = tabs[section].map((item) => (
		<Button
			component={Link}
			variant="white"
			className={cx(classes.link, {
				[classes.linkActive]: location.pathname === item.link,
			})}
			to={item.link}
			key={item.label}
			leftIcon={<item.icon className={classes.linkIcon} stroke={1.5} />}
		>
			<span>{item.label}</span>
		</Button>
	));

	return (
		<Navbar height="auto" width={{ sm: 300 }} p="md" className={classes.navbar}>
			<Navbar.Section>
				<SegmentedControl
					value={section}
					onChange={(value: "admin") => setSection(value)}
					transitionTimingFunction="ease"
					fullWidth
					data={[{ label: "Admin", value: "admin" }]}
				/>
			</Navbar.Section>

			<Navbar.Section grow mt="xl">
				{links}
			</Navbar.Section>

			<Navbar.Section className={classes.footer}>
				<button
					type="button"
					className={classes.link}
					onClick={(event) => event.preventDefault()}
				>
					<IconLogout className={classes.linkIcon} stroke={1.5} />
					<span>Logout</span>
				</button>
			</Navbar.Section>
		</Navbar>
	);
}
