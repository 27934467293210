import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { useInterval } from "@mantine/hooks";
import {
	Paper,
	createStyles,
	TextInput,
	PasswordInput,
	Image,
	Flex,
	Button,
	Text,
	Anchor,
	Box,
	rem,
	Group,
	Divider,
	keyframes,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import useQampusStore from "../../hooks/useQampusStore";
import { getPathname } from "../../helpers/getPathname";
import { AxiosError, AxiosResponse } from "axios";
import storage from "../../helpers/storage";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { MetaMaskSDK } from "@metamask/sdk";
import { Buffer } from "buffer";
import { ImmApi } from "@api";
import TestBG from "../../assets/test.jpg";
import TestBG2 from "../../assets/test2.jpg";
// import CameraController from "./camera";
import immLogo from "../../assets/images/logo_dark.png";

import ttLogo from "../../assets/tt_logo.png";
import borusanLogo from "../../assets/borusancat.png";
import MetamaskLogo from "../../assets/MetaMask_Fox.svg";
import {
	AuthServiceGoogleAuthenticationParameters,
	AuthServiceLoginInput,
	AuthServiceLoginSuccess,
	AuthServiceMetamaskAuthenticationParameters,
} from "../../api/api";

let logo: string;
switch (process.env.REACT_APP_COMPANY) {
	case "borusan":
		logo = borusanLogo;
		break;
	case "turktelekom":
		logo = ttLogo;
		break;
	case "nara":
		logo = immLogo;
		break;
	default:
		logo = immLogo;
}

const changeBg = keyframes({
	"0%": { backgroundImage: `url(${TestBG})` },
	"50%": { backgroundImage: `url(${TestBG2})` },
	//  75% {background-image: url("https://i.stack.imgur.com/2wKWi.png");}
	//  50% {background-image: url("https://i.stack.imgur.com/HobHO.png");}
	//  75% {background-image: url("https://i.stack.imgur.com/3hiHO.png");}
});

const useStyles = createStyles((theme) => ({
	wrapper: {
		minHeight: rem(900),
		backgroundSize: "cover",
		backgroundImage: `url(${TestBG})`,
		animation: `${changeBg} 30s infinite`,
		animationTimingFunction: "cubic-bezier(0,1.8,1,1.5)",
	},

	form: {
		borderRight: `${rem(1)} solid ${
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
		}`,
		minHeight: rem(900),
		minWidth: "50%",
		paddingTop: rem(80),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",

		[theme.fn.smallerThan("sm")]: {
			maxWidth: "100%",
		},
	},

	title: {
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
	},
}));

const backgrounds = [TestBG, TestBG2];

export const SignIn = () => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const from = getPathname(location) || "/";
	const user = useQampusStore((state) => state.user);
	const setUser = useQampusStore((state) => state.setUser);
	const setApp = useQampusStore((state) => state.setApp);
	const setPermissions = useQampusStore((state) => state.setPermissions);
	const [bgIndex, setBgIndex] = useState(0);

	useInterval(
		() =>
			bgIndex < backgrounds.length ? setBgIndex((s) => s + 1) : setBgIndex(0),
		1000,
	);

	const loginQuery = useMutation(
		(request: AuthServiceLoginInput) => ImmApi.auth.login(request),
		{
			onSuccess: (response: AxiosResponse<AuthServiceLoginSuccess>) => {
				ImmApi.setSecurityData(response.data.token);
				setUser(response.data.user);
				setApp("fuar");
				storage.setItem<string>("token", response.data.token);
				storage.setItem<string>("refresh", response.data.refresh);
				storage.setItem<number>("expire", response.data.expire);
			},
			onError: (error) => {
				throw error;
			},
		},
	);

	const loginWithGoogle = useMutation(
		(request: AuthServiceGoogleAuthenticationParameters) =>
			ImmApi.auth.googleAuthenticate(request),
		{
			onSuccess: (response: AxiosResponse<AuthServiceLoginSuccess>) => {
				ImmApi.setSecurityData(response.data.token);
				setUser(response.data.user);
				setApp("fuar");
				storage.setItem<string>("token", response.data.token);
				storage.setItem<string>("refresh", response.data.refresh);
				storage.setItem<number>("expire", response.data.expire);
			},
			onError: (error) => {
				throw error;
			},
		},
	);

	const loginWithMetamask = useMutation(
		(request: AuthServiceMetamaskAuthenticationParameters) =>
			ImmApi.auth.metamaskAuthenticate(request),
		{
			onSuccess: (response: AxiosResponse<AuthServiceLoginSuccess>) => {
				ImmApi.setSecurityData(response.data.token);
				setUser(response.data.user);
				setApp("fuar");
				storage.setItem<string>("token", response.data.token);
				storage.setItem<string>("refresh", response.data.refresh);
				storage.setItem<number>("expire", response.data.expire);
			},
			onError: (error) => {
				throw error;
			},
		},
	);

	useQuery(["permissions"], () => ImmApi.users.getOwnPermissions(), {
		enabled: user !== null,
		onSuccess: (resp) => {
			setPermissions(resp.data.permissions);
			navigate(from, { replace: true });
		},
	});

	const form = useForm({
		initialValues: {
			email: "",
			password: "",
		},

		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : t("invalid_email")),
			password: (value) => (value.length >= 4 ? null : t("invalid_password")),
		},
	});

	const [error, setError] = useState("");

	//   // API Get Current Logged-in user
	//   const query = useQuery(["authUser"], getMeFn, {
	//     enabled: false,
	//     retry: 1,
	//     onSuccess: (data) => {
	//       stateContext.dispatch({ type: "SET_USER", payload: data });
	//     },
	//   });

	const doLogin = async (email: string, password: string) => {
		try {
			await loginQuery.mutateAsync({
				email: email,
				password: password,
			});
		} catch (error: unknown) {
			if (error instanceof AxiosError) {
				if (error.response && error.response.status === 401) {
					setError(t("wrong_email_or_password"));
				} else {
					setError(t("unknown_error"));
				}
			}
		}
	};

	const doGoogleLogin = async (response: CredentialResponse) => {
		if (response.credential === undefined) return;

		try {
			loginWithGoogle.mutateAsync({
				token: response.credential,
			});
		} catch (error: unknown) {
			if (error instanceof AxiosError) {
				if (error.response && error.response.status === 401) {
					setError(t("wrong_email_or_password"));
				} else {
					setError(t("unknown_error"));
				}
			}
		}
	};

	const doMetamaskLogin = async () => {
		const MMSDK = new MetaMaskSDK();
		const ethereum = MMSDK.getProvider();

		if (!ethereum) return;

		let from = "";

		const accounts = await ethereum.request({ method: "eth_requestAccounts" });

		if (accounts && Array.isArray(accounts)) {
			from = accounts[0];
		} else {
			console.log("error");
		}

		const exampleMessage = "Metaqampus login request";
		const msg = `0x${Buffer.from(exampleMessage, "utf8").toString("hex")}`;
		const sign = await ethereum.request({
			method: "personal_sign",
			params: [msg, from],
		});

		try {
			loginWithMetamask.mutateAsync({
				sign: sign as string,
				address: from,
			});
		} catch (error: unknown) {
			if (error instanceof AxiosError) {
				if (error.response && error.response.status === 401) {
					setError(t("wrong_email_or_password"));
				} else {
					setError(t("unknown_error"));
				}
			}
		}
	};
	return (
		<div className={classes.wrapper}>
			<Flex sx={{ width: "100%" }} p={0}>
				<Paper className={classes.form} radius={0} p={30}>
					<Box
						sx={(theme) => ({
							width: "55%",
							[theme.fn.smallerThan("sm")]: {
								width: "100%",
							},
						})}
					>
						<form
							onSubmit={form.onSubmit((values) =>
								doLogin(values.email, values.password),
							)}
						>
							<Flex justify="center" mb={40}>
								<Image src={logo} width={420} />
							</Flex>
							{/* <Title order={3} align="center" mt="md" mb={50}>
                {t("welcome")}
              </Title> */}
							{/* <Title
                  order={1}
                  className={classes.title}
                  align="center"
                  mb={50}
                >
                  Metaqampus
                </Title> */}
							<Group position="apart" sx={{ justifyContent: "center" }}>
								<Button
									leftIcon={<Image src={MetamaskLogo} width={18} height={18} />}
									sx={{ width: 203 }}
									onClick={doMetamaskLogin}
								>
									Metamask
								</Button>
								<GoogleLogin
									onSuccess={(credentialResponse) =>
										doGoogleLogin(credentialResponse)
									}
									onError={() => {
										console.log("Login Failed");
									}}
								/>
							</Group>
							{/* <Button
                onClick={() =>
                  setTargetIdx((state) =>
                    state < targets.length ? state + 1 : 0
                  )
                }
              >
                Test
              </Button> */}
							<Divider
								label={t("login_divider")}
								labelPosition="center"
								my="lg"
							/>
							<TextInput
								label={t("email_address")}
								placeholder={t("example_email")}
								size="md"
								mt={10}
								{...form.getInputProps("email")}
							/>
							<PasswordInput
								label={t("password")}
								placeholder={t("example_password")}
								mt="md"
								size="md"
								{...form.getInputProps("password")}
							/>
							{/* <Checkbox label="Keep me logged in" mt="xl" size="md" /> */}
							<Button type="submit" fullWidth mt="xl" size="md">
								{t("login")}
							</Button>
							<Text color="red" size="sm">
								{error}
							</Text>
							<Text align="center" mt="md">
								<Anchor<"a">
									href="#"
									weight={700}
									onClick={() => navigate("/reset-password")}
								>
									{t("forgot_password")}
								</Anchor>
							</Text>

							<Text align="center" mt="md">
								<Anchor<"a">
									href="#"
									weight={700}
									onClick={() => navigate("/pre")}
								>
									{t("pre_register_1")}
								</Anchor>{" "}
								{t("pre_register_2")}
							</Text>

							{/* <Text align="center" mt="md">
                {t("dont_have_acc") + " "}
                <Anchor<"a">
                  href="#"
                  weight={700}
                  onClick={() => navigate("/register")}
                >
                  {t("register")}
                </Anchor>
              </Text> */}
						</form>
					</Box>
				</Paper>
				{/* <Paper className={classes.form} p={0}>
          <Box sx={{ height: rem(900), width: "100%" }}>
            <Canvas
              camera={{ fov: 75, near: 0.1, far: 1000, position: [0, 2, 3] }}
            >
              <ambientLight />
              <Stats />
              <Marker target={targets[targetIdx]} />
            </Canvas>
          </Box>
        </Paper> */}
			</Flex>
		</div>
	);
};

export default SignIn;
