import React from "react";
import { DocRenderer } from "@cyntler/react-doc-viewer";
import "@google/model-viewer";
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer";

declare global {
	namespace JSX {
		interface IntrinsicElements {
			"model-viewer": Partial<ModelViewerElement>;
		}
	}
}

export const GLBRenderer: DocRenderer = ({
	mainState: { currentDocument },
}) => {
	if (!currentDocument) return null;

	return (
		<div id="glb-renderer" style={{ height: "50vh", width: "100%" }}>
			<model-viewer src={currentDocument.fileData as string} camera-controls />
		</div>
	);
};

GLBRenderer.fileTypes = ["glb", "model/gltf-binary"];
GLBRenderer.weight = 1;
