import {
	Container,
	Paper,
	Text,
	Stack,
	Button,
	Title,
	Box,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ImmApi } from "@api";

export const NotVerified = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [sent, setSent] = useState(false);

	useQuery(["user", "me"], () => ImmApi.users.getOwnDetails(), {
		refetchIntervalInBackground: true,
		refetchInterval: 10000,
		refetchOnMount: true,
		refetchOnWindowFocus: true,
		onSuccess: (response) => {
			if (response.data.status === "COMPLETED") {
				navigate("/");
			}
		},
	});
	const resendVerification = useMutation(() =>
		ImmApi.auth.resendActivationCode(),
	);

	const handleResend = () => {
		resendVerification.mutate(undefined, {
			onSuccess: () => setSent(true),
		});
	};

	if (sent) {
		return (
			<Container>
				<Paper p={30} radius="lg" shadow="lg">
					<Title order={2} mb="lg">
						Verification Email Sent
					</Title>
					<Stack>
						<Text>Please come back after verifying your email</Text>
					</Stack>
				</Paper>
			</Container>
		);
	}

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<Title order={2} mb="lg">
					{t("complete_register")}
				</Title>
				<Stack>
					<Text>Your account is not verified.</Text>
					<Box>
						<Button color="blue" onClick={() => handleResend()}>
							Resend Verification Email
						</Button>
					</Box>
				</Stack>
			</Paper>
		</Container>
	);
};

export default NotVerified;
