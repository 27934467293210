import { useQuery } from "react-query";
import { ImmApi } from "../../api/immApi";
import useQampusStore from "../../hooks/useQampusStore";
import {
	Box,
	Group,
	Title,
	Text,
	SimpleGrid,
	Card,
	Center,
	Image,
	TextInput,
} from "@mantine/core";
import {
	IconVideo,
	Icon3dCubeSphere,
	IconPresentationAnalytics,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { ModelsAsset } from "../../api/api";

type AssetSelectorProps = {
	filter?: "presentation" | "video" | "image" | "model" | "portal";
	onSelected: (id: number, desc: string) => void;
};

export const AssetSelector = ({ onSelected, filter }: AssetSelectorProps) => {
	const user = useQampusStore((state) => state.user);

	const { data } = useQuery(["user", user?.id, "assets"], () =>
		ImmApi.users.listUserAssets({
			filters: `["type","${filter}"]`,
		}),
	);

	const { t } = useTranslation();

	return (
		<Box>
			<Group position="apart">
				<Title order={3} mb="lg">
					{t("asset_selector")}
				</Title>
				<Group>
					<TextInput />
				</Group>
			</Group>
			{data && data.data.items.length > 0 && (
				<SimpleGrid
					cols={4}
					spacing="lg"
					mt="md"
					breakpoints={[
						{ maxWidth: "62rem", cols: 4, spacing: "md" },
						{ maxWidth: "48rem", cols: 2, spacing: "sm" },
						{ maxWidth: "36rem", cols: 1, spacing: "sm" },
					]}
				>
					{data.data.items.map((asset: ModelsAsset) => (
						<div
							onClick={() => onSelected(asset.id, asset.description)}
							onKeyDown={(e) =>
								e.code === "Enter" && onSelected(asset.id, asset.description)
							}
						>
							<Card
								shadow="sm"
								padding="lg"
								radius="md"
								withBorder
								sx={{
									transition: "all .1s ease-in-out",
									"&:hover": {
										cursor: "pointer",
										transform: "scale(1.02)",
									},
								}}
							>
								<Card.Section p="sm">
									<Center mt="md">
										{asset.type === "model" && (
											<Icon3dCubeSphere size="40%" strokeWidth={1} />
										)}
										{asset.type === "presentation" && (
											<IconPresentationAnalytics size="40%" strokeWidth={1} />
										)}
										{asset.type === "video" && (
											<IconVideo size="40%" strokeWidth={1} />
										)}
										{asset.type === "image" && <Image src={asset.url} />}
									</Center>
									<Text>{asset.description}</Text>
								</Card.Section>
							</Card>
						</div>
					))}
				</SimpleGrid>
			)}
		</Box>
	);
};
