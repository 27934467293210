import DocViewer, {
	DocViewerRenderers,
	IDocument,
} from "@cyntler/react-doc-viewer";
import { GLBRenderer } from "./glb-renderer";

type DocumentViewerProps = {
	docs: IDocument[];
	activeDocument: IDocument;
};
export const DocumentViewer = ({
	docs,
	activeDocument,
}: DocumentViewerProps) => {
	return (
		<DocViewer
			documents={docs}
			activeDocument={activeDocument}
			pluginRenderers={[...DocViewerRenderers, GLBRenderer]}
		/>
	);
};
