import {
	Container,
	Paper,
	Group,
	Stack,
	Text,
	Title,
	Button,
	Center,
	Loader,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";

import { SelectableTable } from "../../components/selectable_table/selectable_table";
import {
	AdminSvcUpdateEnvironmentStatusParams,
	ImmApi,
	ModelsEnvironment,
	PaginatePage,
} from "@api";
import { AxiosResponse } from "axios";

export const Environments = () => {
	const [selected, setSelected] = useState<number[]>([]);
	const queryClient = useQueryClient();

	const { hasAny } = usePermissions();

	const navigate = useNavigate();

	const updateUserStatus = useMutation(
		(request: AdminSvcUpdateEnvironmentStatusParams) =>
			ImmApi.mng.updateEnvironmentStatus(request),
	);

	const openDeleteModal = () =>
		openConfirmModal({
			title: <Text weight="bold">Remove environment</Text>,
			centered: true,
			children: (
				<Text size="sm">
					Are you sure you want to remove this environment ?
				</Text>
			),
			labels: { confirm: "Remove", cancel: "Cancel" },
			confirmProps: { color: "red" },
			onCancel: () => console.log("Cancel"),
			onConfirm: () =>
				updateUserStatus.mutate(
					{ action: "remove", environments: selected },
					{
						onSuccess: () => queryClient.invalidateQueries(["environments"]),
					},
				),
		});

	const fetchUsers = useQuery(
		["environments", "all"],
		() => ImmApi.environments.listEnvironments(),
		{
			select: useCallback(
				(data: AxiosResponse<PaginatePage & { items?: ModelsEnvironment[] }>) =>
					data.data.items.map((env: ModelsEnvironment) => ({
						id: env.id,
						name: (
							<Text
								component={Link}
								to={`/admin/environments/${env.uuid}`}
								variant="link"
							>
								{env.name}
							</Text>
						),
						uuid: env.uuid,
						type: env.type,
						wvers: env.wvers,
						avers: env.avers,
					})),
				[],
			),
		},
	);

	//const updateUserStatus = useMutation(updateRegistrantStatusFn);

	if (fetchUsers.isLoading) {
		return (
			<Center>
				<Loader />
			</Center>
		);
	}

	if (fetchUsers.isError) {
		return <Text>Error...</Text>;
	}

	return (
		<Container size="xl">
			<Paper p={30} shadow="lg" radius="lg">
				<Group position="apart" mb="lg">
					<Title order={2}>Environments</Title>
					<Group>
						{hasAny(["organisation:write"]) && (
							<Button onClick={() => navigate("/admin/environments/add")}>
								Add Environment
							</Button>
						)}
						{hasAny(["admin:write"]) && (
							<Button
								color="red"
								disabled={selected.length === 0}
								onClick={openDeleteModal}
							>
								Delete Environments
							</Button>
						)}
					</Group>
				</Group>
				<Stack>
					{fetchUsers.data && (
						<SelectableTable
							headers={{
								id: "ID",
								name: "Name",
								uuid: "UUID",
								type: "Type",
								capacity: "Capacity",
								dses: "D. Sess.",
								ses: "Sess.",
								wvers: "Web Ver.",
								avers: "Android Ver.",
							}}
							data={fetchUsers.data}
							onChange={(slc) => setSelected(slc)}
						/>
					)}
				</Stack>
			</Paper>
		</Container>
	);
};
