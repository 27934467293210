import {
	Container,
	Paper,
	TextInput,
	Group,
	Stack,
	Button,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ImmApi, OrganisationSvcUpdateOrganisationParams } from "@api";
import useQampusStore from "../../hooks/useQampusStore";

type FormData = {
	description: string;
};

export const EditOrganisation = () => {
	const user = useQampusStore((state) => state.user);
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const form = useForm({
		initialValues: {
			description: "",
		},
	});

	useQuery(
		["organisation", user?.organisation_id],
		() => ImmApi.organisations.getOrganisation(),
		{
			onSuccess: (resp) =>
				form.setValues({
					description: resp.data.description,
				}),
		},
	);

	const updateOrganisation = useMutation(
		(request: OrganisationSvcUpdateOrganisationParams) =>
			ImmApi.organisations.updateOrganisation(request),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["organisation", user?.organisation_id]);
				navigate("/organisation");
			},
		},
	);

	const handleSave = (formData: FormData) => {
		const params: OrganisationSvcUpdateOrganisationParams = {
			description: formData.description,
		};

		updateOrganisation.mutate(params);
	};

	return (
		<Container>
			<Paper p={30} shadow="lg" radius="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Stack>
						<TextInput
							label="Description"
							placeholder="Organisation description"
							{...form.getInputProps("description")}
						/>
						<Group>
							<Button type="submit">Edit</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								Cancel
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};

export default EditOrganisation;
