import {
	Container,
	Paper,
	Box,
	Group,
	Stack,
	Text,
	Title,
	Button,
	Image,
} from "@mantine/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import GDriveLogo from "../../assets/gdrive.svg";
import SketchFabLogo from "../../assets/sketchfab.svg";
import { ImmApi, ModelsIntegration } from "@api";

export const Integrations = () => {
	const queryClient = useQueryClient();

	const { t } = useTranslation();

	const getIntegrations = useQuery(
		["integrations"],
		() => ImmApi.integrations.listIntegrations(),
		{
			refetchOnWindowFocus: false,
		},
	);

	const removeIntegration = useMutation(
		({ app }: { app: "sketchfab" | "gdrive" }) =>
			ImmApi.integrations.removeIntegration({ app }),
		{
			onSuccess: () => queryClient.invalidateQueries(["integrations"]),
		},
	);

	const getSketchAuthURL = useQuery(
		["user", "me", "apps", { app: "sketchfab" }],
		() => ImmApi.integrations.getAuthenticationUrl({ app: "sketchfab" }),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			onSuccess: (response) => {
				window.open(response.data.url, "_blank");
			},
		},
	);

	const getGdriveAuthURL = useQuery(
		["user", "me", "apps", { app: "gdrive" }],
		() => ImmApi.integrations.getAuthenticationUrl({ app: "gdrive" }),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			onSuccess: (response) => {
				window.open(response.data.url, "_blank");
			},
		},
	);

	const removeApp = (app: "sketchfab" | "gdrive") => {
		removeIntegration.mutate({ app: app });
	};

	const appStatus = (
		app: "sketchfab" | "gdrive",
	): ModelsIntegration | undefined => {
		if (getIntegrations.data === undefined) return;

		if (getIntegrations.data.data.items.length === 0) return;

		const appData = getIntegrations.data.data.items.find(
			(val: ModelsIntegration) => val.app === app,
		);

		return appData;
	};

	if (getIntegrations.isFetching) {
		return <h1>Please wait...</h1>;
	}

	if (getIntegrations.isError) {
		return <h1>An error occured!</h1>;
	}

	return (
		<Container mt="lg">
			<Paper p={30} shadow="lg" radius="lg">
				<Group position="apart" mb="lg">
					<Title order={2}>{t("integrations")}</Title>
				</Group>
				<Stack>
					<Group position="apart" mt="md">
						<Group>
							<Box>
								<Image src={SketchFabLogo} />
							</Box>
							<Stack spacing={1}>
								<Title order={2} weight={200}>
									Sketchfab
								</Title>
								<Text color="dimmed">{t("sketchfab_text")}</Text>
							</Stack>
						</Group>
						<Button
							onClick={
								appStatus("sketchfab")?.status === "CONNECTED"
									? () => removeApp("sketchfab")
									: () => getSketchAuthURL.refetch()
							}
						>
							{appStatus("sketchfab")?.status === "CONNECTED"
								? t("remove_integration")
								: t("connect_integration")}
						</Button>
					</Group>
					<Group position="apart">
						<Group>
							<Box>
								<Image src={GDriveLogo} />
							</Box>
							<Stack spacing={1}>
								<Title order={2} weight={200}>
									Google Drive
								</Title>
								<Text color="dimmed">{t("gdrive_text")}</Text>
							</Stack>
						</Group>

						<Button
							onClick={
								appStatus("gdrive")?.status === "CONNECTED"
									? () => removeApp("gdrive")
									: () => getGdriveAuthURL.refetch()
							}
						>
							{appStatus("gdrive")?.status === "CONNECTED"
								? t("remove_integration")
								: t("connect_integration")}
						</Button>
					</Group>
				</Stack>
			</Paper>
		</Container>
	);
};
