import {
	Container,
	Paper,
	SimpleGrid,
	Card,
	Group,
	Text,
	Flex,
	ActionIcon,
	useMantineTheme,
} from "@mantine/core";
import { IconEye } from "@tabler/icons";
import { useMutation, useQueryClient } from "react-query";
import { ImmApi, ModelsSpace } from "@api";
import NoImage from "../../assets/no_image.png";
import { useNavigate } from "react-router-dom";
import { IconTrash } from "@tabler/icons-react";
import Blueprint from "../../assets/images/blueprint.png";
import { useTranslation } from "react-i18next";

interface Props {
	spaces: ModelsSpace[];
}
export const Spaces = ({ spaces }: Props) => {
	const queryClient = useQueryClient();
	const deleteSpace = useMutation(
		(id: number) => ImmApi.spaces.deleteSpace(id),
		{
			onSuccess: () => queryClient.invalidateQueries(["spaces"]),
		},
	);
	const navigate = useNavigate();
	const theme = useMantineTheme();
	const { t } = useTranslation();

	return (
		<Container size="xl" mt="lg">
			<Paper p={30} radius="lg" shadow="lg">
				{/* <Group position="right">
          <Button component={Link} to="/spaces/create">
            Yeni Ortam
          </Button>
        </Group> */}
				<SimpleGrid
					cols={3}
					spacing="lg"
					mt="md"
					breakpoints={[
						{ maxWidth: "62rem", cols: 4, spacing: "md" },
						{ maxWidth: "48rem", cols: 2, spacing: "sm" },
						{ maxWidth: "36rem", cols: 1, spacing: "sm" },
					]}
				>
					<Card
						shadow="sm"
						padding={0}
						radius="md"
						sx={{
							border: `0.0625rem solid ${theme.colors.blue[5]}`,
							justifyContent: "flex-end",
							backgroundSize: "cover",
							backgroundImage: `url("${Blueprint}")`,
							transition: "all .1s ease-in-out",
							"&:hover": {
								cursor: "pointer",
								transform: "scale(1.02)",
							},
						}}
						onClick={() => navigate("/spaces/create")}
					>
						<Flex justify="flex-end" sx={{ height: "100%" }}>
							<Group
								position="apart"
								mt="auto"
								p="lg"
								sx={{
									flexGrow: 1,
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									color: "white",
									minHeight: 50,
								}}
							>
								<Text weight={500}>{t("create_space")}</Text>
							</Group>
						</Flex>
					</Card>
					{spaces.map((space: ModelsSpace) => (
						<Card
							shadow="sm"
							padding={0}
							radius="md"
							withBorder
							sx={{
								backgroundSize: "cover",
								backgroundImage: space.environment?.image
									? `url("${space.environment.image?.url}")`
									: `url("${NoImage}")`,
								transition: "all .1s ease-in-out",
								"&:hover": {
									cursor: "pointer",
									transform: "scale(1.02)",
								},
							}}
							onClick={() => navigate(`/spaces/${space.id}`)}
						>
							<Card.Section sx={{ minHeight: 200 }} />

							<Group
								position="apart"
								p="lg"
								sx={{
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									color: "white",
									minHeight: 50,
								}}
							>
								<Text weight={500}>{space.name}</Text>
								<Group spacing="lg" align="center">
									<Group spacing={5}>
										<IconEye color="white" strokeWidth={1} size={18} />
										<Text
											color="white"
											size="lg"
											p={0}
											m={0}
											display="inline-block"
										>
											{space.visits}
										</Text>
									</Group>
									<ActionIcon
										color="red"
										size="xs"
										onClick={() => deleteSpace.mutate(space.id)}
									>
										<IconTrash />
									</ActionIcon>
								</Group>
							</Group>

							{/* <Button>Visit</Button> */}
						</Card>
					))}
				</SimpleGrid>
			</Paper>
		</Container>
	);
};

export default Spaces;
