import {
	Tabs,
	Paper,
	Text,
	Group,
	Checkbox,
	Container,
	Stack,
	Box,
	Button,
	Center,
	Loader,
	Pagination,
	TextInput,
} from "@mantine/core";
import {
	IconCalendarTime,
	IconUsers,
	IconBuildingSkyscraper,
	IconDoor,
	IconArrowNarrowUp,
	IconArrowNarrowDown,
	IconLockAccess,
	IconDots,
	IconDeviceDesktopAnalytics,
} from "@tabler/icons";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useListState, useDisclosure, useDebouncedValue } from "@mantine/hooks";
import * as _ from "lodash";
import { openConfirmModal } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import { SelectableTable } from "../../components/selectable_table/selectable_table";
import {
	AdminSvcUpdatePermissionsParams,
	AdminSvcUpdateUserStatusParams,
	ImmApi,
	ModelsPermission,
	ModelsRole,
	ModelsUser,
} from "@api";

const getFormattedData = (roles: ModelsRole[]) => {
	let all_perms: ModelsPermission[] = [];

	for (const role of roles) {
		all_perms = _.unionBy(all_perms, role.permissions, "id");
	}

	return all_perms;
};

const PermissionsManager = () => {
	const [permState, permStateHandlers] = useListState<ModelsRole>();
	const [allPerms, allPermsHandlers] = useListState<ModelsPermission>();
	const [fetched, setFetched] = useState(false);

	const [userOpened, userHandlers] = useDisclosure(true);
	const [roomopened, roomHandlers] = useDisclosure(true);
	const [orgOpened, orgHandlers] = useDisclosure(true);
	const [envOpened, envHandlers] = useDisclosure(true);
	const [sesOpened, sesHandlers] = useDisclosure(true);
	const [adminOpened, adminHandlers] = useDisclosure(true);
	const [otherOpened, otherHandlers] = useDisclosure(true);

	useQuery(["roles"], () => ImmApi.mng.listAllRoles(), {
		enabled: !fetched,
		onSuccess: (data) => {
			permStateHandlers.setState(data.data.items);
			allPermsHandlers.setState(getFormattedData(data.data.items));
			setFetched(true);
		},
	});

	const updatePerms = useMutation((request: AdminSvcUpdatePermissionsParams) =>
		ImmApi.mng.updatePermissions(request),
	);

	const handleCheck = (
		role: ModelsRole,
		perm: ModelsPermission,
		status: boolean,
	) => {
		if (status) {
			permStateHandlers.applyWhere(
				(item) => item.name === role.name,
				(item) => ({
					id: item.id,
					description: item.description,
					name: item.name,
					permissions: [...item.permissions, perm],
					created_at: item.created_at,
					updated_at: item.updated_at,
					organisation_id: item.organisation_id,
					default: item.default,
					protected: item.protected,
				}),
			);
			updatePerms.mutate({
				role_id: role.id,
				permission_id: perm.id,
				action: "add",
			});
		} else {
			permStateHandlers.applyWhere(
				(item) => item.name === role.name,
				(item) => ({
					id: item.id,
					description: item.description,
					name: item.name,
					created_at: item.created_at,
					updated_at: item.updated_at,
					organisation_id: item.organisation_id,
					permissions: item.permissions.filter((it) => it.name !== perm.name),
					default: item.default,
					protected: item.protected,
				}),
			);
			updatePerms.mutate({
				role_id: role.id,
				permission_id: perm.id,
				action: "remove",
			});
		}
	};

	return (
		<Stack>
			<Group grow>
				<Box />
				<Group grow sx={{ alignItems: "center" }}>
					{permState.map((role) => (
						<Text key={role.id} fw={300} align="center">
							{role.name}
						</Text>
					))}
				</Group>
			</Group>
			<Group
				bg={"#b2d9ee4b"}
				p="xs"
				position="apart"
				onClick={userHandlers.toggle}
				sx={{ cursor: "pointer" }}
			>
				<Group>
					<IconUsers />
					<Text fw={500}>User management</Text>
				</Group>
				{userOpened ? <IconArrowNarrowUp /> : <IconArrowNarrowDown />}
			</Group>
			{userOpened &&
				permState.length > 0 &&
				allPerms
					.filter((p) => p.category === "Users")
					.map((perm) => (
						<Group grow key={perm.id}>
							<Text fw={300}>{perm.description}</Text>
							<Group grow>
								{permState.map((role) => (
									<Checkbox
										key={role.id}
										display="flex"
										sx={{ justifyContent: "center" }}
										checked={
											role.permissions.find(
												(item) => item.name === perm.name,
											) !== undefined
										}
										onChange={(event) =>
											handleCheck(role, perm, event.currentTarget.checked)
										}
									/>
								))}
							</Group>
						</Group>
					))}
			<Group
				bg={"#b2d9ee4b"}
				p="xs"
				position="apart"
				onClick={roomHandlers.toggle}
				sx={{ cursor: "pointer" }}
			>
				<Group>
					<IconCalendarTime />
					<Text fw={500}>Room Magement</Text>
				</Group>
				{roomopened ? <IconArrowNarrowUp /> : <IconArrowNarrowDown />}
			</Group>
			{roomopened &&
				permState.length > 0 &&
				allPerms
					.filter((p) => p.category === "Rooms")
					.map((perm) => (
						<Group grow key={perm.id}>
							<Text fw={300}>{perm.description}</Text>
							<Group grow>
								{permState.map((role) => (
									<Checkbox
										key={role.id}
										display="flex"
										sx={{ justifyContent: "center" }}
										checked={
											role.permissions.find(
												(item) => item.name === perm.name,
											) !== undefined
										}
										onChange={(event) =>
											handleCheck(role, perm, event.currentTarget.checked)
										}
									/>
								))}
							</Group>
						</Group>
					))}
			<Group
				bg={"#b2d9ee4b"}
				p="xs"
				position="apart"
				onClick={orgHandlers.toggle}
				sx={{ cursor: "pointer" }}
			>
				<Group>
					<IconBuildingSkyscraper />
					<Text fw={500}>Organisation Management</Text>
				</Group>
				{orgOpened ? <IconArrowNarrowUp /> : <IconArrowNarrowDown />}
			</Group>
			{orgOpened &&
				permState.length > 0 &&
				allPerms
					.filter((p) => p.category === "Organisation")
					.map((perm) => (
						<Group grow key={perm.id}>
							<Text fw={300}>{perm.description}</Text>
							<Group grow>
								{permState.map((role) => (
									<Checkbox
										key={role.id}
										display="flex"
										sx={{ justifyContent: "center" }}
										checked={
											role.permissions.find(
												(item) => item.name === perm.name,
											) !== undefined
										}
										onChange={(event) =>
											handleCheck(role, perm, event.currentTarget.checked)
										}
									/>
								))}
							</Group>
						</Group>
					))}
			<Group
				bg={"#b2d9ee4b"}
				p="xs"
				position="apart"
				onClick={envHandlers.toggle}
				sx={{ cursor: "pointer" }}
			>
				<Group>
					<IconDoor />
					<Text fw={500}>Environment Management</Text>
				</Group>
				{envOpened ? <IconArrowNarrowUp /> : <IconArrowNarrowDown />}
			</Group>
			{envOpened &&
				permState.length > 0 &&
				allPerms
					.filter((p) => p.category === "Environments")
					.map((perm) => (
						<Group grow key={perm.id}>
							<Text fw={300}>{perm.description}</Text>
							<Group grow>
								{permState.map((role) => (
									<Checkbox
										key={role.id}
										display="flex"
										sx={{ justifyContent: "center" }}
										checked={
											role.permissions.find(
												(item) => item.name === perm.name,
											) !== undefined
										}
										onChange={(event) =>
											handleCheck(role, perm, event.currentTarget.checked)
										}
									/>
								))}
							</Group>
						</Group>
					))}
			<Group
				bg={"#b2d9ee4b"}
				p="xs"
				position="apart"
				onClick={sesHandlers.toggle}
				sx={{ cursor: "pointer" }}
			>
				<Group>
					<IconDeviceDesktopAnalytics />
					<Text fw={500}>Sessions Management</Text>
				</Group>
				{sesOpened ? <IconArrowNarrowUp /> : <IconArrowNarrowDown />}
			</Group>
			{sesOpened &&
				permState.length > 0 &&
				allPerms
					.filter((p) => p.category === "Sessions")
					.map((perm) => (
						<Group grow key={perm.id}>
							<Text fw={300}>{perm.description}</Text>
							<Group grow>
								{permState.map((role) => (
									<Checkbox
										key={role.id}
										display="flex"
										sx={{ justifyContent: "center" }}
										checked={
											role.permissions.find(
												(item) => item.name === perm.name,
											) !== undefined
										}
										onChange={(event) =>
											handleCheck(role, perm, event.currentTarget.checked)
										}
									/>
								))}
							</Group>
						</Group>
					))}
			<Group
				bg={"#b2d9ee4b"}
				p="xs"
				position="apart"
				onClick={adminHandlers.toggle}
				sx={{ cursor: "pointer" }}
			>
				<Group>
					<IconLockAccess />
					<Text fw={500}>Administration</Text>
				</Group>
				{adminOpened ? <IconArrowNarrowUp /> : <IconArrowNarrowDown />}
			</Group>
			{adminOpened &&
				permState.length > 0 &&
				allPerms
					.filter((p) => p.category === "Administration")
					.map((perm) => (
						<Group grow key={perm.id}>
							<Text fw={300}>{perm.description}</Text>
							<Group grow>
								{permState.map((role) => (
									<Checkbox
										key={role.id}
										display="flex"
										sx={{ justifyContent: "center" }}
										checked={
											role.permissions.find(
												(item) => item.name === perm.name,
											) !== undefined
										}
										onChange={(event) =>
											handleCheck(role, perm, event.currentTarget.checked)
										}
									/>
								))}
							</Group>
						</Group>
					))}
			<Group
				bg={"#b2d9ee4b"}
				p="xs"
				position="apart"
				onClick={otherHandlers.toggle}
				sx={{ cursor: "pointer" }}
			>
				<Group>
					<IconDots />
					<Text fw={500}>Other</Text>
				</Group>
				{otherOpened ? <IconArrowNarrowUp /> : <IconArrowNarrowDown />}
			</Group>
			{otherOpened &&
				permState.length > 0 &&
				allPerms
					.filter((p) => p.category === "Other")
					.map((perm) => (
						<Group grow key={perm.id}>
							<Text fw={300}>{perm.description}</Text>
							<Group grow>
								{permState.map((role) => (
									<Checkbox
										key={role.id}
										display="flex"
										sx={{ justifyContent: "center" }}
										checked={
											role.permissions.find(
												(item) => item.name === perm.name,
											) !== undefined
										}
										onChange={(event) =>
											handleCheck(role, perm, event.currentTarget.checked)
										}
									/>
								))}
							</Group>
						</Group>
					))}
		</Stack>
	);
};

const UserManager = () => {
	const [selected, setSelected] = useState<number[]>([]);
	const [activePage, setPage] = useState(1);
	const [filter, setFilter] = useState("");
	const [debounced] = useDebouncedValue(filter, 500);

	const queryClient = useQueryClient();

	const { hasAny } = usePermissions();

	const navigate = useNavigate();

	const updateUserStatus = useMutation(
		(request: AdminSvcUpdateUserStatusParams) =>
			ImmApi.mng.updateUserStatus(request),
	);

	const openDeleteModal = () =>
		openConfirmModal({
			title: <Text weight="bold">Remove user</Text>,
			centered: true,
			children: (
				<Text size="sm">Are you sure you want to remove this user ?</Text>
			),
			labels: { confirm: "Remove", cancel: "Cancel" },
			confirmProps: { color: "red" },
			onCancel: () => console.log("Cancel"),
			onConfirm: () =>
				updateUserStatus.mutate(
					{ action: "remove", users: selected },
					{
						onSuccess: () => queryClient.invalidateQueries(["users"]),
					},
				),
		});

	const searchUsers = useQuery(
		["users", debounced],
		() => ImmApi.mng.listAllUsers(),
		{
			select: (res) => {
				const data = res.data.items.map((r: ModelsUser) => ({
					id: r.id,
					value: r.id.toString(),
					name: `${r.name} ${r.surname}`,
					label: `${r.name} ${r.surname}`,
					email: r.email,
					registerDate: new Date(r.created_at).toLocaleString(),
					role: r.role_id,
				}));
				return {
					// current_page: res.current_page,
					// num_pages: res.num_pages,
					data,
					num_pages: res.data.total_pages,
					current_page: res.data.page,
				};
			},
		},
	);

	// const fetchUsers = useQuery(["users", activePage], listUsersFn, {
	//   select: (res) => {
	//     return {
	//       current_page: res.current_page,
	//       num_pages: res.num_pages,
	//       data: res.data.map((r) => ({
	//         id: r.ID,
	//         name: `${r.name} ${r.surname}`,
	//         email: r.email,
	//         registerDate: new Date(r.CreatedAt).toLocaleString(),
	//         role: RoleEnum[r.role_id],
	//       })),
	//     };
	//   },
	// });

	// const updateUserStatus = useMutation(updateRegistrantStatusFn);

	// if (searchUsers.isLoading) {
	//   return (
	//     <Center>
	//       <Loader />
	//     </Center>
	//   );
	// }

	// if (searchUsers.isError) {
	//   return <Text>Error...</Text>;
	// }

	return (
		<>
			<Group position="apart" mb="lg">
				<TextInput
					label="Search"
					onChange={(event) => setFilter(event.currentTarget.value)}
				/>
				<Box>
					{hasAny(["organisation:write"]) && (
						<Button onClick={() => navigate("/admin/users/add")}>
							Add User
						</Button>
					)}

					{hasAny(["admin:write"]) && (
						<Button
							color="red"
							disabled={selected.length === 0}
							onClick={openDeleteModal}
							ml={10}
						>
							Delete Users
						</Button>
					)}
				</Box>
			</Group>
			<Stack>
				{searchUsers.isFetching && (
					<Center>
						<Loader />
					</Center>
				)}
				{searchUsers.data && (
					<SelectableTable
						headers={{
							email: "Email",
							name: "Name",
							role: "Role",
						}}
						data={searchUsers.data.data}
						onChange={(slc) => setSelected(slc)}
					/>
				)}
				<Pagination
					value={activePage}
					total={
						searchUsers.data?.num_pages !== undefined
							? searchUsers.data.num_pages
							: 0
					}
					onChange={setPage}
					position="center"
				/>
			</Stack>
		</>
	);
};

const RolePage = () => {
	return (
		<Container size="lg">
			<Paper p={30} shadow="lg" radius="lg">
				<Tabs defaultValue="first">
					<Tabs.List sx={{ justifyContent: "space-around" }} p={0} mb="lg">
						<Tabs.Tab value="first" fw={500} fz="xl" w="50%">
							Users
						</Tabs.Tab>
						<Tabs.Tab value="second" fw={500} fz="xl" w="50%">
							Permissions
						</Tabs.Tab>
					</Tabs.List>

					<Tabs.Panel value="first" pt="xs">
						<UserManager />
					</Tabs.Panel>
					<Tabs.Panel value="second" pt="xs">
						<PermissionsManager />
						{/* <Group bg={"#b2d9ee4b"} p="xs">
              <IconBriefcase />
              <Text fw={500}>Jobs management</Text>
            </Group>
            {data.jobs.map((item) => (
              <Paper
                py={10}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text fw={500} sx={{ width: "35%" }}>
                  {item.description}
                </Text>
                <Group>
                  <Checkbox mx={20} />
                  <Checkbox mx={20} />
                  <Checkbox mx={20} />
                </Group>
              </Paper>
            ))}
            <Group bg={"#b2d9ee4b"} p="xs">
              <IconUsers />
              <Text fw={500}>Candidate management</Text>
            </Group>
            {data.Candidate.map((item) => (
              <Paper
                py={10}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text fw={500} sx={{ width: "35%" }}>
                  {item.description}
                </Text>
                <Group>
                  <Checkbox mx={20} />
                  <Checkbox mx={20} />
                  <Checkbox mx={20} />
                </Group>
              </Paper>
            ))}
            <Group bg={"#b2d9ee4b"} p="xs">
              <IconAdjustmentsHorizontal />
              <Text fw={500}>User management</Text>
            </Group>
            {data.user.map((item) => (
              <Paper
                py={10}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text fw={500} sx={{ width: "35%" }}>
                  {item.description}
                </Text>
                <Group>
                  <Checkbox mx={20} />
                  <Checkbox mx={20} />
                  <Checkbox mx={20} />
                </Group>
              </Paper>
            ))} */}
					</Tabs.Panel>
				</Tabs>
			</Paper>
		</Container>
	);
};

export default RolePage;
