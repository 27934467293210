import {
	Container,
	Paper,
	Group,
	Text,
	Stack,
	Badge,
	Card,
	SimpleGrid,
	Title,
	ActionIcon,
	Avatar,
	ScrollArea,
} from "@mantine/core";
import { IconArrowBack, IconUserCircle } from "@tabler/icons";
import { useState } from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ImmApi, ModelsSession } from "@api";

export const SessionCard = ({ session }: { session: ModelsSession }) => {
	const { t } = useTranslation();

	const [showList, setShowList] = useState(false);

	return (
		<Card shadow="sm" radius="md" withBorder>
			<Group position="apart" mt="md" mb="xs">
				<Title order={3}>{session.session}</Title>
				{showList ? (
					<ActionIcon onClick={() => setShowList(false)}>
						<IconArrowBack />
					</ActionIcon>
				) : (
					<Badge color="green" variant="filled" />
				)}
			</Group>

			<ScrollArea sx={{ height: "150px" }}>
				{showList ? (
					<Stack>
						{session.users.map((user) => (
							<Group>
								<Avatar radius="xl" color="green" size="sm">
									<IconUserCircle />
								</Avatar>
								<Text>{`${user.name} ${user.surname}`}</Text>
							</Group>
						))}
					</Stack>
				) : (
					<Stack>
						<Group>
							<Text weight={500}>{`${t("scene")}:`}</Text>
							<Text>{session.scene}</Text>
						</Group>
						<Group>
							<Text weight={500}>{`${t("user_count")}:`}</Text>
							<Text
								onClick={() => setShowList(true)}
								variant="link"
								sx={{
									"&:hover": {
										cursor: "pointer",
									},
								}}
							>
								{session.numUsers}
							</Text>
						</Group>
						<Group>
							<Text weight={500}>{`${t("first_ping")}:`}</Text>
							<Text>
								{dayjs(session.created_at).format("DD-MM-YYYY HH:mm")}
							</Text>
						</Group>
						<Group>
							<Text weight={500}>{`${t("last_ping")}:`}</Text>
							<Text>
								{dayjs(session.updated_at).format("DD-MM-YYYY HH:mm")}
							</Text>
						</Group>
					</Stack>
				)}
			</ScrollArea>
		</Card>
	);
};

export const Sessions = () => {
	const { t } = useTranslation();
	const fetchSessions = useQuery(["sessions"], () =>
		ImmApi.sessions.listActiveSessions(),
	);

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<SimpleGrid
					cols={3}
					spacing="lg"
					breakpoints={[
						{ maxWidth: "lg", cols: 3, spacing: "md" },
						{ maxWidth: "md", cols: 2, spacing: "sm" },
						{ maxWidth: "sm", cols: 1, spacing: "sm" },
					]}
				>
					{(fetchSessions.data === null ||
						fetchSessions.data?.data.items.length === 0) && (
						<Text>{t("no_sessions")}</Text>
					)}
					{fetchSessions.data?.data.items.map((session: ModelsSession) => {
						return <SessionCard session={session} />;
					})}
				</SimpleGrid>
			</Paper>
		</Container>
	);
};

export default Sessions;
