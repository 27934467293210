import { ImmApi, UsersvcUpdateAvatarParams } from "@api";
import {
	Box,
	Button,
	Center,
	Group,
	Paper,
	Stack,
	Text,
	TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals, openModal } from "@mantine/modals";
import { AvatarExportedEvent } from "@readyplayerme/react-avatar-creator";
import { Avatar as RPMAvatar } from "@readyplayerme/visage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import PuffLoader from "react-spinners/PuffLoader";
import { Guest } from "../../CommonTypes";
import useAvatarMetadata from "../../hooks/useAvatarMetadata";
import useQampusStore from "../../hooks/useQampusStore";
import AvatarContainer from "../avatar/avatar";

interface GuestLoginParams {
	callback: (guest: Guest) => void;
}

export function GameLogin({ callback }: GuestLoginParams) {
	//const [render, isFetching] = useRender();

	const { user, setUser } = useQampusStore();
	const [isUploading, setIsUploading] = useState(false);
	const [getMetadata] = useAvatarMetadata();
	const updateAvatar = useMutation((request: UsersvcUpdateAvatarParams) =>
		ImmApi.users.updateOwnAvatar(request),
	);
	const { t } = useTranslation();

	const form = useForm({
		initialValues: {
			email: user !== null ? user.email : "",
			name: user !== null ? `${user.name} ${user.surname}` : "",
		},

		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
			name: (value) =>
				value.length > 6 ? null : "Name must be more than 6 characters",
		},
	});

	const setGuest = (name: string, email: string) => {
		const guest: Guest = { name: name, email: email };
		callback(guest);
	};

	const updateStoredUserData = ({
		avatar,
		type,
	}: {
		avatar: string;
		avatar_pic: string;
		type: string;
	}) => {
		if (user === null) return;

		if (type === "fullbody") {
			user.avatar = avatar;
		} else {
			user.avatarvr = avatar;
		}

		setUser(user);
	};

	const handleAvatarLoaded = async (e: AvatarExportedEvent) => {
		closeAllModals();
		setIsUploading(true);
		const url = e.data.url;
		const metadata = await getMetadata(url);
		const pic = url.replace("glb", "png");

		const data = { avatar: url, avatar_pic: pic, type: metadata.bodyType };

		updateAvatar.mutate(data, {
			onSuccess: () => {
				updateStoredUserData(data);
			},
			onSettled: () => setIsUploading(false),
		});
	};

	return (
		<>
			<Stack spacing="lg" sx={{ alignItems: "center", width: "600px" }}>
				<Paper
					radius="lg"
					shadow="lg"
					p={0}
					sx={{ overflow: "hidden", width: "100%" }}
					withBorder
				>
					<Group grow sx={{ alignItems: "stretch" }}>
						<form
							onSubmit={form.onSubmit((values) =>
								setGuest(values.name, values.email),
							)}
						>
							<Stack p={30}>
								<Text
									sx={{
										textAlign: "center",
										marginBottom: 20,
										fontSize: 20,
									}}
								>
									MetaQampus
								</Text>
								<TextInput
									withAsterisk
									label={t("name")}
									disabled={user !== null}
									{...form.getInputProps("name")}
								/>
								<TextInput
									withAsterisk
									label={t("email")}
									disabled={user !== null}
									{...form.getInputProps("email")}
								/>
								<Button
									color="indigo"
									onClick={() =>
										openModal({
											children: (
												<AvatarContainer
													callBack={handleAvatarLoaded}
													type="fullbody"
													quickStart
												/>
											),
											fullScreen: true,
										})
									}
								>
									{user?.avatar !== "" ? t("edit_avatar") : t("create_avatar")}
								</Button>
								<Button
									type="submit"
									disabled={
										isUploading || user == null || user.avatar.length === 0
									}
								>
									{t("enter")}
								</Button>
							</Stack>
						</form>
						{user?.avatar && (
							<Box>
								<RPMAvatar
									modelSrc={`${user.avatar}?quality=medium`}
									cameraInitialDistance={0.7}
									animationSrc="https://naraxr.s3.amazonaws.com/cdn/misc/male-idle.glb"
									environment="https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/sunset-in-the-chalk-quarry/sunset_in_the_chalk_quarry_1k.hdr"
									loader={
										<Box
											sx={{
												height: "100%",
												width: "100%",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<Stack>
												<Center>
													<PuffLoader color="#4C6EF5" />
												</Center>
												<Text color="dimmed">{t("loading_avatar")}</Text>
											</Stack>
										</Box>
									}
									headMovement
								/>
							</Box>
						)}
					</Group>
				</Paper>
				{/* <Group position="apart">
          {defaultAvatars.map((avatar) => (
            <Avatar
              key={avatar.id}
              onClick={() => {}}
              src={avatar.img}
              size="xl"
              radius="xl"
              sx={{
                backgroundColor: "hsla(0, 0%, 0%, 0.090)",
                ":hover": {
                  backgroundColor: "hsla(0, 0%, 0%, 0.114)",
                },
              }}
            />
          ))}
        </Group> */}
				{user?.avatar.length === 0 && (
					<Text color="dimmed">{t("create_avatar_text")}</Text>
				)}
			</Stack>
		</>
	);
}

export default GameLogin;
