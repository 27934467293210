import { FallbackProps } from "react-error-boundary";
import { Flex, Text } from "@mantine/core";

export function ErrorFallback({ error }: FallbackProps) {
	return (
		<Flex
			sx={{
				width: "100vw",
				height: "100vh",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Flex sx={{ flexDirection: "column", alignItems: "center" }}>
				<Text size="h5" color="red">
					{error.message}
				</Text>
				<Text>Please try again later</Text>
			</Flex>
		</Flex>
	);
}

export default ErrorFallback;
