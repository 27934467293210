import { useLocation, Navigate } from "react-router-dom";
import useQampusStore from "../../hooks/useQampusStore";

type RequireAdminProps = {
	children: JSX.Element;
};

function RequireAdmin({ children }: RequireAdminProps) {
	const user = useQampusStore((state) => state.user);

	const location = useLocation();

	if (user === null) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/sign-in" state={{ from: location }} replace />;
	}

	// if (
	//   hasAny !== undefined &&
	//   perms &&
	//   !hasAny.some((perm) => perms.includes(perm))
	// ) {
	//   return <Navigate to="/" state={{ from: location }} replace />;
	// }

	// if (
	//   hasAll !== undefined &&
	//   perms &&
	//   !hasAll.every((perm) => perms.includes(perm))
	// ) {
	//   return <Navigate to="/" state={{ from: location }} replace />;
	// }

	return <>{children}</>;
}

export default RequireAdmin;
