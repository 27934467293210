/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdminSvcAddEnvironmentParams {
	/** Android version */
	avers: number;
	/** Name of the environment */
	name: string;
	/**
	 * Type of the environment
	 * * stand - Stand
	 * * fair - Fair
	 */
	type?: "stand" | "fair";
	/** URL of the bundle */
	url: string;
	/** Webgl version */
	wvers: number;
}

/** Parameters for creating a new build */
export interface AdminSvcCreateBuildParams {
	/** Build notes */
	notes?: string;
	/** Build version */
	version: string;
}

export interface AdminSvcUpdateBuildStatusParams {
	/**
	 * Action
	 * * remove - Remove
	 */
	action: "remove";
	/**
	 * List of build ids
	 * @minItems 1
	 */
	builds: number[];
}

export interface AdminSvcUpdateEnvironmentStatusParams {
	/**
	 * Action
	 * * remove - Remove
	 */
	action: "remove";
	/**
	 * Environment id list
	 * @minItems 1
	 */
	environments: number[];
}

export interface AdminSvcUpdatePermissionsParams {
	/**
	 * Action
	 * * add - Add permission to role
	 * * remove - Remove permisson from role
	 */
	action: "add" | "remove";
	/** Permission ID */
	permission_id: number;
	/** Role ID */
	role_id: number;
}

export interface AdminSvcUpdateSettingsParams {
	/** Agora app cert */
	agora_cert: string;
	/** Agora cert expiration time */
	agora_expire_time?: number;
	/** Agora app id */
	agora_id: string;
	/** Build ID */
	build_id: number;
	/** Default shard capacity */
	cap: number;
	/** Photon app code */
	photon_app_code: string;
	/** Photon app id */
	photon_app_id: string;
	/**
	 * Registration status
	 * * 0 - No registration
	 * * 1 - Allowed domains only
	 * * 2 - Any domain can register
	 */
	registration: 0 | 1 | 2;
}

export interface AdminSvcUpdateUserStatusParams {
	/**
	 * Name of the action
	 * * remove - Remove
	 */
	action: "remove";
	/**
	 * Users
	 * @minItems 1
	 */
	users: number[];
}

export interface AuthServiceCompleteRegisterParameters {
	/** Department */
	department?: string;
	/** Email address */
	email: string;
	/** Job title */
	job_title?: string;
	/** Name */
	name: string;
	/** Surname */
	surname: string;
}

export interface AuthServiceGoogleAuthenticationParameters {
	token: string;
}

export interface AuthServiceLoginInput {
	/** Email address */
	email: string;
	/** Password */
	password: string;
}

export interface AuthServiceLoginSuccess {
	/** Token expiration time */
	expire: number;
	/** Refresh token */
	refresh: string;
	/** Access token */
	token: string;
	/** Token type */
	type: string;
	/** User information */
	user: ModelsUser;
}

export interface AuthServiceMetamaskAuthenticationParameters {
	/** Wallet address */
	address: string;
	/** Signature */
	sign: string;
}

export interface AuthServicePreRegisterParameters {
	captcha: string;
	company: string;
	company_size: string;
	email: string;
	industry: string;
}

export interface AuthServiceRedeemPasswordResetTokenParams {
	/** Password */
	password: string;
	/** Token */
	token: string;
}

export interface AuthServiceRegisterParameters {
	/** ReCaptcha */
	captcha: string;
	/** Department */
	department?: string;
	/** Email address */
	email: string;
	/** Job title */
	job_title?: string;
	/** Name */
	name: string;
	/** Password */
	password: string;
	/** Surname */
	surname: string;
}

export interface AuthServiceResetPasswordParameters {
	/** ReCaptcha */
	captcha: string;
	/** Email address */
	email: string;
}

export interface ConnectedappsSvcAuthURLResponse {
	url: string;
}

export interface DriveContentRestriction {
	/**
	 * ReadOnly: Whether the content of the file is read-only. If a file is
	 * read-only, a new revision of the file may not be added, comments may
	 * not be added or modified, and the title of the file may not be
	 * modified.
	 */
	readOnly?: boolean;
	/**
	 * Reason: Reason for why the content of the file is restricted. This is
	 * only mutable on requests that also set `readOnly=true`.
	 */
	reason?: string;
	/**
	 * RestrictingUser: Output only. The user who set the content
	 * restriction. Only populated if `readOnly` is true.
	 */
	restrictingUser?: DriveUser;
	/**
	 * RestrictionTime: The time at which the content restriction was set
	 * (formatted RFC 3339 timestamp). Only populated if readOnly is true.
	 */
	restrictionTime?: string;
	/**
	 * Type: Output only. The type of the content restriction. Currently the
	 * only possible value is `globalContentRestriction`.
	 */
	type?: string;
}

export interface DriveFile {
	/**
	 * AppProperties: A collection of arbitrary key-value pairs which are
	 * private to the requesting app.
	 * Entries with null values are cleared in update and copy requests.
	 * These properties can only be retrieved using an authenticated
	 * request. An authenticated request uses an access token obtained with
	 * a OAuth 2 client ID. You cannot use an API key to retrieve private
	 * properties.
	 */
	appProperties?: Record<string, string>;
	/**
	 * Capabilities: Output only. Capabilities the current user has on this
	 * file. Each capability corresponds to a fine-grained action that a
	 * user may take.
	 */
	capabilities?: DriveFileCapabilities;
	/**
	 * ContentHints: Additional information about the content of the file.
	 * These fields are never populated in responses.
	 */
	contentHints?: DriveFileContentHints;
	/**
	 * ContentRestrictions: Restrictions for accessing the content of the
	 * file. Only populated if such a restriction exists.
	 */
	contentRestrictions?: DriveContentRestriction[];
	/**
	 * CopyRequiresWriterPermission: Whether the options to copy, print, or
	 * download this file, should be disabled for readers and commenters.
	 */
	copyRequiresWriterPermission?: boolean;
	/**
	 * CreatedTime: The time at which the file was created (RFC 3339
	 * date-time).
	 */
	createdTime?: string;
	/** Description: A short description of the file. */
	description?: string;
	/**
	 * DriveId: Output only. ID of the shared drive the file resides in.
	 * Only populated for items in shared drives.
	 */
	driveId?: string;
	/**
	 * ExplicitlyTrashed: Output only. Whether the file has been explicitly
	 * trashed, as opposed to recursively trashed from a parent folder.
	 */
	explicitlyTrashed?: boolean;
	/**
	 * ExportLinks: Output only. Links for exporting Docs Editors files to
	 * specific formats.
	 */
	exportLinks?: Record<string, string>;
	/**
	 * FileExtension: Output only. The final component of
	 * `fullFileExtension`. This is only available for files with binary
	 * content in Google Drive.
	 */
	fileExtension?: string;
	/**
	 * FolderColorRgb: The color for a folder or a shortcut to a folder as
	 * an RGB hex string. The supported colors are published in the
	 * `folderColorPalette` field of the About resource. If an unsupported
	 * color is specified, the closest color in the palette is used instead.
	 */
	folderColorRgb?: string;
	/**
	 * FullFileExtension: Output only. The full file extension extracted
	 * from the `name` field. May contain multiple concatenated extensions,
	 * such as "tar.gz". This is only available for files with binary
	 * content in Google Drive. This is automatically updated when the
	 * `name` field changes, however it is not cleared if the new name does
	 * not contain a valid extension.
	 */
	fullFileExtension?: string;
	/**
	 * HasAugmentedPermissions: Output only. Whether there are permissions
	 * directly on this file. This field is only populated for items in
	 * shared drives.
	 */
	hasAugmentedPermissions?: boolean;
	/**
	 * HasThumbnail: Output only. Whether this file has a thumbnail. This
	 * does not indicate whether the requesting app has access to the
	 * thumbnail. To check access, look for the presence of the
	 * thumbnailLink field.
	 */
	hasThumbnail?: boolean;
	/**
	 * HeadRevisionId: Output only. The ID of the file's head revision. This
	 * is currently only available for files with binary content in Google
	 * Drive.
	 */
	headRevisionId?: string;
	/**
	 * IconLink: Output only. A static, unauthenticated link to the file's
	 * icon.
	 */
	iconLink?: string;
	/** Id: The ID of the file. */
	id?: string;
	/**
	 * ImageMediaMetadata: Output only. Additional metadata about image
	 * media, if available.
	 */
	imageMediaMetadata?: DriveFileImageMediaMetadata;
	/**
	 * IsAppAuthorized: Output only. Whether the file was created or opened
	 * by the requesting app.
	 */
	isAppAuthorized?: boolean;
	/**
	 * Kind: Output only. Identifies what kind of resource this is. Value:
	 * the fixed string "drive#file".
	 */
	kind?: string;
	/** LabelInfo: Output only. An overview of the labels on the file. */
	labelInfo?: DriveFileLabelInfo;
	/** LastModifyingUser: Output only. The last user to modify the file. */
	lastModifyingUser?: DriveUser;
	/**
	 * LinkShareMetadata: Contains details about the link URLs that clients
	 * are using to refer to this item.
	 */
	linkShareMetadata?: DriveFileLinkShareMetadata;
	/**
	 * Md5Checksum: Output only. The MD5 checksum for the content of the
	 * file. This is only applicable to files with binary content in Google
	 * Drive.
	 */
	md5Checksum?: string;
	/**
	 * MimeType: The MIME type of the file. Google Drive attempts to
	 * automatically detect an appropriate value from uploaded content, if
	 * no value is provided. The value cannot be changed unless a new
	 * revision is uploaded. If a file is created with a Google Doc MIME
	 * type, the uploaded content is imported, if possible. The supported
	 * import formats are published in the About resource.
	 */
	mimeType?: string;
	/**
	 * ModifiedByMe: Output only. Whether the file has been modified by this
	 * user.
	 */
	modifiedByMe?: boolean;
	/**
	 * ModifiedByMeTime: The last time the file was modified by the user
	 * (RFC 3339 date-time).
	 */
	modifiedByMeTime?: string;
	/**
	 * ModifiedTime: he last time the file was modified by anyone (RFC 3339
	 * date-time). Note that setting modifiedTime will also update
	 * modifiedByMeTime for the user.
	 */
	modifiedTime?: string;
	/**
	 * Name: The name of the file. This is not necessarily unique within a
	 * folder. Note that for immutable items such as the top level folders
	 * of shared drives, My Drive root folder, and Application Data folder
	 * the name is constant.
	 */
	name?: string;
	/**
	 * OriginalFilename: The original filename of the uploaded content if
	 * available, or else the original value of the `name` field. This is
	 * only available for files with binary content in Google Drive.
	 */
	originalFilename?: string;
	/**
	 * OwnedByMe: Output only. Whether the user owns the file. Not populated
	 * for items in shared drives.
	 */
	ownedByMe?: boolean;
	/**
	 * Owners: Output only. The owner of this file. Only certain legacy
	 * files may have more than one owner. This field isn't populated for
	 * items in shared drives.
	 */
	owners?: DriveUser[];
	/**
	 * Parents: The IDs of the parent folders which contain the file. If not
	 * specified as part of a create request, the file is placed directly in
	 * the user's My Drive folder. If not specified as part of a copy
	 * request, the file inherits any discoverable parents of the source
	 * file. Update requests must use the `addParents` and `removeParents`
	 * parameters to modify the parents list.
	 */
	parents?: string[];
	/**
	 * PermissionIds: Output only. List of permission IDs for users with
	 * access to this file.
	 */
	permissionIds?: string[];
	/**
	 * Permissions: Output only. The full list of permissions for the file.
	 * This is only available if the requesting user can share the file. Not
	 * populated for items in shared drives.
	 */
	permissions?: DrivePermission[];
	/**
	 * Properties: A collection of arbitrary key-value pairs which are
	 * visible to all apps.
	 * Entries with null values are cleared in update and copy requests.
	 */
	properties?: Record<string, string>;
	/**
	 * QuotaBytesUsed: Output only. The number of storage quota bytes used
	 * by the file. This includes the head revision as well as previous
	 * revisions with `keepForever` enabled.
	 * @example "0"
	 */
	quotaBytesUsed?: string;
	/**
	 * ResourceKey: Output only. A key needed to access the item via a
	 * shared link.
	 */
	resourceKey?: string;
	/**
	 * Sha1Checksum: Output only. The SHA1 checksum associated with this
	 * file, if available. This field is only populated for files with
	 * content stored in Google Drive; it is not populated for Docs Editors
	 * or shortcut files.
	 */
	sha1Checksum?: string;
	/**
	 * Sha256Checksum: Output only. The SHA256 checksum associated with this
	 * file, if available. This field is only populated for files with
	 * content stored in Google Drive; it is not populated for Docs Editors
	 * or shortcut files.
	 */
	sha256Checksum?: string;
	/**
	 * Shared: Output only. Whether the file has been shared. Not populated
	 * for items in shared drives.
	 */
	shared?: boolean;
	/**
	 * SharedWithMeTime: The time at which the file was shared with the
	 * user, if applicable (RFC 3339 date-time).
	 */
	sharedWithMeTime?: string;
	/**
	 * SharingUser: Output only. The user who shared the file with the
	 * requesting user, if applicable.
	 */
	sharingUser?: DriveUser;
	/**
	 * ShortcutDetails: Shortcut file details. Only populated for shortcut
	 * files, which have the mimeType field set to
	 * `application/vnd.google-apps.shortcut`.
	 */
	shortcutDetails?: DriveFileShortcutDetails;
	/**
	 * Size: Output only. Size in bytes of blobs and first party editor
	 * files. Won't be populated for files that have no size, like shortcuts
	 * and folders.
	 * @example "0"
	 */
	size?: string;
	/**
	 * Spaces: Output only. The list of spaces which contain the file. The
	 * currently supported values are 'drive', 'appDataFolder' and 'photos'.
	 */
	spaces?: string[];
	/** Starred: Whether the user has starred the file. */
	starred?: boolean;
	/** TeamDriveId: Deprecated: Output only. Use `driveId` instead. */
	teamDriveId?: string;
	/**
	 * ThumbnailLink: Output only. A short-lived link to the file's
	 * thumbnail, if available. Typically lasts on the order of hours. Only
	 * populated when the requesting app can access the file's content. If
	 * the file isn't shared publicly, the URL returned in
	 * `Files.thumbnailLink` must be fetched using a credentialed request.
	 */
	thumbnailLink?: string;
	/**
	 * ThumbnailVersion: Output only. The thumbnail version for use in
	 * thumbnail cache invalidation.
	 * @example "0"
	 */
	thumbnailVersion?: string;
	/**
	 * Trashed: Whether the file has been trashed, either explicitly or from
	 * a trashed parent folder. Only the owner may trash a file, and other
	 * users cannot see files in the owner's trash.
	 */
	trashed?: boolean;
	/**
	 * TrashedTime: The time that the item was trashed (RFC 3339 date-time).
	 * Only populated for items in shared drives.
	 */
	trashedTime?: string;
	/**
	 * TrashingUser: Output only. If the file has been explicitly trashed,
	 * the user who trashed it. Only populated for items in shared drives.
	 */
	trashingUser?: DriveUser;
	/**
	 * Version: Output only. A monotonically increasing version number for
	 * the file. This reflects every change made to the file on the server,
	 * even those not visible to the user.
	 * @example "0"
	 */
	version?: string;
	/**
	 * VideoMediaMetadata: Output only. Additional metadata about video
	 * media. This may not be available immediately upon upload.
	 */
	videoMediaMetadata?: DriveFileVideoMediaMetadata;
	/**
	 * ViewedByMe: Output only. Whether the file has been viewed by this
	 * user.
	 */
	viewedByMe?: boolean;
	/**
	 * ViewedByMeTime: The last time the file was viewed by the user (RFC
	 * 3339 date-time).
	 */
	viewedByMeTime?: string;
	/**
	 * ViewersCanCopyContent: Deprecated: Use `copyRequiresWriterPermission`
	 * instead.
	 */
	viewersCanCopyContent?: boolean;
	/**
	 * WebContentLink: Output only. A link for downloading the content of
	 * the file in a browser. This is only available for files with binary
	 * content in Google Drive.
	 */
	webContentLink?: string;
	/**
	 * WebViewLink: Output only. A link for opening the file in a relevant
	 * Google editor or viewer in a browser.
	 */
	webViewLink?: string;
	/**
	 * WritersCanShare: Whether users with only `writer` permission can
	 * modify the file's permissions. Not populated for items in shared
	 * drives.
	 */
	writersCanShare?: boolean;
}

export interface DriveFileCapabilities {
	/**
	 * CanAcceptOwnership: Output only. Whether the current user is the
	 * pending owner of the file. Not populated for shared drive files.
	 */
	canAcceptOwnership?: boolean;
	/**
	 * CanAddChildren: Output only. Whether the current user can add
	 * children to this folder. This is always false when the item is not a
	 * folder.
	 */
	canAddChildren?: boolean;
	/**
	 * CanAddFolderFromAnotherDrive: Output only. Whether the current user
	 * can add a folder from another drive (different shared drive or My
	 * Drive) to this folder. This is false when the item is not a folder.
	 * Only populated for items in shared drives.
	 */
	canAddFolderFromAnotherDrive?: boolean;
	/**
	 * CanAddMyDriveParent: Output only. Whether the current user can add a
	 * parent for the item without removing an existing parent in the same
	 * request. Not populated for shared drive files.
	 */
	canAddMyDriveParent?: boolean;
	/**
	 * CanChangeCopyRequiresWriterPermission: Output only. Whether the
	 * current user can change the `copyRequiresWriterPermission`
	 * restriction of this file.
	 */
	canChangeCopyRequiresWriterPermission?: boolean;
	/**
	 * CanChangeSecurityUpdateEnabled: Output only. Whether the current user
	 * can change the securityUpdateEnabled field on link share metadata.
	 */
	canChangeSecurityUpdateEnabled?: boolean;
	/** CanChangeViewersCanCopyContent: Deprecated: Output only. */
	canChangeViewersCanCopyContent?: boolean;
	/**
	 * CanComment: Output only. Whether the current user can comment on this
	 * file.
	 */
	canComment?: boolean;
	/**
	 * CanCopy: Output only. Whether the current user can copy this file.
	 * For an item in a shared drive, whether the current user can copy
	 * non-folder descendants of this item, or this item itself if it is not
	 * a folder.
	 */
	canCopy?: boolean;
	/**
	 * CanDelete: Output only. Whether the current user can delete this
	 * file.
	 */
	canDelete?: boolean;
	/**
	 * CanDeleteChildren: Output only. Whether the current user can delete
	 * children of this folder. This is false when the item is not a folder.
	 * Only populated for items in shared drives.
	 */
	canDeleteChildren?: boolean;
	/**
	 * CanDownload: Output only. Whether the current user can download this
	 * file.
	 */
	canDownload?: boolean;
	/**
	 * CanEdit: Output only. Whether the current user can edit this file.
	 * Other factors may limit the type of changes a user can make to a
	 * file. For example, see `canChangeCopyRequiresWriterPermission` or
	 * `canModifyContent`.
	 */
	canEdit?: boolean;
	/**
	 * CanListChildren: Output only. Whether the current user can list the
	 * children of this folder. This is always false when the item is not a
	 * folder.
	 */
	canListChildren?: boolean;
	/**
	 * CanModifyContent: Output only. Whether the current user can modify
	 * the content of this file.
	 */
	canModifyContent?: boolean;
	/**
	 * CanModifyContentRestriction: Output only. Whether the current user
	 * can modify restrictions on content of this file.
	 */
	canModifyContentRestriction?: boolean;
	/**
	 * CanModifyLabels: Output only. Whether the current user can modify the
	 * labels on the file.
	 */
	canModifyLabels?: boolean;
	/**
	 * CanMoveChildrenOutOfDrive: Output only. Whether the current user can
	 * move children of this folder outside of the shared drive. This is
	 * false when the item is not a folder. Only populated for items in
	 * shared drives.
	 */
	canMoveChildrenOutOfDrive?: boolean;
	/**
	 * CanMoveChildrenOutOfTeamDrive: Deprecated: Output only. Use
	 * `canMoveChildrenOutOfDrive` instead.
	 */
	canMoveChildrenOutOfTeamDrive?: boolean;
	/**
	 * CanMoveChildrenWithinDrive: Output only. Whether the current user can
	 * move children of this folder within this drive. This is false when
	 * the item is not a folder. Note that a request to move the child may
	 * still fail depending on the current user's access to the child and to
	 * the destination folder.
	 */
	canMoveChildrenWithinDrive?: boolean;
	/**
	 * CanMoveChildrenWithinTeamDrive: Deprecated: Output only. Use
	 * `canMoveChildrenWithinDrive` instead.
	 */
	canMoveChildrenWithinTeamDrive?: boolean;
	/**
	 * CanMoveItemIntoTeamDrive: Deprecated: Output only. Use
	 * `canMoveItemOutOfDrive` instead.
	 */
	canMoveItemIntoTeamDrive?: boolean;
	/**
	 * CanMoveItemOutOfDrive: Output only. Whether the current user can move
	 * this item outside of this drive by changing its parent. Note that a
	 * request to change the parent of the item may still fail depending on
	 * the new parent that is being added.
	 */
	canMoveItemOutOfDrive?: boolean;
	/**
	 * CanMoveItemOutOfTeamDrive: Deprecated: Output only. Use
	 * `canMoveItemOutOfDrive` instead.
	 */
	canMoveItemOutOfTeamDrive?: boolean;
	/**
	 * CanMoveItemWithinDrive: Output only. Whether the current user can
	 * move this item within this drive. Note that a request to change the
	 * parent of the item may still fail depending on the new parent that is
	 * being added and the parent that is being removed.
	 */
	canMoveItemWithinDrive?: boolean;
	/**
	 * CanMoveItemWithinTeamDrive: Deprecated: Output only. Use
	 * `canMoveItemWithinDrive` instead.
	 */
	canMoveItemWithinTeamDrive?: boolean;
	/**
	 * CanMoveTeamDriveItem: Deprecated: Output only. Use
	 * `canMoveItemWithinDrive` or `canMoveItemOutOfDrive` instead.
	 */
	canMoveTeamDriveItem?: boolean;
	/**
	 * CanReadDrive: Output only. Whether the current user can read the
	 * shared drive to which this file belongs. Only populated for items in
	 * shared drives.
	 */
	canReadDrive?: boolean;
	/**
	 * CanReadLabels: Output only. Whether the current user can read the
	 * labels on the file.
	 */
	canReadLabels?: boolean;
	/**
	 * CanReadRevisions: Output only. Whether the current user can read the
	 * revisions resource of this file. For a shared drive item, whether
	 * revisions of non-folder descendants of this item, or this item itself
	 * if it is not a folder, can be read.
	 */
	canReadRevisions?: boolean;
	/**
	 * CanReadTeamDrive: Deprecated: Output only. Use `canReadDrive`
	 * instead.
	 */
	canReadTeamDrive?: boolean;
	/**
	 * CanRemoveChildren: Output only. Whether the current user can remove
	 * children from this folder. This is always false when the item is not
	 * a folder. For a folder in a shared drive, use `canDeleteChildren` or
	 * `canTrashChildren` instead.
	 */
	canRemoveChildren?: boolean;
	/**
	 * CanRemoveMyDriveParent: Output only. Whether the current user can
	 * remove a parent from the item without adding another parent in the
	 * same request. Not populated for shared drive files.
	 */
	canRemoveMyDriveParent?: boolean;
	/**
	 * CanRename: Output only. Whether the current user can rename this
	 * file.
	 */
	canRename?: boolean;
	/**
	 * CanShare: Output only. Whether the current user can modify the
	 * sharing settings for this file.
	 */
	canShare?: boolean;
	/**
	 * CanTrash: Output only. Whether the current user can move this file to
	 * trash.
	 */
	canTrash?: boolean;
	/**
	 * CanTrashChildren: Output only. Whether the current user can trash
	 * children of this folder. This is false when the item is not a folder.
	 * Only populated for items in shared drives.
	 */
	canTrashChildren?: boolean;
	/**
	 * CanUntrash: Output only. Whether the current user can restore this
	 * file from trash.
	 */
	canUntrash?: boolean;
}

export interface DriveFileContentHints {
	/**
	 * IndexableText: Text to be indexed for the file to improve fullText
	 * queries. This is limited to 128KB in length and may contain HTML
	 * elements.
	 */
	indexableText?: string;
	/**
	 * Thumbnail: A thumbnail for the file. This will only be used if Google
	 * Drive cannot generate a standard thumbnail.
	 */
	thumbnail?: DriveFileContentHintsThumbnail;
}

export interface DriveFileContentHintsThumbnail {
	/**
	 * Image: The thumbnail data encoded with URL-safe Base64 (RFC 4648
	 * section 5).
	 */
	image?: string;
	/** MimeType: The MIME type of the thumbnail. */
	mimeType?: string;
}

export interface DriveFileImageMediaMetadata {
	/**
	 * Aperture: Output only. The aperture used to create the photo
	 * (f-number).
	 */
	aperture?: number;
	/**
	 * CameraMake: Output only. The make of the camera used to create the
	 * photo.
	 */
	cameraMake?: string;
	/**
	 * CameraModel: Output only. The model of the camera used to create the
	 * photo.
	 */
	cameraModel?: string;
	/** ColorSpace: Output only. The color space of the photo. */
	colorSpace?: string;
	/**
	 * ExposureBias: Output only. The exposure bias of the photo (APEX
	 * value).
	 */
	exposureBias?: number;
	/**
	 * ExposureMode: Output only. The exposure mode used to create the
	 * photo.
	 */
	exposureMode?: string;
	/** ExposureTime: Output only. The length of the exposure, in seconds. */
	exposureTime?: number;
	/** FlashUsed: Output only. Whether a flash was used to create the photo. */
	flashUsed?: boolean;
	/**
	 * FocalLength: Output only. The focal length used to create the photo,
	 * in millimeters.
	 */
	focalLength?: number;
	/** Height: Output only. The height of the image in pixels. */
	height?: number;
	/** IsoSpeed: Output only. The ISO speed used to create the photo. */
	isoSpeed?: number;
	/** Lens: Output only. The lens used to create the photo. */
	lens?: string;
	/**
	 * Location: Output only. Geographic location information stored in the
	 * image.
	 */
	location?: DriveFileImageMediaMetadataLocation;
	/**
	 * MaxApertureValue: Output only. The smallest f-number of the lens at
	 * the focal length used to create the photo (APEX value).
	 */
	maxApertureValue?: number;
	/**
	 * MeteringMode: Output only. The metering mode used to create the
	 * photo.
	 */
	meteringMode?: string;
	/**
	 * Rotation: Output only. The number of clockwise 90 degree rotations
	 * applied from the image's original orientation.
	 */
	rotation?: number;
	/** Sensor: Output only. The type of sensor used to create the photo. */
	sensor?: string;
	/**
	 * SubjectDistance: Output only. The distance to the subject of the
	 * photo, in meters.
	 */
	subjectDistance?: number;
	/**
	 * Time: Output only. The date and time the photo was taken (EXIF
	 * DateTime).
	 */
	time?: string;
	/**
	 * WhiteBalance: Output only. The white balance mode used to create the
	 * photo.
	 */
	whiteBalance?: string;
	/** Width: Output only. The width of the image in pixels. */
	width?: number;
}

export interface DriveFileImageMediaMetadataLocation {
	/** Altitude: Output only. The altitude stored in the image. */
	altitude?: number;
	/** Latitude: Output only. The latitude stored in the image. */
	latitude?: number;
	/** Longitude: Output only. The longitude stored in the image. */
	longitude?: number;
}

export interface DriveFileLabelInfo {
	/**
	 * Labels: Output only. The set of labels on the file as requested by
	 * the label IDs in the `includeLabels` parameter. By default, no labels
	 * are returned.
	 */
	labels?: DriveLabel[];
}

export interface DriveFileLinkShareMetadata {
	/**
	 * SecurityUpdateEligible: Output only. Whether the file is eligible for
	 * security update.
	 */
	securityUpdateEligible?: boolean;
	/**
	 * SecurityUpdateEnabled: Output only. Whether the security update is
	 * enabled for this file.
	 */
	securityUpdateEnabled?: boolean;
}

export interface DriveFileList {
	/**
	 * Files: The list of files. If nextPageToken is populated, then this
	 * list may be incomplete and an additional page of results should be
	 * fetched.
	 */
	files?: DriveFile[];
	/**
	 * IncompleteSearch: Whether the search process was incomplete. If true,
	 * then some search results might be missing, since all documents were
	 * not searched. This can occur when searching multiple drives with the
	 * 'allDrives' corpora, but all corpora couldn't be searched. When this
	 * happens, it's suggested that clients narrow their query by choosing a
	 * different corpus such as 'user' or 'drive'.
	 */
	incompleteSearch?: boolean;
	/**
	 * Kind: Identifies what kind of resource this is. Value: the fixed
	 * string "drive#fileList".
	 */
	kind?: string;
	/**
	 * NextPageToken: The page token for the next page of files. This will
	 * be absent if the end of the files list has been reached. If the token
	 * is rejected for any reason, it should be discarded, and pagination
	 * should be restarted from the first page of results. The page token is
	 * typically valid for several hours. However, if new items are added or
	 * removed, your expected results might differ.
	 */
	nextPageToken?: string;
}

export interface DriveFileShortcutDetails {
	/** TargetId: The ID of the file that this shortcut points to. */
	targetId?: string;
	/**
	 * TargetMimeType: Output only. The MIME type of the file that this
	 * shortcut points to. The value of this field is a snapshot of the
	 * target's MIME type, captured when the shortcut is created.
	 */
	targetMimeType?: string;
	/** TargetResourceKey: Output only. The ResourceKey for the target file. */
	targetResourceKey?: string;
}

export interface DriveFileVideoMediaMetadata {
	/**
	 * DurationMillis: Output only. The duration of the video in
	 * milliseconds.
	 * @example "0"
	 */
	durationMillis?: string;
	/** Height: Output only. The height of the video in pixels. */
	height?: number;
	/** Width: Output only. The width of the video in pixels. */
	width?: number;
}

export interface DriveLabel {
	/** Fields: A map of the fields on the label, keyed by the field's ID. */
	fields?: Record<string, DriveLabelField>;
	/** Id: The ID of the label. */
	id?: string;
	/** Kind: This is always drive#label */
	kind?: string;
	/** RevisionId: The revision ID of the label. */
	revisionId?: string;
}

export interface DriveLabelField {
	/**
	 * DateString: Only present if valueType is dateString. RFC 3339
	 * formatted date: YYYY-MM-DD.
	 */
	dateString?: string[];
	/** Id: The identifier of this label field. */
	id?: string;
	/** Integer: Only present if `valueType` is `integer`. */
	integer?: number[];
	/** Kind: This is always drive#labelField. */
	kind?: string;
	/** Selection: Only present if `valueType` is `selection` */
	selection?: string[];
	/** Text: Only present if `valueType` is `text`. */
	text?: string[];
	/** User: Only present if `valueType` is `user`. */
	user?: DriveUser[];
	/**
	 * ValueType: The field type. While new values may be supported in the
	 * future, the following are currently allowed: * `dateString` *
	 * `integer` * `selection` * `text` * `user`
	 */
	valueType?: string;
}

export interface DrivePermission {
	/**
	 * AllowFileDiscovery: Whether the permission allows the file to be
	 * discovered through search. This is only applicable for permissions of
	 * type `domain` or `anyone`.
	 */
	allowFileDiscovery?: boolean;
	/**
	 * Deleted: Output only. Whether the account associated with this
	 * permission has been deleted. This field only pertains to user and
	 * group permissions.
	 */
	deleted?: boolean;
	/**
	 * DisplayName: Output only. The "pretty" name of the value of the
	 * permission. The following is a list of examples for each type of
	 * permission: * `user` - User's full name, as defined for their Google
	 * account, such as "Joe Smith." * `group` - Name of the Google Group,
	 * such as "The Company Administrators." * `domain` - String domain
	 * name, such as "thecompany.com." * `anyone` - No `displayName` is
	 * present.
	 */
	displayName?: string;
	/** Domain: The domain to which this permission refers. */
	domain?: string;
	/**
	 * EmailAddress: The email address of the user or group to which this
	 * permission refers.
	 */
	emailAddress?: string;
	/**
	 * ExpirationTime: The time at which this permission will expire (RFC
	 * 3339 date-time). Expiration times have the following restrictions: -
	 * They can only be set on user and group permissions - The time must be
	 * in the future - The time cannot be more than a year in the future
	 */
	expirationTime?: string;
	/**
	 * Id: Output only. The ID of this permission. This is a unique
	 * identifier for the grantee, and is published in User resources as
	 * `permissionId`. IDs should be treated as opaque values.
	 */
	id?: string;
	/**
	 * Kind: Output only. Identifies what kind of resource this is. Value:
	 * the fixed string "drive#permission".
	 */
	kind?: string;
	/**
	 * PendingOwner: Whether the account associated with this permission is
	 * a pending owner. Only populated for `user` type permissions for files
	 * that are not in a shared drive.
	 */
	pendingOwner?: boolean;
	/**
	 * PermissionDetails: Output only. Details of whether the permissions on
	 * this shared drive item are inherited or directly on this item. This
	 * is an output-only field which is present only for shared drive items.
	 */
	permissionDetails?: DrivePermissionPermissionDetails[];
	/**
	 * PhotoLink: Output only. A link to the user's profile photo, if
	 * available.
	 */
	photoLink?: string;
	/**
	 * Role: The role granted by this permission. While new values may be
	 * supported in the future, the following are currently allowed: *
	 * `owner` * `organizer` * `fileOrganizer` * `writer` * `commenter` *
	 * `reader`
	 */
	role?: string;
	/**
	 * TeamDrivePermissionDetails: Output only. Deprecated: Output only. Use
	 * `permissionDetails` instead.
	 */
	teamDrivePermissionDetails?: DrivePermissionTeamDrivePermissionDetails[];
	/**
	 * Type: The type of the grantee. Valid values are: * `user` * `group` *
	 * `domain` * `anyone` When creating a permission, if `type` is `user`
	 * or `group`, you must provide an `emailAddress` for the user or group.
	 * When `type` is `domain`, you must provide a `domain`. There isn't
	 * extra information required for an `anyone` type.
	 */
	type?: string;
	/**
	 * View: Indicates the view for this permission. Only populated for
	 * permissions that belong to a view. 'published' is the only supported
	 * value.
	 */
	view?: string;
}

export interface DrivePermissionPermissionDetails {
	/**
	 * Inherited: Output only. Whether this permission is inherited. This
	 * field is always populated. This is an output-only field.
	 */
	inherited?: boolean;
	/**
	 * InheritedFrom: Output only. The ID of the item from which this
	 * permission is inherited. This is an output-only field.
	 */
	inheritedFrom?: string;
	/**
	 * PermissionType: Output only. The permission type for this user. While
	 * new values may be added in future, the following are currently
	 * possible: * `file` * `member`
	 */
	permissionType?: string;
	/**
	 * Role: Output only. The primary role for this user. While new values
	 * may be added in the future, the following are currently possible: *
	 * `organizer` * `fileOrganizer` * `writer` * `commenter` * `reader`
	 */
	role?: string;
}

export interface DrivePermissionTeamDrivePermissionDetails {
	/**
	 * Inherited: Deprecated: Output only. Use `permissionDetails/inherited`
	 * instead.
	 */
	inherited?: boolean;
	/**
	 * InheritedFrom: Deprecated: Output only. Use
	 * `permissionDetails/inheritedFrom` instead.
	 */
	inheritedFrom?: string;
	/** Role: Deprecated: Output only. Use `permissionDetails/role` instead. */
	role?: string;
	/**
	 * TeamDrivePermissionType: Deprecated: Output only. Use
	 * `permissionDetails/permissionType` instead.
	 */
	teamDrivePermissionType?: string;
}

export interface DriveUser {
	/**
	 * DisplayName: Output only. A plain text displayable name for this
	 * user.
	 */
	displayName?: string;
	/**
	 * EmailAddress: Output only. The email address of the user. This may
	 * not be present in certain contexts if the user has not made their
	 * email address visible to the requester.
	 */
	emailAddress?: string;
	/**
	 * Kind: Output only. Identifies what kind of resource this is. Value:
	 * the fixed string "drive#user".
	 */
	kind?: string;
	/** Me: Output only. Whether this user is the requesting user. */
	me?: boolean;
	/**
	 * PermissionId: Output only. The user's ID as visible in Permission
	 * resources.
	 */
	permissionId?: string;
	/**
	 * PhotoLink: Output only. A link to the user's profile photo, if
	 * available.
	 */
	photoLink?: string;
}

export interface EventSvcAttendee {
	/** Is cohost */
	cohost: boolean;
	created_at?: string;
	deleted_at?: GormDeletedAt;
	/** Department */
	department?: string;
	/** Email */
	email: string;
	id: number;
	/** Job title */
	job_title?: string;
	/** Name */
	name: string;
	/** Surname */
	surname: string;
	updated_at?: string;
}

export interface EventSvcJoinEventResponse {
	/** Is the room active */
	activated: boolean;
	/** ID's of the cohosts */
	admins: number[];
	/** Is the room closed */
	closed: boolean;
	created_at: string;
	/** Creator of the session */
	creator?: ModelsUser;
	/** ID of the creator of the session */
	creator_id: number;
	deleted_at?: GormDeletedAt;
	/** Description of the session */
	description: string;
	/** Actual end time of the session */
	end_time: string;
	/** ID of the host of the session */
	host_id: number;
	id: number;
	/** Livestream url of the session */
	livefeed?: string;
	/** Livestream url of the session */
	liveingest?: string;
	/** Livestream key of the session */
	livekey?: string;
	/** Name of the session */
	name: string;
	/** Photon ID */
	pid?: string;
	/** Planned duration of the session */
	planned_duration: number;
	/** Planned start time of the session */
	planned_start_time: string;
	/**
	 * Security level of the session
	 * * 0 - Public
	 * * 1 - Restricted
	 * * 3 - Private
	 */
	security: number;
	/** Session name */
	session: string;
	/** The space which this session will use */
	space?: ModelsSpace;
	/** ID of the space which this session will use */
	space_id: number;
	/** Actual start time of the session */
	start_time: string;
	updated_at: string;
	/** UUID of the session */
	uuid: string;
	/** Web version */
	web_version?: string;
}

export interface EventSvcUpdateAdminsParams {
	/**
	 * List of user ids
	 * @minItems 1
	 */
	admins: number[];
}

export interface GdriveSvcDownloadFileParams {
	fid?: string;
}

/** Parameters for listing files on the Google Drive */
export interface GdriveSvcListFilesParams {
	ptoken?: string;
	query: string;
}

export interface GormDeletedAt {
	time?: string;
	/** Valid is true if Time is not NULL */
	valid?: boolean;
}

export interface HttpErrHTTPError {
	/** @example 400 */
	code: number;
	/** @example "status bad request" */
	message: string;
}

/** An asset that can be swapned */
export interface ModelsAsset {
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** Description of the asset */
	description: string;
	id: number;
	/** Aws key of the asset */
	key: string;
	/** Asset size in bytes */
	size?: number;
	/**
	 * Type of the asset
	 * * presentation - Presentation
	 * * video - Video
	 * * model - Model
	 * * image * Image
	 */
	type?: string;
	updated_at: string;
	/** Asset URL */
	url: string;
}

/** An immerzia webgl build */
export interface ModelsBuild {
	created_at: string;
	deleted_at?: GormDeletedAt;
	id: number;
	/** Notes about the build */
	notes: string;
	/** ID of the organisation which this build belongs to */
	organisation_id?: number;
	updated_at: string;
	/** Build version */
	version: string;
}

/** Domaina address related to an organisation */
export interface ModelsDomain {
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** Domain address */
	domain: string;
	id: number;
	updated_at: string;
}

/** Env requirements */
export interface ModelsEnvReq {
	/** Type of the requirement */
	asset_type: "video" | "image" | "model" | "portal";
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** Description */
	description: string;
	/** Environment */
	environment: ModelsEnvironment;
	/** EnvironmentID */
	environment_id: number;
	id: number;
	/** Name */
	name: string;
	updated_at: string;
}

/** A 3D environment */
export interface ModelsEnvironment {
	/** Android version of the bundle */
	avers: number;
	created_at: string;
	deleted_at?: GormDeletedAt;
	id: number;
	/** Image of the environment */
	image?: ModelsImage;
	/** Image id of the environment */
	image_id?: number;
	/** Name of the environment */
	name: string;
	/** Required assets */
	slots?: ModelsEnvReq[];
	/**
	 * Type of the environment
	 * * stand - Stand
	 * * fair - Fair
	 */
	type: "stand" | "fair";
	updated_at: string;
	/** URL of the asset bundle */
	url: string;
	/** UUID of the environment */
	uuid: string;
	/** Web version of the bundle */
	wvers: number;
}

/** Formatted room response */
export interface ModelsEventResponse {
	/** Is the room active */
	activated: boolean;
	/** ID's of the cohosts */
	admins: number[];
	/** Is the room closed */
	closed: boolean;
	created_at: string;
	/** Creator of the session */
	creator?: ModelsUser;
	/** ID of the creator of the session */
	creator_id: number;
	deleted_at?: GormDeletedAt;
	/** Description of the session */
	description: string;
	/** Actual end time of the session */
	end_time: string;
	/** ID of the host of the session */
	host_id: number;
	id: number;
	/** Livestream url of the session */
	livefeed?: string;
	/** Livestream url of the session */
	liveingest?: string;
	/** Livestream key of the session */
	livekey?: string;
	/** Name of the session */
	name: string;
	/** Planned duration of the session */
	planned_duration: number;
	/** Planned start time of the session */
	planned_start_time: string;
	/**
	 * Security level of the session
	 * * 0 - Public
	 * * 1 - Restricted
	 * * 3 - Private
	 */
	security: number;
	/** Session name */
	session: string;
	/** The space which this session will use */
	space?: ModelsSpace;
	/** ID of the space which this session will use */
	space_id: number;
	/** Actual start time of the session */
	start_time: string;
	updated_at: string;
	/** UUID of the session */
	uuid: string;
}

export interface ModelsFair {
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** Description of the fair */
	description: string;
	/** The environment */
	environment: ModelsEnvironment;
	/** ID of the environment */
	environment_id: number;
	id: number;
	/** Name of the fair */
	name: string;
	updated_at: string;
}

export interface ModelsFairStands {
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** Fair ID */
	fair_id: number;
	id: number;
	/** Space */
	space?: ModelsSpace;
	/** Space ID */
	space_id?: number;
	/** Number of the stand */
	stand_number: number;
	/**
	 * Type of the stand
	 * * regular - Regular
	 * * silver - Silver
	 * * gold - Gold
	 * * diamond - Diamond
	 */
	stand_type: "regular" | "silver" | "gold" | "diamond";
	updated_at: string;
}

/** An image */
export interface ModelsImage {
	created_at: string;
	deleted_at?: GormDeletedAt;
	id: number;
	/** ID of the organisation which this image belongs to */
	organisation_id?: number;
	updated_at: string;
	/** URL of the image */
	url: string;
}

/** An integration with a third party oauth application */
export interface ModelsIntegration {
	/** Name of the application */
	app: string;
	created_at: string;
	deleted_at?: GormDeletedAt;
	id: number;
	/**
	 * Integration status
	 * * WAITING - Waiting confirmation
	 * * CONNECTED - Application is connected
	 */
	status: "WAITING" | "CONNECTED";
	updated_at: string;
}

/** An organisation */
export interface ModelsOrganisation {
	/** Organisation's assets */
	assets?: ModelsAsset[];
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** Description of the organisation */
	description: string;
	id: number;
	/** Organisation's images */
	images?: ModelsImage[];
	/** Invite link */
	invite_link?: string;
	/** Logo image */
	logo: ModelsImage;
	/** Organisation's manager's id */
	manager_id: number;
	/** Name of the organisation */
	name: string;
	/** Organisation's spaces */
	spaces?: ModelsSpace[];
	updated_at: string;
	/** Organisation's users */
	users?: ModelsUser[];
}

/** Settings of an organisation */
export interface ModelsOrganisationSettings {
	/** Build ID */
	build_id: number;
	created_at: string;
	/** The default space is where the users will initially be spawned */
	d_space?: ModelsSpace;
	d_space_id?: number;
	deleted_at?: GormDeletedAt;
	/** Domains of the organisation */
	domains?: ModelsDomain[];
	id: number;
	organisation_id?: number;
	/**
	 * Registration status
	 * * 0 - Registrations are closed
	 * * 1 - Only allowed domains can register
	 * * 2 - Any domain can register
	 */
	registration?: number;
	updated_at: string;
	/** Build */
	web_version: ModelsBuild;
}

/** Permission */
export interface ModelsPermission {
	/** Category of the permission */
	category: string;
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** Description of the permission */
	description: string;
	id: number;
	/** Name of the permission */
	name: string;
	updated_at: string;
}

export interface ModelsPreUser {
	company?: string;
	company_size?: string;
	created_at: string;
	deleted_at?: GormDeletedAt;
	email?: string;
	id: number;
	industry?: string;
	updated_at: string;
}

/** A prop is an object that can be dynamically placed in a scene */
export interface ModelsProp {
	/** The related asset */
	asset: ModelsAsset;
	/** ID of the related asset */
	asset_id: number;
	created_at: string;
	deleted_at?: GormDeletedAt;
	id: number;
	/** ID of the organisation which this prop belongs to */
	organisation_id: number;
	/** X value of the position of the prop */
	posX: number;
	/** Y value of the position of the prop */
	posY: number;
	/** Z value of the position of the prop */
	posZ: number;
	/** X value of the rotation of the prop */
	rotX: number;
	/** Y value of the rotation of the prop */
	rotY: number;
	/** Z value of the rotation of the prop */
	rotZ: number;
	/** SpaceID */
	space_id: number;
	updated_at: string;
}

/** User roles */
export interface ModelsRole {
	created_at: string;
	/** Default role? Default roles can't be removed */
	default: boolean;
	deleted_at?: GormDeletedAt;
	/** Description of the role */
	description: string;
	id: number;
	/** Name of the role */
	name: string;
	/** ID of the organisation which this role belongs to */
	organisation_id: number;
	/** Permissions of the role */
	permissions: ModelsPermission[];
	/** Protedted role? Protected roles can't be assigned */
	protected: boolean;
	updated_at: string;
}

/** a session */
export interface ModelsSession {
	created_at: string;
	deleted_at?: GormDeletedAt;
	id: number;
	/** Number of users in the session */
	numUsers: number;
	/** ID of the organisation which this session belongs to */
	organisation_id: number;
	/** Name of the scene */
	scene: string;
	/** Name of the session */
	session: string;
	updated_at: string;
	/** List of the currently online users in the session */
	users: ModelsUser[];
}

/** Application settings */
export interface ModelsSettings {
	/** Agora certificate */
	agora_cert: string;
	/** Agora expiration time */
	agora_expire_time: number;
	/** Agora application ID */
	agora_id: string;
	/** Build ID */
	build_id: number;
	/** Default shard capacity */
	cap?: number;
	created_at: string;
	deleted_at?: GormDeletedAt;
	id: number;
	/** Photon application code for the gateway */
	photon_app_code: string;
	/** Photon application ID */
	photon_app_id: string;
	/**
	 * Registration status
	 * * 0 - Registrations are closed
	 * * 1 - Only allowed domains can register
	 * * 2 - Any domain can register
	 */
	registration?: number;
	updated_at: string;
	/** Build */
	web_version: ModelsBuild;
}

/** Space is an instance of an environment an can contain additional props */
export interface ModelsSpace {
	/** Sharding capacity */
	cap: number;
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** The environment */
	environment: ModelsEnvironment;
	/** ID of the environment */
	environment_id: number;
	id: number;
	/** Name of the space */
	name: string;
	/** Organisation */
	organisation?: ModelsOrganisation;
	/** ID of the organisation which this space belongs to */
	organisation_id: number;
	/**
	 * Privacy of the space
	 * * private - Private
	 * * restricted - Restricted
	 * * public - Public
	 */
	privaciy: "private" | "restricted" | "public";
	/**
	 * Is session name dynamic
	 * DynamicSession *bool `json:"dses" gorm:"default:false" validate:"required"`
	 * Default session name
	 */
	ses: string;
	/**
	 * Type of the space
	 * * stand - Stand
	 * * fair - Fair
	 */
	type: "stand" | "fair";
	updated_at: string;
	/** ID of the user which this space belongs to */
	user_id: number;
	/** Number of visits */
	visits: number;
}

/** Space requirements */
export interface ModelsSpaceReq {
	/** Asset */
	asset: ModelsAsset;
	/** AssetID */
	asset_id: number;
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** Requirement */
	env_slot?: ModelsEnvReq;
	id: number;
	/** Portal */
	portal?: ModelsSpace;
	/** PortalID */
	portal_id?: number;
	/** RequirementID */
	requirement_id: number;
	/** SpaceID */
	space_id: number;
	updated_at: string;
}

/** An application user */
export interface ModelsUser {
	/** Asset's belonging to user */
	assets?: ModelsAsset[];
	/** User's full body avatar */
	avatar: string;
	/** User's half body avatar */
	avatarvr: string;
	created_at: string;
	deleted_at?: GormDeletedAt;
	/** User's department */
	department?: string;
	/** Email address */
	email: string;
	id: number;
	/** User's job title */
	job_title?: string;
	/** Name */
	name: string;
	/** Organisation of the user */
	organisation?: ModelsOrganisation;
	/** ID of the organisation which this user belongs to */
	organisation_id: number;
	/** User's role */
	role?: ModelsRole;
	/** ID of user's role */
	role_id: number;
	/** User's registration status */
	status: string;
	/**
	 * PlanID             *uint        `json:"plan_id" gorm:"default:1"` //1 - free 2- ? 3- licensed
	 * ID of users subscription
	 */
	subscription_id: number;
	/** Surname */
	surname: string;
	updated_at: string;
}

export interface OrganisationSvcCreateOrganisationInput {
	/** Description */
	description: string;
	/** Name */
	name: string;
}

export interface OrganisationSvcCreateSpaceParameters {
	/** Environment ID */
	environment_id: number;
	/** Name */
	name: string;
	/**
	 * Privacy
	 * * private - Private
	 * * restricted - Restricted
	 * * public - Public
	 */
	privacy: "private" | "restricted" | "public";
	/**
	 * Type
	 * * stand - Stand
	 * * fair - Fair
	 */
	type: "stand" | "fair";
}

export interface OrganisationSvcUpdateMemberRoleParams {
	/**
	 * List of user ids
	 * @minItems 1
	 */
	users: number[];
}

export interface OrganisationSvcUpdateMemberStatusParams {
	/**
	 * Action
	 * * add - Remove user
	 * * remove - Remove user
	 */
	action: "remove";
	/**
	 * List of user ids
	 * @minItems 1
	 */
	users: number[];
}

export interface OrganisationSvcUpdateOrganisationParams {
	description: string;
}

export interface PaginatePage {
	first?: boolean;
	items?: any;
	last?: boolean;
	max_page?: number;
	page?: number;
	size?: number;
	total?: number;
	total_pages?: number;
	visible?: number;
}

export interface SessionSvcGetChannelkeyResponse {
	key: string;
}

export interface SessionSvcJoinSessionResponse {
	pid: string;
	se: string;
	vid: string;
}

export interface SketchfabArchives {
	glb?: SketchfabGlb;
	gltf?: SketchfabGltf;
	source?: SketchfabSource;
	usdz?: SketchfabUsdz;
}

export interface SketchfabAvatar {
	images?: SketchfabImage2[];
	uid?: string;
	uri?: string;
}

export interface SketchfabCursors {
	next?: string;
	previous?: string;
}

export interface SketchfabGlb {
	faceCount?: number;
	size?: number;
	textureCount?: number;
	textureMaxResolution?: number;
	vertexCount?: number;
}

export interface SketchfabGltf {
	faceCount?: number;
	size?: number;
	textureCount?: number;
	textureMaxResolution?: number;
	vertexCount?: number;
}

export interface SketchfabImage {
	height?: number;
	uid?: string;
	url?: string;
	width?: number;
}

export interface SketchfabImage2 {
	height?: number;
	size?: number;
	url?: string;
	width?: number;
}

export interface SketchfabLink {
	expires?: number;
	size?: number;
	url?: string;
}

export interface SketchfabResult {
	animationCount?: number;
	archives?: SketchfabArchives;
	commentCount?: number;
	embedUrl?: string;
	isDownloadable?: boolean;
	isPublished?: boolean;
	likeCount?: number;
	name?: string;
	publishedAt?: string;
	thumbnails?: SketchfabThumbnails;
	uid?: string;
	user?: SketchfabUser;
	viewCount?: number;
	viewerUrl?: string;
}

export interface SketchfabSearchResponse {
	cursors?: SketchfabCursors;
	results?: SketchfabResult[];
}

export interface SketchfabSource {
	faceCount?: number;
	size?: number;
	textureCount?: number;
	textureMaxResolution?: number;
	vertexCount?: number;
}

export interface SketchfabThumbnails {
	images?: SketchfabImage[];
}

export interface SketchfabUsdz {
	faceCount?: number;
	size?: number;
	textureCount?: number;
	textureMaxResolution?: number;
	vertexCount?: number;
}

export interface SketchfabUser {
	account?: string;
	avatar?: SketchfabAvatar;
	displayName?: string;
	profileUrl?: string;
	uid?: string;
	uri?: string;
	username?: string;
}

export interface SketchfabSvcDownloadModelParams {
	/** UUID of the model */
	uid: string;
}

export interface SketchfabSvcDownloadModelResponse {
	/** Link of the model */
	link: SketchfabLink;
}

export interface SpacesSvcCreatePropParameters {
	asset_id: number;
	posX: number;
	posY: number;
	posZ: number;
	rotX: number;
	rotY: number;
	rotZ: number;
}

export interface ThemingTheme {
	colors: ThemingThemeColor[];
}

export interface ThemingThemeColor {
	name: string;
	value: string;
}

export interface UsersvcCreateEventInput {
	/**
	 * PreApproved bool   `json:"pre_approved"`
	 * List of IDs of the admins
	 */
	admins: number[];
	/** List of IDs of the attendees */
	attendees?: number[];
	/** Description of the session */
	description: string;
	/** Should a live stream URL be generated */
	livestream?: boolean;
	/** Name of the session */
	name: string;
	/** Planned duration of the session */
	planned_duration: number;
	/** Planned start time of the session */
	planned_start_time: string;
	/**
	 * Security level of the session
	 * * 0 - Public
	 * * 3 - Private
	 */
	security: 0 | 3;
	/** ID of the space where the session will take place */
	space_id: number;
}

export interface UsersvcGetPermissionsResponse {
	/** List of permissions */
	permissions: string[];
}

export interface UsersvcStorageInfo {
	num_files: number;
	used_space: number;
}

export interface UsersvcUpdateAvatarParams {
	/** Avatar URL */
	avatar: string;
	/**
	 * Avatar Type
	 * * halfbody - Half body avatar for VR
	 * * fullbody - Full body avatar for desktop
	 */
	type: "halfbody" | "fullbody";
}

export interface UsersvcUpdatePasswordParams {
	confirm_password?: string;
	password?: string;
}

export interface UsersvcUpdateUserParameters {
	department?: string;
	job_title?: string;
	name?: string;
	surname?: string;
}

import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<
	FullRequestParams,
	"body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || "//localhost:8080/v1",
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig,
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[
						method.toLowerCase() as keyof HeadersDefaults
					]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === "object" && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] =
				property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(
					key,
					isFileType ? formItem : this.stringifyFormItem(formItem),
				);
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (
			type === ContentType.FormData &&
			body &&
			body !== null &&
			typeof body === "object"
		) {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (
			type === ContentType.Text &&
			body &&
			body !== null &&
			typeof body !== "string"
		) {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData
					? { "Content-Type": type }
					: {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @title Immerzia API
 * @version 1.0
 * @license Nara Educational Technologies © 2022
 * @termsOfService http://swagger.io/terms/
 * @baseUrl //localhost:8080/v1
 * @contact Burak Karaceylan <burakkaraceylan@nara.com.tr> (https://www.linkedin.com/in/burak-karaceylan/)
 *
 * This is the backend for Immerzia by Nara XR. This is a RESTful API. Authorization header must be in the form of "Bearer {token}".
 */
export class Api<
	SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
	application = {
		/**
		 * No description
		 *
		 * @tags application
		 * @name GetApplicationSettings
		 * @summary Main application settings
		 * @request GET:/application/settings
		 * @secure
		 */
		getApplicationSettings: (params: RequestParams = {}) =>
			this.request<ModelsSettings, HttpErrHTTPError>({
				path: `/application/settings`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	auth = {
		/**
		 * No description
		 *
		 * @tags auth
		 * @name GoogleAuthenticate
		 * @summary Authenticate using Google
		 * @request POST:/auth/google
		 */
		googleAuthenticate: (
			request: AuthServiceGoogleAuthenticationParameters,
			params: RequestParams = {},
		) =>
			this.request<AuthServiceLoginSuccess, HttpErrHTTPError>({
				path: `/auth/google`,
				method: "POST",
				body: request,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name Login
		 * @summary Returns a jwt token
		 * @request POST:/auth/login
		 */
		login: (request: AuthServiceLoginInput, params: RequestParams = {}) =>
			this.request<AuthServiceLoginSuccess, HttpErrHTTPError>({
				path: `/auth/login`,
				method: "POST",
				body: request,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name MetamaskAuthenticate
		 * @summary Authenticate using Metamask
		 * @request POST:/auth/metamask
		 */
		metamaskAuthenticate: (
			request: AuthServiceMetamaskAuthenticationParameters,
			params: RequestParams = {},
		) =>
			this.request<AuthServiceLoginSuccess, HttpErrHTTPError>({
				path: `/auth/metamask`,
				method: "POST",
				body: request,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name CompleteMetamaskRegistration
		 * @summary User registration
		 * @request POST:/auth/metamask/complete
		 */
		completeMetamaskRegistration: (
			request: AuthServiceCompleteRegisterParameters,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/auth/metamask/complete`,
				method: "POST",
				body: request,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name PreRegister
		 * @summary Preregister user
		 * @request POST:/auth/preregister
		 */
		preRegister: (
			params: AuthServicePreRegisterParameters,
			requestParams: RequestParams = {},
		) =>
			this.request<ModelsPreUser, HttpErrHTTPError>({
				path: `/auth/preregister`,
				method: "POST",
				body: params,
				type: ContentType.Json,
				format: "json",
				...requestParams,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name Register
		 * @summary User registration
		 * @request POST:/auth/register
		 */
		register: (
			request: AuthServiceRegisterParameters,
			params: RequestParams = {},
		) =>
			this.request<AuthServiceLoginSuccess, HttpErrHTTPError>({
				path: `/auth/register`,
				method: "POST",
				body: request,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name RedeemPasswordResetToken
		 * @summary Redeem password reset token
		 * @request PUT:/auth/reset/password
		 */
		redeemPasswordResetToken: (
			request: AuthServiceRedeemPasswordResetTokenParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/auth/reset/password`,
				method: "PUT",
				body: request,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name RequestPasswordReset
		 * @summary Request password reset
		 * @request POST:/auth/reset/password
		 */
		requestPasswordReset: (
			request: AuthServiceResetPasswordParameters,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/auth/reset/password`,
				method: "POST",
				body: request,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name ResendActivationCode
		 * @summary Resend activation code
		 * @request GET:/auth/verify/resend
		 */
		resendActivationCode: (params: RequestParams = {}) =>
			this.request<void, HttpErrHTTPError>({
				path: `/auth/verify/resend`,
				method: "GET",
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name VerifyEmail
		 * @summary Verify email address
		 * @request GET:/auth/verify/{code}
		 */
		verifyEmail: (code: string, params: RequestParams = {}) =>
			this.request<void, HttpErrHTTPError>({
				path: `/auth/verify/${code}`,
				method: "GET",
				type: ContentType.Json,
				...params,
			}),
	};
	environments = {
		/**
		 * No description
		 *
		 * @tags environments
		 * @name ListEnvironments
		 * @summary List environments
		 * @request GET:/environments
		 * @secure
		 */
		listEnvironments: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filters
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsEnvironment[];
				},
				HttpErrHTTPError
			>({
				path: `/environments`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags environments
		 * @name GetEnvironment
		 * @summary Get environment details
		 * @request GET:/environments/{environment_uid}
		 * @secure
		 */
		getEnvironment: (environmentUid: string, params: RequestParams = {}) =>
			this.request<ModelsEnvironment, HttpErrHTTPError>({
				path: `/environments/${environmentUid}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	events = {
		/**
		 * No description
		 *
		 * @tags event
		 * @name GetEvent
		 * @summary Event information
		 * @request GET:/events/{event_uuid}
		 * @secure
		 */
		getEvent: (eventUuid: string, params: RequestParams = {}) =>
			this.request<ModelsEventResponse, HttpErrHTTPError>({
				path: `/events/${eventUuid}`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags event
		 * @name UpdateEvent
		 * @summary Update a event
		 * @request PUT:/events/{event_uuid}
		 * @secure
		 */
		updateEvent: (
			eventUuid: string,
			request: UsersvcCreateEventInput,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/events/${eventUuid}`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags event
		 * @name DeleteEvent
		 * @summary Delete a event
		 * @request DELETE:/events/{event_uuid}
		 * @secure
		 */
		deleteEvent: (eventUuid: string, params: RequestParams = {}) =>
			this.request<void, HttpErrHTTPError>({
				path: `/events/${eventUuid}`,
				method: "DELETE",
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags event
		 * @name UpdateEventAdmins
		 * @summary Update event admins
		 * @request PUT:/events/{event_uuid}/admins
		 * @secure
		 */
		updateEventAdmins: (
			eventUuid: string,
			request: EventSvcUpdateAdminsParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/events/${eventUuid}/admins`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags event
		 * @name GetEventAttendees
		 * @summary Get a event's attendees
		 * @request GET:/events/{event_uuid}/attendees
		 * @secure
		 */
		getEventAttendees: (
			eventUuid: string,
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: EventSvcAttendee[];
				},
				HttpErrHTTPError
			>({
				path: `/events/${eventUuid}/attendees`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags event
		 * @name JoinEvent
		 * @summary Join a event
		 * @request GET:/events/{event_uuid}/join
		 * @deprecated
		 * @secure
		 */
		joinEvent: (eventUuid: string, params: RequestParams = {}) =>
			this.request<EventSvcJoinEventResponse, HttpErrHTTPError>({
				path: `/events/${eventUuid}/join`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	fairs = {
		/**
		 * No description
		 *
		 * @tags fairs
		 * @name ListFairs
		 * @summary List fairs
		 * @request GET:/fairs
		 * @secure
		 */
		listFairs: (params: RequestParams = {}) =>
			this.request<
				PaginatePage & {
					items?: ModelsFair[];
				},
				HttpErrHTTPError
			>({
				path: `/fairs`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags fairs
		 * @name GetFair
		 * @summary Get fair details
		 * @request GET:/fairs/{fair_id}
		 * @secure
		 */
		getFair: (fairId: number, params: RequestParams = {}) =>
			this.request<ModelsFair, HttpErrHTTPError>({
				path: `/fairs/${fairId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags fairs
		 * @name ListStands
		 * @summary List fair stands
		 * @request GET:/fairs/{fair_id}/stands
		 * @secure
		 */
		listStands: (
			fairId: number,
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filters
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsFairStands[];
				},
				HttpErrHTTPError
			>({
				path: `/fairs/${fairId}/stands`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags fairs
		 * @name DeassignSpace
		 * @summary Deassing space from a stand
		 * @request DELETE:/fairs/{fair_id}/stands/{stand_id}
		 * @secure
		 */
		deassignSpace: (
			fairId: number,
			standId: number,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/fairs/${fairId}/stands/${standId}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags fairs
		 * @name AssignSpace
		 * @summary Assign space to a stand
		 * @request PUT:/fairs/{fair_id}/stands/{stand_id}/space/{space_id}
		 * @secure
		 */
		assignSpace: (
			fairId: number,
			spaceId: number,
			standId: number,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/fairs/${fairId}/stands/${standId}/space/${spaceId}`,
				method: "PUT",
				secure: true,
				...params,
			}),
	};
	gdrive = {
		/**
		 * @description Only supports pdf, glb, and mp4 files
		 *
		 * @tags integrations
		 * @name DownloadFile
		 * @summary Download a file from Google Drive
		 * @request GET:/gdrive/download
		 * @secure
		 */
		downloadFile: (
			request: GdriveSvcDownloadFileParams,
			params: RequestParams = {},
		) =>
			this.request<ModelsAsset, HttpErrHTTPError>({
				path: `/gdrive/download`,
				method: "GET",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags integrations
		 * @name ListFiles
		 * @summary List files on the Google Drive
		 * @request GET:/gdrive/list
		 * @secure
		 */
		listFiles: (
			request: GdriveSvcListFilesParams,
			params: RequestParams = {},
		) =>
			this.request<DriveFileList, HttpErrHTTPError>({
				path: `/gdrive/list`,
				method: "GET",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	integrations = {
		/**
		 * No description
		 *
		 * @tags integrations
		 * @name ListIntegrations
		 * @summary List integrations
		 * @request GET:/integrations
		 * @secure
		 */
		listIntegrations: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsIntegration[];
				},
				HttpErrHTTPError
			>({
				path: `/integrations`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags integrations
		 * @name RemoveIntegration
		 * @summary Remove an integration
		 * @request DELETE:/integrations
		 * @secure
		 */
		removeIntegration: (
			query: {
				/**
				 * Application
				 * * sketchfab - Sketchfab
				 * * gderive - Google Drive
				 */
				app: "sketchfab" | "gdrive";
			},
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/integrations`,
				method: "DELETE",
				query: query,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags integrations
		 * @name GetAuthenticationUrl
		 * @summary Get authentication URL
		 * @request GET:/integrations/auth
		 * @secure
		 */
		getAuthenticationUrl: (
			query: {
				/**
				 * Application
				 * * sketchfab - Sketchfab
				 * * gderive - Google Drive
				 */
				app: "sketchfab" | "gdrive";
			},
			params: RequestParams = {},
		) =>
			this.request<ConnectedappsSvcAuthURLResponse, HttpErrHTTPError>({
				path: `/integrations/auth`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags integrations
		 * @name GetToken
		 * @summary Get oauth tokens
		 * @request GET:/integrations/auth/callback
		 * @secure
		 */
		getToken: (
			query: {
				/** Authentication code */
				code: string;
				/** State for confirmation */
				state: string;
			},
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/integrations/auth/callback`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	mng = {
		/**
		 * No description
		 *
		 * @tags management
		 * @name CreateBuild
		 * @summary Create a new build
		 * @request POST:/mng/build
		 * @secure
		 */
		createBuild: (
			request: AdminSvcCreateBuildParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/mng/build`,
				method: "POST",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name ListBuilds
		 * @summary List all builds.
		 * @request GET:/mng/builds
		 * @secure
		 */
		listBuilds: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filters
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsBuild[];
				},
				HttpErrHTTPError
			>({
				path: `/mng/builds`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name UpdateBuildStatus
		 * @summary Update a build's status.
		 * @request PUT:/mng/builds
		 * @secure
		 */
		updateBuildStatus: (
			request: AdminSvcUpdateBuildStatusParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/mng/builds`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name UpdateEnvironmentStatus
		 * @summary Update environment status
		 * @request PUT:/mng/environments
		 * @secure
		 */
		updateEnvironmentStatus: (
			request: AdminSvcUpdateEnvironmentStatusParams,
			params: RequestParams = {},
		) =>
			this.request<ModelsEnvironment, HttpErrHTTPError>({
				path: `/mng/environments`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name CreateEnvironment
		 * @summary Create a new environment
		 * @request POST:/mng/environments
		 * @secure
		 */
		createEnvironment: (
			request: AdminSvcAddEnvironmentParams,
			params: RequestParams = {},
		) =>
			this.request<ModelsEnvironment, HttpErrHTTPError>({
				path: `/mng/environments`,
				method: "POST",
				body: request,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name UploadEnvironmentImage
		 * @summary Upload an environment image
		 * @request POST:/mng/environments/{environment_id}/image
		 * @secure
		 */
		uploadEnvironmentImage: (
			environmentId: number,
			data: {
				/** Environment image */
				file: File;
			},
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/mng/environments/${environmentId}/image`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.FormData,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name UpdateEnvironment
		 * @summary Update an environment
		 * @request PUT:/mng/environments/{environment_uid}
		 * @secure
		 */
		updateEnvironment: (
			environmentUid: string,
			request: AdminSvcAddEnvironmentParams,
			params: RequestParams = {},
		) =>
			this.request<ModelsEnvironment, HttpErrHTTPError>({
				path: `/mng/environments/${environmentUid}`,
				method: "PUT",
				body: request,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name ListEnvSlots
		 * @summary Lists slots of an environment
		 * @request GET:/mng/environments/{environment_uid}/slots
		 * @secure
		 */
		listEnvSlots: (
			environmentUid: string,
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filter value
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsEnvReq[];
				},
				HttpErrHTTPError
			>({
				path: `/mng/environments/${environmentUid}/slots`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name ListOrganisations
		 * @summary List all organisations.
		 * @request GET:/mng/organisations
		 * @secure
		 */
		listOrganisations: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filters
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsOrganisation[];
				},
				HttpErrHTTPError
			>({
				path: `/mng/organisations`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name ListAllRoles
		 * @summary List all roles.
		 * @request GET:/mng/roles
		 * @secure
		 */
		listAllRoles: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filters
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsRole[];
				},
				HttpErrHTTPError
			>({
				path: `/mng/roles`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name UpdatePermissions
		 * @summary Update permissions.
		 * @request PUT:/mng/roles
		 * @secure
		 */
		updatePermissions: (
			request: AdminSvcUpdatePermissionsParams,
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsRole[];
				},
				HttpErrHTTPError
			>({
				path: `/mng/roles`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name GetAppSettingsAdmin
		 * @summary Get application settings.
		 * @request GET:/mng/settings
		 * @secure
		 */
		getAppSettingsAdmin: (params: RequestParams = {}) =>
			this.request<ModelsSettings, HttpErrHTTPError>({
				path: `/mng/settings`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name SettingsUpdateMultiple
		 * @summary Update application settings.
		 * @request PUT:/mng/settings
		 * @secure
		 */
		settingsUpdateMultiple: (
			request: AdminSvcUpdateSettingsParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/mng/settings`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name ListAllUsers
		 * @summary List all users.
		 * @request GET:/mng/users
		 * @secure
		 */
		listAllUsers: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filters
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsUser[];
				},
				HttpErrHTTPError
			>({
				path: `/mng/users`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags management
		 * @name UpdateUserStatus
		 * @summary Update a user's status.
		 * @request PUT:/mng/users
		 * @secure
		 */
		updateUserStatus: (
			request: AdminSvcUpdateUserStatusParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/mng/users`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	organisation = {
		/**
		 * No description
		 *
		 * @tags organisation
		 * @name GetOrganisationSettings
		 * @summary Settings of an organisation.
		 * @request GET:/organisation/{organisation_id}/settings
		 * @secure
		 */
		getOrganisationSettings: (
			organisationId: number,
			params: RequestParams = {},
		) =>
			this.request<ModelsOrganisationSettings, HttpErrHTTPError>({
				path: `/organisation/${organisationId}/settings`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	organisations = {
		/**
		 * No description
		 *
		 * @tags organisation
		 * @name CreateOrganisation
		 * @summary Creates a new organisation
		 * @request POST:/organisations
		 * @secure
		 */
		createOrganisation: (
			request: OrganisationSvcCreateOrganisationInput,
			params: RequestParams = {},
		) =>
			this.request<ModelsOrganisation, HttpErrHTTPError>({
				path: `/organisations`,
				method: "POST",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags organisation
		 * @name GetOrganisation
		 * @summary Get organisation details
		 * @request GET:/organisations/me
		 * @secure
		 */
		getOrganisation: (params: RequestParams = {}) =>
			this.request<ModelsOrganisation, HttpErrHTTPError>({
				path: `/organisations/me`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags organisation
		 * @name UpdateOrganisation
		 * @summary Update organisation
		 * @request PUT:/organisations/me
		 * @secure
		 */
		updateOrganisation: (
			request: OrganisationSvcUpdateOrganisationParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/organisations/me`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint is used to delete an organisation.
		 *
		 * @tags organisation
		 * @name DeleteOrganisation
		 * @summary Deletes an organisation
		 * @request DELETE:/organisations/me
		 * @secure
		 */
		deleteOrganisation: (params: RequestParams = {}) =>
			this.request<void, HttpErrHTTPError>({
				path: `/organisations/me`,
				method: "DELETE",
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UploadOrganisationImage
		 * @summary Upload organisation image
		 * @request POST:/organisations/me/image
		 * @secure
		 */
		uploadOrganisationImage: (
			data: {
				/**
				 * Image file
				 * @format binary
				 */
				file: File;
			},
			params: RequestParams = {},
		) =>
			this.request<ModelsImage, HttpErrHTTPError>({
				path: `/organisations/me/image`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags organisation
		 * @name ListOrganisationRoles
		 * @summary List organisation roles
		 * @request GET:/organisations/me/roles
		 * @secure
		 */
		listOrganisationRoles: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filter value
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsRole[];
				},
				HttpErrHTTPError
			>({
				path: `/organisations/me/roles`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags organisation
		 * @name UpdateMemberRole
		 * @summary Update member roles
		 * @request PUT:/organisations/me/roles/{role_id}
		 * @secure
		 */
		updateMemberRole: (
			roleId: number,
			request: OrganisationSvcUpdateMemberRoleParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/organisations/me/roles/${roleId}`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint is used to fetch all spaces
		 *
		 * @tags organisations
		 * @name ListOrganisationSpaces
		 * @summary List organisations's spaces
		 * @request GET:/organisations/me/spaces
		 * @secure
		 */
		listOrganisationSpaces: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filter value
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsSpace[];
				},
				HttpErrHTTPError
			>({
				path: `/organisations/me/spaces`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags organisations
		 * @name CreateSpace
		 * @summary Create a space
		 * @request POST:/organisations/me/spaces
		 * @secure
		 */
		createSpace: (
			request: OrganisationSvcCreateSpaceParameters,
			params: RequestParams = {},
		) =>
			this.request<ModelsSpace, HttpErrHTTPError>({
				path: `/organisations/me/spaces`,
				method: "POST",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags organisation
		 * @name ListOrganisationMembers
		 * @summary List organisation members
		 * @request GET:/organisations/me/users
		 * @secure
		 */
		listOrganisationMembers: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filter value
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsUser[];
				},
				HttpErrHTTPError
			>({
				path: `/organisations/me/users`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description Add or remove members from organisation
		 *
		 * @tags organisation
		 * @name UpdateMemberStatus
		 * @summary Update member statutes
		 * @request PUT:/organisations/me/users
		 * @secure
		 */
		updateMemberStatus: (
			request: OrganisationSvcUpdateMemberStatusParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/organisations/me/users`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	sessions = {
		/**
		 * No description
		 *
		 * @tags session
		 * @name GetChannelKey
		 * @summary Gets agora channel key.
		 * @request GET:/sessions/channel
		 * @secure
		 */
		getChannelKey: (
			query: {
				/** Channel name */
				name: string;
			},
			params: RequestParams = {},
		) =>
			this.request<SessionSvcGetChannelkeyResponse, HttpErrHTTPError>({
				path: `/sessions/channel`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags session
		 * @name ListActiveSessions
		 * @summary Lists the active sessions
		 * @request GET:/sessions/list
		 * @secure
		 */
		listActiveSessions: (params: RequestParams = {}) =>
			this.request<
				PaginatePage & {
					items?: ModelsSession[];
				},
				HttpErrHTTPError
			>({
				path: `/sessions/list`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags session
		 * @name PingSession
		 * @summary Sends data about the current session.
		 * @request POST:/sessions/ping
		 * @secure
		 */
		pingSession: (params: RequestParams = {}) =>
			this.request<void, HttpErrHTTPError>({
				path: `/sessions/ping`,
				method: "POST",
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags session
		 * @name JoinSession
		 * @summary Join a session.
		 * @request GET:/sessions/{session_name}/join
		 * @secure
		 */
		joinSession: (sessionName: string, params: RequestParams = {}) =>
			this.request<SessionSvcJoinSessionResponse, HttpErrHTTPError>({
				path: `/sessions/${sessionName}/join`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	sketchfab = {
		/**
		 * No description
		 *
		 * @tags integrations
		 * @name DownloadModel
		 * @summary Download a model from sketchfab
		 * @request POST:/sketchfab/get
		 * @secure
		 */
		downloadModel: (
			request: SketchfabSvcDownloadModelParams,
			params: RequestParams = {},
		) =>
			this.request<SketchfabSvcDownloadModelResponse, HttpErrHTTPError>({
				path: `/sketchfab/get`,
				method: "POST",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags integrations
		 * @name GetDownloadLinks
		 * @summary Download a model from sketchfab
		 * @request GET:/sketchfab/models/{model_uid}/links
		 * @secure
		 */
		getDownloadLinks: (modelUid: string, params: RequestParams = {}) =>
			this.request<ModelsAsset, HttpErrHTTPError>({
				path: `/sketchfab/models/${modelUid}/links`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags integrations
		 * @name SearchModel
		 * @summary Search a model on sketchfab
		 * @request GET:/sketchfab/search
		 * @secure
		 */
		searchModel: (
			query: {
				/** Search query */
				query: string;
				/** Search offset */
				offset: number;
			},
			params: RequestParams = {},
		) =>
			this.request<SketchfabSearchResponse, HttpErrHTTPError>({
				path: `/sketchfab/search`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	spaces = {
		/**
		 * No description
		 *
		 * @tags spaces
		 * @name SpaceList
		 * @summary Space information
		 * @request GET:/spaces
		 * @secure
		 */
		spaceList: (params: RequestParams = {}) =>
			this.request<
				PaginatePage & {
					items?: ModelsSpace[];
				},
				HttpErrHTTPError
			>({
				path: `/spaces`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name UpdateProp
		 * @summary Updates a prop
		 * @request PUT:/spaces/props/{prop_id}
		 * @secure
		 */
		updateProp: (
			propId: number,
			request: SpacesSvcCreatePropParameters,
			params: RequestParams = {},
		) =>
			this.request<ModelsProp, HttpErrHTTPError>({
				path: `/spaces/props/${propId}`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name DeleteProp
		 * @summary Delete prop
		 * @request DELETE:/spaces/props/{prop_id}
		 * @secure
		 */
		deleteProp: (propId: number, params: RequestParams = {}) =>
			this.request<void, HttpErrHTTPError>({
				path: `/spaces/props/${propId}`,
				method: "DELETE",
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name SpaceDetails
		 * @summary Space information
		 * @request GET:/spaces/{space_id}
		 * @secure
		 */
		spaceDetails: (spaceId: number, params: RequestParams = {}) =>
			this.request<ModelsSpace, HttpErrHTTPError>({
				path: `/spaces/${spaceId}`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name DeleteSpace
		 * @summary Space information
		 * @request DELETE:/spaces/{space_id}
		 * @secure
		 */
		deleteSpace: (spaceId: number, params: RequestParams = {}) =>
			this.request<void, HttpErrHTTPError>({
				path: `/spaces/${spaceId}`,
				method: "DELETE",
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name ListSpaceProps
		 * @summary Lists props of a space
		 * @request GET:/spaces/{space_id}/props
		 * @secure
		 */
		listSpaceProps: (spaceId: number, params: RequestParams = {}) =>
			this.request<
				PaginatePage & {
					items?: ModelsProp[];
				},
				HttpErrHTTPError
			>({
				path: `/spaces/${spaceId}/props`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name CreateProp
		 * @summary Creates a prop in a space
		 * @request POST:/spaces/{space_id}/props
		 * @secure
		 */
		createProp: (
			spaceId: number,
			request: SpacesSvcCreatePropParameters,
			params: RequestParams = {},
		) =>
			this.request<ModelsProp, HttpErrHTTPError>({
				path: `/spaces/${spaceId}/props`,
				method: "POST",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name CheckProp
		 * @summary Checks if space has the prop
		 * @request GET:/spaces/{space_id}/props/{prop_id}
		 * @secure
		 */
		checkProp: (spaceId: number, propId: number, params: RequestParams = {}) =>
			this.request<void, HttpErrHTTPError>({
				path: `/spaces/${spaceId}/props/${propId}`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name ListSlots
		 * @summary Lists slots of a space
		 * @request GET:/spaces/{space_id}/slots
		 * @secure
		 */
		listSlots: (
			spaceId: number,
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filter value
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsSpaceReq[];
				},
				HttpErrHTTPError
			>({
				path: `/spaces/${spaceId}/slots`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name DeAssignSlot
		 * @summary Deassign a slot
		 * @request DELETE:/spaces/{space_id}/slots/{slot_id}/asset
		 * @secure
		 */
		deAssignSlot: (
			spaceId: number,
			slotId: number,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/spaces/${spaceId}/slots/${slotId}/asset`,
				method: "DELETE",
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name AssignAssetToSlot
		 * @summary Assign space static reqs
		 * @request PUT:/spaces/{space_id}/slots/{slot_id}/asset/{asset_id}
		 * @secure
		 */
		assignAssetToSlot: (
			spaceId: number,
			slotId: number,
			assetId: number,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/spaces/${spaceId}/slots/${slotId}/asset/${assetId}`,
				method: "PUT",
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name GetTheme
		 * @summary Gets the theme of the space
		 * @request GET:/spaces/{space_id}/theme
		 * @secure
		 */
		getTheme: (spaceId: number, params: RequestParams = {}) =>
			this.request<ThemingTheme, HttpErrHTTPError>({
				path: `/spaces/${spaceId}/theme`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags spaces
		 * @name UpdateTheme
		 * @summary Updates a theme godoc
		 * @request PUT:/spaces/{space_id}/theme
		 * @secure
		 */
		updateTheme: (
			spaceId: number,
			request: ThemingTheme,
			params: RequestParams = {},
		) =>
			this.request<ThemingTheme, HttpErrHTTPError>({
				path: `/spaces/${spaceId}/theme`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	users = {
		/**
		 * No description
		 *
		 * @tags user
		 * @name GetOwnDetails
		 * @summary Get own information
		 * @request GET:/users/me
		 * @secure
		 */
		getOwnDetails: (params: RequestParams = {}) =>
			this.request<ModelsUser, HttpErrHTTPError>({
				path: `/users/me`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UpdateOwnData
		 * @summary Update own information
		 * @request PUT:/users/me
		 * @secure
		 */
		updateOwnData: (
			request: UsersvcUpdateUserParameters,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/users/me`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name ListUserAssets
		 * @summary List user assets
		 * @request GET:/users/me/assets
		 * @secure
		 */
		listUserAssets: (
			query?: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/**
				 * filters
				 * @default "id"
				 */
				filters?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsAsset[];
				},
				HttpErrHTTPError
			>({
				path: `/users/me/assets`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UploadAsset
		 * @summary Upload an asset
		 * @request POST:/users/me/assets
		 * @secure
		 */
		uploadAsset: (
			data: {
				/** Asset description */
				description: string;
				/**
				 * Asset file
				 * @format binary
				 */
				file: File;
			},
			params: RequestParams = {},
		) =>
			this.request<ModelsAsset, HttpErrHTTPError>({
				path: `/users/me/assets`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name GetStorageInfo
		 * @summary GetUserStorageInfo
		 * @request GET:/users/me/assets/info
		 * @secure
		 */
		getStorageInfo: (params: RequestParams = {}) =>
			this.request<UsersvcStorageInfo, HttpErrHTTPError>({
				path: `/users/me/assets/info`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name DeleteUserAsset
		 * @summary Delete an asset
		 * @request DELETE:/users/me/assets/{asset_id}
		 * @secure
		 */
		deleteUserAsset: (assetId: number, params: RequestParams = {}) =>
			this.request<ModelsAsset, HttpErrHTTPError>({
				path: `/users/me/assets/${assetId}`,
				method: "DELETE",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UpdateOwnAvatar
		 * @summary Update own avatar
		 * @request PUT:/users/me/avatar
		 * @secure
		 */
		updateOwnAvatar: (
			request: UsersvcUpdateAvatarParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/users/me/avatar`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * @description This endpoint is used to create a new event.
		 *
		 * @tags event
		 * @name CreateEvent
		 * @summary Creates a new event
		 * @request POST:/users/me/events
		 * @secure
		 */
		createEvent: (
			request: UsersvcCreateEventInput,
			params: RequestParams = {},
		) =>
			this.request<ModelsEventResponse, HttpErrHTTPError>({
				path: `/users/me/events`,
				method: "POST",
				body: request,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name ListAttendingEvents
		 * @summary List all public events plus private events which this user can join
		 * @request GET:/users/me/events/attending
		 * @secure
		 */
		listAttendingEvents: (
			query?: {
				/** Space ID */
				space_id?: number;
				/** Event type */
				type?: "scheduled" | "live" | "all";
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsEventResponse[];
				},
				HttpErrHTTPError
			>({
				path: `/users/me/events/attending`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UpdateOwnPassword
		 * @summary Update own password
		 * @request PUT:/users/me/password
		 * @secure
		 */
		updateOwnPassword: (
			request: UsersvcUpdatePasswordParams,
			params: RequestParams = {},
		) =>
			this.request<void, HttpErrHTTPError>({
				path: `/users/me/password`,
				method: "PUT",
				body: request,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name GetOwnPermissions
		 * @summary Get own permissions
		 * @request GET:/users/me/permissions
		 * @secure
		 */
		getOwnPermissions: (params: RequestParams = {}) =>
			this.request<UsersvcGetPermissionsResponse, HttpErrHTTPError>({
				path: `/users/me/permissions`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name SearchUsers
		 * @summary Search users
		 * @request GET:/users/search
		 * @secure
		 */
		searchUsers: (
			query: {
				/**
				 * number of items
				 * @default 10
				 */
				size?: number;
				/**
				 * page number
				 * @default 1
				 */
				page?: number;
				/**
				 * sorting value
				 * @default "id"
				 */
				sort?: string;
				/** User email */
				email: string;
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsUser[];
				},
				HttpErrHTTPError
			>({
				path: `/users/search`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name GetUserDetails
		 * @summary Get a user's information
		 * @request GET:/users/{user_id}
		 * @secure
		 */
		getUserDetails: (userId: number, params: RequestParams = {}) =>
			this.request<ModelsUser, HttpErrHTTPError>({
				path: `/users/${userId}`,
				method: "GET",
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * @description This endpoint is used to get the information about all active events
		 *
		 * @tags event
		 * @name ListEvents
		 * @summary Event information of active events created by a user
		 * @request GET:/users/{user_id}/events
		 * @secure
		 */
		listEvents: (
			userId: number,
			query: {
				/** Event type */
				type: "scheduled" | "live" | "all";
			},
			params: RequestParams = {},
		) =>
			this.request<
				PaginatePage & {
					items?: ModelsEventResponse[];
				},
				HttpErrHTTPError
			>({
				path: `/users/${userId}/events`,
				method: "GET",
				query: query,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
}
