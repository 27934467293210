import { useState, forwardRef } from "react";
import {
	Container,
	Paper,
	TextInput,
	Group,
	NumberInput,
	Text,
	Stack,
	Button,
	Title,
	Autocomplete,
	Loader,
	AutocompleteItem,
	ScrollArea,
	Badge,
	Anchor,
	useMantineTheme,
	ActionIcon,
	Table,
	Select,
	Checkbox,
	Alert,
} from "@mantine/core";
import { IconTrash, IconAlertCircle } from "@tabler/icons";
import { useForm } from "@mantine/form";
import { useDebouncedValue, useListState } from "@mantine/hooks";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useNavigate, Navigate } from "react-router-dom";
import { DateTimePicker } from "@mantine/dates";
import useQampusStore from "../../hooks/useQampusStore";
import { useTranslation } from "react-i18next";
import {
	ImmApi,
	EventSvcAttendee,
	ModelsEventResponse,
	ModelsUser,
	ModelsSpace,
	UsersvcCreateEventInput,
} from "@api";
import useResourceId from "../../hooks/useResourceId";

interface UserItem extends EventSvcAttendee {
	value: string;
}

interface UsersTableProps {
	data: UserItem[];
	onRemoveUser: (id: number) => void;
	onSelectCohost: (id: number, selected: boolean) => void;
}

export function UsersTable({
	data,
	onRemoveUser,
	onSelectCohost,
}: UsersTableProps) {
	const theme = useMantineTheme();
	const { t } = useTranslation();

	const rows = data.map((item, idx) => (
		<tr key={item.email}>
			<td>
				<Group spacing="sm">
					{/* <Avatar size={30} src={item.avatar} radius={30} /> */}
					<Text size="sm" weight={500}>
						{`${item.name} ${item.surname}`}
					</Text>
				</Group>
			</td>

			<td>
				<Badge variant={theme.colorScheme === "dark" ? "light" : "outline"}>
					{item.job_title}
				</Badge>
			</td>
			<td>
				<Anchor<"a">
					size="sm"
					href="#"
					onClick={(event) => event.preventDefault()}
				>
					{item.email}
				</Anchor>
			</td>
			<td>
				<Checkbox
					checked={item.cohost}
					onChange={(event) => onSelectCohost(idx, event.currentTarget.checked)}
				/>
			</td>
			<td>
				<Group spacing={0} position="right">
					<ActionIcon color="red" onClick={() => onRemoveUser(idx)}>
						<IconTrash size={16} stroke={1.5} />
					</ActionIcon>
				</Group>
			</td>
		</tr>
	));

	return (
		<ScrollArea>
			<Table sx={{ minWidth: 800 }} verticalSpacing="sm">
				<thead>
					<tr>
						<th>{t("employee")}</th>
						<th>{t("job_title")}</th>
						<th>{t("email")}</th>
						<th>{t("cohost")}</th>
						<th />
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</ScrollArea>
	);
}

const convertRoomToState = (room: ModelsEventResponse) => {
	const plannedTime = new Date(room.planned_start_time);
	const topic = room.name;
	const description = room.description;
	const durationHours = Math.floor(room.planned_duration / 60);
	const durationMinutes = room.planned_duration % 60;
	const date = plannedTime;
	const time = plannedTime;
	const security = room.security.toString();
	const space_id = room.space_id.toString();
	const livestream = room.livefeed != null;

	return {
		topic,
		description,
		durationHours,
		durationMinutes,
		security,
		space_id,
		date,
		time,
		livestream,
	};
};

type FormParams = {
	topic: string;
	description: string;
	durationHours: number;
	durationMinutes: number;
	security: string;
	space_id: string;
	livestream: boolean;
};

const AutoCompleteItem = forwardRef<HTMLDivElement, EventSvcAttendee>(
	({ email, id: _id, ...others }: EventSvcAttendee, ref) => (
		<div ref={ref} {...others}>
			<Group noWrap>
				<div>
					<Text>{email}</Text>
				</div>
			</Group>
		</div>
	),
);

export const CreateRoom = () => {
	const { id } = useResourceId();

	if (id == null) {
		<Navigate to="/" />;
	}

	const user = useQampusStore((state) => state.user);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [userSearchKey, setUserSearchKey] = useState("");
	const [debouncedSearchKey] = useDebouncedValue(userSearchKey, 200);
	const { t } = useTranslation();

	const [date, setDate] = useState<Date | null>(new Date());

	const [error, setError] = useState<string | null>(null);

	const [selectedUserValues, selectedUserHandlers] = useListState<UserItem>([]);

	const form = useForm({
		initialValues: {
			topic: "",
			description: "",
			durationHours: 1,
			durationMinutes: 0,
			security: "0",
			space_id: "",
			livestream: false,
		},
		validate: {
			topic: (value) =>
				value.length < 2 ? t("shedule_topic_validation") : null,
			description: (value) =>
				value.length < 2 ? t("schedule_description_validation") : null,
			space_id: (value) =>
				value === "" ? t("schedule_environment_validation") : null,
		},
	});

	const fetchSpaces = useQuery(
		["spaces", user?.organisation_id, "meeting"],
		() => ImmApi.organisations.listOrganisationSpaces(),
	);

	useQuery(["room", id], () => ImmApi.events.getEvent(id.toString()), {
		enabled: id !== undefined,
		onSuccess: (data) => {
			if (!data.data.admins?.includes(user?.id as number)) {
				navigate(-1);
			} else {
				const editedData = convertRoomToState(data.data);
				form.setValues(editedData);
				setDate(editedData.date);
				//setTime(editedData.time);
			}
		},
	});

	useQuery(
		["attendees", id],
		() => ImmApi.events.getEventAttendees(id.toString()),
		{
			enabled: id !== undefined,
			select: (response) =>
				response.data.items.map((a: EventSvcAttendee) => ({
					...a,
					value: a.email,
				})),
			onSuccess: (data) => {
				selectedUserHandlers.setState(data);
			},
		},
	);

	const searchUsers = useQuery(
		["users", debouncedSearchKey],
		() =>
			ImmApi.organisations.listOrganisationMembers({
				filters: `[["email","like","${debouncedSearchKey}"],["OR"],["name","like",["${debouncedSearchKey}"]]]`,
			}),
		{
			enabled: debouncedSearchKey.length > 2,
			select: (response) =>
				response.data.items.flatMap((item: ModelsUser) =>
					!selectedUserValues.some((u) => u.id === item.id)
						? [
								{
									...item,
									value: item.email,
									cohost: false,
								},
						  ]
						: [],
				),
		},
	);

	const createRoom = useMutation(
		({ request }: { request: UsersvcCreateEventInput }) =>
			ImmApi.users.createEvent(request),
		{
			onSuccess: (response) => {
				queryClient.invalidateQueries(["events"]);
				navigate(`/meeting/${response.data.uuid}`);
			},
		},
	);

	const updateRoom = useMutation(
		({ id, request }: { id: string; request: UsersvcCreateEventInput }) =>
			ImmApi.events.updateEvent(id, request),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["events"]);
				navigate("/meeting");
			},
		},
	);

	const handleSave = (values: FormParams) => {
		if (date === null) return;
		const plannedStart = date;
		const plannedDuration = values.durationHours * 60 + values.durationMinutes;

		if (!id && plannedStart < new Date(Date.now() - 60000)) {
			setError(t("schedule_time_validation"));
			return;
		}

		const admins: number[] = [];
		const attendees: number[] = [];

		for (const user of selectedUserValues) {
			attendees.push(user.id);

			if (user.cohost) {
				admins.push(user.id);
			}
		}

		const security = parseInt(values.security);
		const space_id = values.space_id !== "" ? parseInt(values.space_id) : null;

		if (security !== 0 && security !== 3) return;
		if (!space_id) return;

		const data: UsersvcCreateEventInput = {
			name: values.topic,
			description: values.description,
			planned_start_time: plannedStart.toISOString(),
			planned_duration: plannedDuration,
			security: security,
			space_id: space_id,
			admins: admins,
			attendees: attendees,
		};

		if (id) {
			updateRoom.mutate({
				id: id.toString(),
				request: data,
			});
		} else {
			createRoom.mutate({ request: data });
		}
	};

	const handleAutoCompleteSubmit = (item: AutocompleteItem) => {
		if (selectedUserValues.some((u) => u.email === item.value)) return;

		const user = searchUsers.data
			? searchUsers.data.items.find((u: ModelsUser) => u.email === item.value)
			: null;

		if (user != null) {
			selectedUserHandlers.append(user);
		}

		setUserSearchKey("");
	};

	const handleCohostCheckbox = (idx: number, checked: boolean) => {
		selectedUserHandlers.setItemProp(idx, "cohost", checked);
	};

	return (
		<Container>
			<Paper p={30} radius="lg" shadow="lg">
				<form onSubmit={form.onSubmit((values) => handleSave(values))}>
					<Title order={2} mb="lg">
						{id ? t("update_meeting") : t("schedule_meeting")}
					</Title>
					<Stack>
						<TextInput
							label={t("topic")}
							placeholder={t("topic_example")}
							withAsterisk
							{...form.getInputProps("topic")}
						/>
						<TextInput
							label={t("description")}
							placeholder={t("description_example")}
							withAsterisk
							{...form.getInputProps("description")}
						/>
						<DateTimePicker
							label={t("date_time")}
							placeholder={t("date_time_example")}
							withAsterisk
							value={date}
							onChange={setDate}
						/>
						<Group align="flex-end">
							<NumberInput
								label={t("duration")}
								stepHoldDelay={500}
								stepHoldInterval={100}
								{...form.getInputProps("durationHours")}
							/>
							<Text>{t("hr")}</Text>
							<NumberInput
								label=" "
								stepHoldDelay={500}
								stepHoldInterval={100}
								step={5}
								{...form.getInputProps("durationMinutes")}
							/>
							<Text>{t("min")}</Text>
						</Group>
						{/* <Checkbox
              label="Live Feed"
              {...form.getInputProps("livestream", { type: "checkbox" })}
            /> */}
						{fetchSpaces.data && fetchSpaces.data.data.items.length !== 0 && (
							<Select
								label={t("environment")}
								placeholder={t("environment_example")}
								data={fetchSpaces.data.data.items.map((env: ModelsSpace) => ({
									label: env.name,
									value: env.id.toString(),
								}))}
								withAsterisk
								{...form.getInputProps("space_id")}
							/>
						)}
						<Select
							label="Meeting Type"
							withAsterisk
							data={[
								{ value: "0", label: t("public") },
								{ value: "3", label: t("private") },
							]}
							{...form.getInputProps("security")}
						/>
						{true && (
							<>
								<Title order={3} my="lg">
									{t("attendees")}
								</Title>
								<Group position="apart" sx={{ alignItems: "flex-end" }}>
									<Autocomplete
										value={userSearchKey}
										data={searchUsers.data ? searchUsers.data : []}
										onChange={(val) => setUserSearchKey(val)}
										rightSection={
											searchUsers.isFetching ? <Loader size={16} /> : null
										}
										itemComponent={AutoCompleteItem}
										filter={(email, item) =>
											item.email
												.toLowerCase()
												.includes(email.toLowerCase().trim())
										}
										onItemSubmit={handleAutoCompleteSubmit}
										label={t("add_users")}
										placeholder="Keyword"
										sx={{ width: 300 }}
									/>
								</Group>
								<UsersTable
									data={selectedUserValues || []}
									onSelectCohost={(idx, selected) =>
										handleCohostCheckbox(idx, selected)
									}
									onRemoveUser={(idx) => selectedUserHandlers.remove(idx)}
								/>
							</>
						)}
						{error && (
							<Alert
								icon={<IconAlertCircle size="1rem" />}
								title={t("error")}
								color="red"
							>
								{error}
							</Alert>
						)}
						<Group>
							<Button color="blue" type="submit">
								{id ? t("update") : t("save")}
							</Button>
							<Button color="red" onClick={() => navigate(-1)}>
								{t("cancel")}
							</Button>
						</Group>
					</Stack>
				</form>
			</Paper>
		</Container>
	);
};

export default CreateRoom;
