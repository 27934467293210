import { useState } from "react";
import { useQuery } from "react-query";
import { Box, Flex } from "@mantine/core";
import Unity from "../../components/new-renderer/renderer";
import { Guest } from "../../CommonTypes";
import { useQampusStore } from "../../hooks/useQampusStore";
import GameLogin from "../../components/game-login/game-login";
import { ImmApi } from "@api";

function Lobby() {
	const user = useQampusStore((store) => store.user);
	const [guest, setGuest] = useState<Guest | null>(null);

	const { data, isFetching, isError } = useQuery(
		["settings"],
		() => ImmApi.application.getApplicationSettings(),
		{
			refetchOnWindowFocus: false,
			refetchInterval: false,
			refetchOnReconnect: false,
		},
	);

	if (isFetching) {
		return <h1>Loading</h1>;
	}

	if (isError) {
		return <h1>Error</h1>;
	}

	return (
		<Box sx={{ height: "100vh", backgroundColor: "#f8f9fa" }}>
			{guest ? (
				<Unity user={user} version={data?.data.web_version.version ?? ""} />
			) : (
				<Flex
					sx={{
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}
				>
					<GameLogin callback={setGuest} />
				</Flex>
			)}
		</Box>
	);
}

export default Lobby;
